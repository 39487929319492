import React, { useState, useRef } from "react";
import { ReactComponent as Xmark } from "../../assets/images/xmark.svg";
import { ReactComponent as FilteIcon } from "../../assets/images/filterIcon.svg";
import { ReactComponent as Export } from "../../assets/images/Export.svg";
import { ReactComponent as Search } from "../../assets/images/search.svg";
import { ReactComponent as Circle } from "../../assets/images/plus-circle.svg";
import BaseInput from "../form/BaseInput";
import ManageButton from "../../components/manage-column-button/ManageColumnButton";
import {
  Popover,
  Dropdown,
  OverlayTrigger,
  Badge,
  Tooltip,
} from "react-bootstrap";
import CustomTabContainer from "./CustomTabContainer";
import { jsPDF } from "jspdf";
import "jspdf-autotable";
import { ReactComponent as ArrowDown } from "../../assets/images/chevron-green-down.svg";
import { sendEvent } from "../../util/analytics";
import { useLocation } from "react-router-dom";
import { locationToPageName } from "../../helpers";
import { BulkReject } from "../bulkReject/BulkReject";
import CrackHashButton from "../crack-hash-button/CrackHashButton";
import { rubikFontBase64 } from "../../assets/fonts/Rubik-normal";

const BaseFilter = ({
  tabs = [],
  activeFilters = [],
  removeFilter,
  onFilterChange,
  totalRecords,
  exportTitle,
  exportHeader,
  exportRows,
  exportPDFRows,
  activeRows = [],
  handleApplyActions,
  showFilter = true,
  isDatainProcess,
  showSortBy = false,
  options = [],
  handleSortChange,
  searchValue,
  onSearchChange,
  isSearch = false,
  isManageColumn = false,
  selectedSortValue,
  allColumns = [],
  setAllColumns,
  actions,
  onActionSelect,
  multiSelect,
  onClearSelectRow,
  isCrackHash = false,
  handleHashCrack,
  multiselectLabel = "Asset",
  disableExport = false,
  disabledCrackAll = false,
  tableData,
}) => {
  const dropdownRef = useRef(null);
  const [hasScrolled, setHasScrolled] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [isOpenDropdown, setIsOpenDropdown] = useState(false);
  const maxVisibleBadges = 10;

  const selectedActions = [{ id: 1, name: "Reject IP", checked: false }];

  const toggleBulkDropdown = () => setIsOpenDropdown(!isOpenDropdown);
  const closeDropdown = () => setIsOpenDropdown(false);
  const toggleDropdown = () => setIsOpen((prev) => !prev);
  const onSelectAction = (action) => {
    closeDropdown();
    onActionSelect(action);
  };

  const location = useLocation();
  const handleFilterChange = (filterName, isChecked, key, allData) => {
    let updatedActiveFilters = [...activeFilters];
    if (key == "selectAll") {
      const allSelected = allData.every((el) =>
        activeFilters.some(
          (ol) => ol.eventKey === el.key && ol.name === el.name
        )
      );

      if (allSelected) {
        updatedActiveFilters = updatedActiveFilters.filter(
          (filter) =>
            !allData.some(
              (el) => el.key === filter.eventKey && el.name === filter.name
            )
        );
      } else {
        allData.forEach((el) => {
          const hasActiveFilter = activeFilters.some(
            (ol) => ol.eventKey === el.key && ol.name === el.name
          );
          if (!hasActiveFilter) {
            updatedActiveFilters.push({
              name: el.name,
              type: el?.type,
              eventKey: el.key,
            });
          }
        });
      }
    } else if (filterName.key == "advanced-filter") {
      updatedActiveFilters = updatedActiveFilters.filter(
        (f) => !(f.eventKey === filterName.key)
      );
      updatedActiveFilters.push({
        name: filterName.name,
        type: filterName?.type,
        eventKey: filterName.key,
      });
    } else if (isChecked) {
      updatedActiveFilters.push({
        name: filterName.name,
        type: filterName?.type,
        eventKey: filterName.key,
      });
    } else if (key === "clear") {
      updatedActiveFilters = [];
    } else {
      updatedActiveFilters = updatedActiveFilters.filter(
        (f) => !(f.name === filterName.name && f.eventKey === filterName.key)
      );
    }

    const updatedTabs = tabs.map((tab) => ({
      ...tab,
      data: tab.data.map((item) => {
        const active = updatedActiveFilters.some(
          (f) => f.name === item.name && f.eventKey === item.key
        );
        return { ...item, active };
      }),
    }));
    onFilterChange(updatedActiveFilters, updatedTabs);
  };

  const handleRemoveFilterByKey = (filterName, index) => {
    const updatedFilters = activeFilters.filter(
      (f) => !(f.name === filterName.name && f.eventKey === filterName.eventKey)
    );
    const updatedTabs = tabs.map((tab) => ({
      ...tab,
      data: tab.data.map((item) => ({
        ...item,
        active: updatedFilters.some(
          (f) => f.name === item.name && f.eventKey === item.key
        ),
      })),
    }));
    removeFilter(updatedFilters, updatedTabs);
  };

  const popover = (
    <Popover id="popover-basic-container">
      <Popover.Body className="p-0">
        <CustomTabContainer
          tabs={tabs}
          activeKey="advanced-filter"
          onDomainChange={handleFilterChange}
          activeFilters={activeFilters}
        />
      </Popover.Body>
    </Popover>
  );

  const sendExportEvent = (format) => {
    sendEvent("export", {
      pageName: locationToPageName(location),
      format,
    });
  };

  const handleOnSearchChange = (e) => {
    onSearchChange(e);
    sendEvent("search", {
      pageName: locationToPageName(location),
      search: e.target.value,
    });
  };

  const exportCSV = () => {
    sendExportEvent("CSV");
    const header = exportHeader;
    const rows = exportRows;
    const csvContent = [header, ...rows].map((row) => row.join(",")).join("\n");
    const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8" });
    const link = document.createElement("a");
    link.setAttribute("href", URL.createObjectURL(blob));
    link.setAttribute("download", `${exportTitle}.csv`);
    link.style.display = "none";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const exportPDF = () => {
    sendExportEvent("PDF");
    const doc = new jsPDF({
      orientation: "landscape",
      unit: "pt",
      format: "a4",
    });

    doc.addFileToVFS("Rubik-normal.ttf", rubikFontBase64);
    doc.addFont("Rubik-normal.ttf", "Rubik", "normal");

    const header = exportHeader;
    const rows = exportPDFRows || exportRows;

    const formattedRows = rows.map((row) =>
      row.map((cell) => {
        if (typeof cell === "string") {
          return cell.normalize("NFKC");
        }
        return cell;
      })
    );

    const title =
      typeof exportTitle === "string" ? exportTitle : "Exported Data";

    const maxColumnWidth = 150;

    const calculateColumnWidths = (header, rows) => {
      return header.map((_, i) => {
        const headerWidth = doc.getTextWidth(header[i].toString());
        const maxWidth = Math.max(
          headerWidth,
          ...rows.map((row) => doc.getTextWidth(row[i]?.toString() || ""))
        );
        return Math.min(maxWidth + 45, maxColumnWidth);
      });
    };

    const columnWidths = calculateColumnWidths(header, formattedRows);

    const totalTableWidth = columnWidths.reduce((a, b) => a + b, 0);
    const pageWidth = doc.internal.pageSize.width - 40;
    const scalingFactor =
      totalTableWidth > pageWidth ? pageWidth / totalTableWidth : 1;

    const adjustedColumnWidths = columnWidths.map(
      (width) => width * scalingFactor
    );

    const fillPageScalingFactor =
      totalTableWidth < pageWidth ? pageWidth / totalTableWidth : 1;
    const finalColumnWidths = adjustedColumnWidths.map(
      (width) => width * fillPageScalingFactor
    );

    doc.setFont("Rubik", "normal");
    try {
      doc.text(title, 14, 15);
    } catch (e) {
      console.error("Invalid arguments passed to jsPDF.text:", e);
      return;
    }

    doc.autoTable({
      head: [header],
      body: formattedRows,
      startY: 30,
      styles: {
        font: "Rubik",
        fontSize: 8,
        cellPadding: 3,
      },
      headStyles: {
        fillColor: [0, 123, 255],
        textColor: [255, 255, 255],
        overflow: "auto",
      },
      columnStyles: finalColumnWidths.reduce((acc, width, i) => {
        acc[i] = { cellWidth: width, minCellWidth: width };
        return acc;
      }, {}),
      didDrawPage: (data) => {
        doc.setFontSize(10);
        doc.setTextColor(40);
        const str = `Page ${doc.internal.getNumberOfPages()}`;
        doc.text(str, pageWidth / 2, doc.internal.pageSize.height - 15, {
          align: "center",
        });
      },
      margin: { top: 30, bottom: 20, left: 10, right: 10 },
      theme: "striped",
      bodyStyles: { valign: "top", cellWidth: "wrap" },
      useUnicode: true,
    });

    doc.save(`${title}.pdf`);
  };

  const scrollDownOnce = () => {
    if (!hasScrolled) {
      const parentDiv = document.querySelector(".main-section");
      if (parentDiv && dropdownRef.current) {
        const dropdownPosition =
          dropdownRef.current.getBoundingClientRect().top;
        const scrollPosition = parentDiv.scrollTop + dropdownPosition - 60; // Adjust 20 to your needs
        parentDiv.scrollTo({
          top: scrollPosition,
          behavior: "smooth",
        });
        setHasScrolled(true);
      }
    }
  };

  const remainingBadges = activeFilters.length - maxVisibleBadges;
  const visibleBadges = activeFilters.slice(0, maxVisibleBadges);
  const hiddenBadges = activeFilters.slice(maxVisibleBadges);

  const handleClearAll = () => {
    handleFilterChange("", false, "clear");
  };

  const getBadgeValue = (filter) => {
    if (filter.eventKey == "advanced-filter") {
      let data = filter?.type?.split("|");
      return (
        <span
          style={{
            textTransform: "capitalize",
          }}
        >
          &#91;
          {data?.map((el, index) => {
            return (
              <span key={el}>
                {el?.split(",")?.map((ol) => {
                  if (!(ol === "Empty" || ol == "Not Empty")) {
                    return <span> &lt;{ol.replaceAll("_", " ")}&gt; </span>;
                  }
                })}
                {index != data?.length - 1 && (
                  <Circle className="condition-circle-icon" />
                )}
              </span>
            );
          })}
        </span>
      );
    } else {
      return <span>{filter?.type || filter?.name}</span>;
    }
  };

  return (
    <div>
      <div className="d-flex justify-content-between align-items-center text-white p-2">
        {showFilter && (
          <div className="table-result d-flex align-items-center">
            <>
              <p className="m-0">
                {isDatainProcess ? "Processing" : totalRecords}
              </p>{" "}
              <p className="ms-2 text-nowrap m-0">
                Results &nbsp;{tabs?.length > 0 && "|"}
              </p>
            </>
            <div
              className="ms-4"
              style={{
                display: tabs?.length > 0 ? "inline" : "none",
              }}
            >
              <OverlayTrigger
                trigger="click"
                placement={
                  tableData?.length == 0 ? "top-start" : "bottom-start"
                }
                overlay={popover}
                rootClose={true}
              >
                <div className="d-flex rounded-2 align-items-center p-2 border border-white cursor-pointer filter-dot-container">
                  <FilteIcon height={20} width={20} />
                  {activeFilters?.length > 0 && <div className="filter-dot" />}
                </div>
              </OverlayTrigger>
            </div>
          </div>
        )}

        <div className="d-flex justify-content-between align-items-center text-white p-2">
          {showSortBy && (
            <div className="ms-2 m-0 fw-normal  m-w-75">Sort By:</div>
          )}
          {showSortBy && (
            <Dropdown
              onToggle={toggleDropdown}
              show={isOpen}
              className="d-inline mx-3 sort-dp"
            >
              <Dropdown.Toggle
                id="dropdown-autoclose-true"
                className="py-2 d-flex align-items-center justify-content-between"
              >
                {selectedSortValue}
                <ArrowDown
                  height={18}
                  width={18}
                  className={`base-drop-down-arrow ${isOpen ? "open" : ""}`}
                />
              </Dropdown.Toggle>
              <Dropdown.Menu style={{ width: "308px" }}>
                {options?.map((el) => (
                  <div key={el.value}>
                    <Dropdown.Item onClick={() => handleSortChange(el)}>
                      {el.label}
                    </Dropdown.Item>
                  </div>
                ))}
              </Dropdown.Menu>
            </Dropdown>
          )}
          {activeRows.length > 0 && (
            <Dropdown
              data-bs-theme="dark"
              autoClose="outside"
              className="primary-drop-down export-dp me-2"
            >
              <Dropdown.Toggle className="bg-transparent border-0">
                <span className="text-white">
                  <span className="me-2">Actions</span>(
                  {activeRows.length > 2 ? "2+" : activeRows.length})
                </span>
              </Dropdown.Toggle>

              <Dropdown.Menu className="dp-active-menu action-dp-menu">
                {selectedActions.map((action) => (
                  <Dropdown.Item
                    key={action?.id}
                    onClick={handleApplyActions}
                    className="d-flex align-items-center bg-transparent"
                  >
                    {action.name}
                  </Dropdown.Item>
                ))}
              </Dropdown.Menu>
            </Dropdown>
          )}

          {isSearch && (
            <div className="w-400 ">
              <BaseInput
                type="text"
                placeholder="Search..."
                className="search-input"
                icon={Search}
                value={searchValue}
                onChange={handleOnSearchChange}
              />
            </div>
          )}
          {isManageColumn && (
            <ManageButton
              allColumns={allColumns}
              setAllColumns={setAllColumns}
            />
          )}
          {isCrackHash && (
            <CrackHashButton
              onCrack={handleHashCrack}
              disabled={disabledCrackAll}
            />
          )}
          {disableExport ? (
            <span
              className="table-export ms-3"
              style={{ opacity: 0.7, cursor: "no-drop" }}
            >
              <Export className="export-icon" height={25} width={25} />
              Export
            </span>
          ) : (
            <Dropdown
              className="export-dp"
              onClick={scrollDownOnce}
              ref={dropdownRef}
            >
              <Dropdown.Toggle
                variant=""
                className="bg-transparent border-0 cursor-pointer"
                id="dropdown-basic"
                disabled={disableExport}
              >
                <span className="table-export cursor-pointer ms-3">
                  <Export className="export-icon" height={25} width={25} />
                  Export
                </span>
              </Dropdown.Toggle>
              <Dropdown.Menu className="dp-active-menu">
                <Dropdown.Item eventKey="csv" onClick={exportCSV}>
                  CSV
                </Dropdown.Item>
                <Dropdown.Item eventKey="pdf" onClick={exportPDF}>
                  PDF
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          )}
        </div>
      </div>
      <div className="new-filter-chips">
        {visibleBadges.map((filter, index) => (
          <Badge key={filter?.id} className="new-filter-box">
            <div className="d-flex align-items-center justify-content-between">
              {getBadgeValue(filter)}
              <span
                className="px-0 pb-0 pt-0 ms-2 cursor-pointer"
                onClick={() => handleRemoveFilterByKey(filter, index)}
              >
                <Xmark />
              </span>
            </div>
          </Badge>
        ))}

        {remainingBadges > 0 && (
          <OverlayTrigger
            overlay={
              <Tooltip className="light-theme-filter-tooltip">
                <ul className="badge-container-chip">
                  {hiddenBadges.map((filter) => (
                    <li key={filter?.id}>
                      <div>{getBadgeValue(filter)}</div>
                    </li>
                  ))}
                </ul>
              </Tooltip>
            }
          >
            <Badge className="new-filter-box">+{remainingBadges}</Badge>
          </OverlayTrigger>
        )}

        {activeFilters.length > 0 && (
          <span
            className="clear-all-text cursor-pointer"
            onClick={handleClearAll}
          >
            Clear All
          </span>
        )}
      </div>
    </div>
  );
};

export default BaseFilter;
