import React, { useCallback, useEffect, useRef, useState } from "react";
import Terminal from "../../components/Terminal";
import { TerminalContextProvider } from "react-terminal";
import {
  Alert,
  Col,
  Container,
  Row,
} from "react-bootstrap";
import axios from "../../util/axios";
import { ReactComponent as IconWarning } from "../../assets/images/icon-warning.svg";
import { ReactComponent as IconCheckCircle } from "../../assets/images/icon-check-circle.svg";
import core from "../../util/core";
import Tree from "react-animated-tree";
import EasmForm from "./EasmForm";
import { useParams } from "react-router-dom";
import { showToast, showUpdateToast } from "../../util/toasts";

const Scanner = () => {
  const routeParams = useParams();
  const params = new URLSearchParams(window.location.search); // id=123

  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState("");
  const [easm, setEasm] = useState(null);

  const [uniqueIps, setUniqueIps] = useState([]);

  const [ipLocations, setIpLocations] = useState([["Country", "Servers"]]);
  const [domain, setDomain] = useState("");
  const [mailInfo, setMailInfo] = useState({});

  // const info = [];
  const [submitBtn, setSubmitBtn] = useState({});

  const renderHTML = (rawHTML) =>
    React.createElement("div", {
      dangerouslySetInnerHTML: { __html: core.nl2br(rawHTML) },
    });

  const checkMxTools = async (values, helpers) => {
    axios
      .get("easm/mail", { params: { domain: values["domain"] } })
      .then((res) => {
        setMailInfo(res.data);
      });
  };

  const fetchEasm = async () => {
    setIsLoading(true);
    if (routeParams.id > 0) {
      try {
        const params = {
          id: routeParams.id,
        };
        const { data } = await axios.get("/scans/view", { params });
        setEasm(data);
        setIsLoading(false);
      } catch (err) {
        setIsLoading(false);
        console.log(err);
        showToast("Unable to fetch details", "error", "top-center", "light", {
          toastId: "error1",
        });
      }
    }
  };

  const init = async () => {
    await fetchEasm();
  };

  const fetchData = async (values, helpers) => {
    const toastId = showToast(
      `Analyzing ${values["domain"]}`,
      "loading",
      "top-center",
      "light",
      {
        isLoading: !isLoading,
      }
    );
    try {
      setIsLoading(true);
      setData("");

      setDomain(values["domain"]);
      const queryParams = new URLSearchParams(values);

      const response = await fetch(
        process.env.REACT_APP_API_URL + "/easm/analyze?" + queryParams
      );
      if (!response.ok || !response.body) {
        throw response.statusText;
      }

      const reader = response.body.getReader();
      const decoder = new TextDecoder();

      while (true) {
        const { value, done } = await reader.read();
        if (done) {
          setIsLoading(false);
          showUpdateToast(
            toastId,
            "Analysis Completed",
            "success",
            false
          )
          break;
        }

        const decodedChunk = decoder.decode(value, { stream: true });
        setData((prev) => prev + decodedChunk);
      }
    } catch (error) {
      setIsLoading(false);
      console.log("my error", error);
      showUpdateToast(
        toastId,
        "Something went wrong",
        "error",
        false
      )
    }
  };

  useEffect(() => {
    init();
  }, []);

  useEffect(() => {
    if (isLoading) {
      setSubmitBtn({ disabled: true, label: "Analyzing..." });
    } else {
      setSubmitBtn({ disabled: false, label: "Analyze" });
    }
  }, [isLoading]);

  const handleSubmit = (values, helpers) => {
    checkMxTools(values, helpers);
    fetchData(values, helpers);
  };

  const renderNmapInfo = () => {
    const config = (open) => ({
      from: { height: 0, opacity: 0, transform: "translate3d(20px,0,0)" },
      to: {
        height: open ? "auto" : 0,
        opacity: open ? 1 : 0,
        transform: open ? "translate3d(0px,0,0)" : "translate3d(20px,0,0)",
      },
    });

    const SpecialTree = (props) => <Tree {...props} springConfig={config} />;

    if (mailInfo?.nmapInfo) {
      console.log("len", mailInfo?.nmapInfo.length);
      return mailInfo?.nmapInfo?.map((portGroup) => {
        return (
          <>
            <SpecialTree
              open={true}
              content={
                <>
                  <i className="bi bi-server text-success"></i>{" "}
                  {`${portGroup.ip}  ${portGroup?.osNmap || "N/A"}`}
                </>
              }
            >
              <SpecialTree
                open={true}
                content={`Ports (${core.numberFormat(
                  portGroup?.openPorts.length || 0
                )})`}
              >
                {portGroup.openPorts.map((item) => {
                  return (
                    <SpecialTree
                      content={`${item.service} (${item.protocol}/${item.port})`}
                    ></SpecialTree>
                  );
                })}
              </SpecialTree>
            </SpecialTree>
          </>
        );
      });
    }
    return null;
  };

  const renderMailboxInfo = () => {
    if (mailInfo?.data?.length > 0) {
      return mailInfo.data.map((item) => {
        if (item.key == "domain") {
          // return renderMapInfo(item);
          return null; //we will display some where else...
        } else {
          return (
            <>
              <div>
                <h3 className="text-uppercase">
                  {"error" == item.status ? (
                    <IconWarning className="align-middle me-2" width="24" />
                  ) : (
                    <IconCheckCircle className="align-middle me-2" width="24" />
                  )}
                  {item.key}{" "}
                </h3>
                <Alert
                  key={item.status}
                  variant={"error" == item.status ? "danger" : item.status}
                >
                  <pre>
                    <code>
                      {"error" == item.status
                        ? item.msg
                        : JSON.stringify(item.data)}
                    </code>
                  </pre>
                </Alert>
              </div>
            </>
          );
        }
      });
    }
    return null;
  };

  const onDomainChange = (e, cb) => {
    let extractr = e.target.value.match(
      /^(?:https?:\/\/)?(?:[^@\n]+@)?(?:www\.)?([^:\/\n]+)/im
    );
    if (extractr && extractr.length > 0) e.target.value = extractr[1];

    cb(e);
  };

  return (
    <React.Fragment>
      <Container fluid>
        <Row>
          <Col>
            <EasmForm item={easm} loadMore={fetchEasm} />
          </Col>
        </Row>
      </Container>
    </React.Fragment>
  );
};

export default Scanner;
