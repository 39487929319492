import React, { useEffect } from "react";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import moment from "moment";
import scanning from "../../assets/images/scanning.png";
import { ThreeCircles } from "react-loader-spinner";
import { ReactComponent as Check } from "../../assets/images/check.svg";
import { ReactComponent as Stop } from "../../assets/images/Stop.svg"


function BreadCrumb({
  isLastUpdate = true,
  title,
  breadcrumbItems,
  status,
  endTime = new Date(),
  showScanning = true,
  isStopScan = false,
  scannerStop
}) {
  const formatDate = (date) => {
    return moment(date).isValid()
      ? moment(date).format("MMM D, YYYY HH:mm")
      : "Invalid date";
  };

  const getStatusMessage = () => {
    switch (status) {
      case 3:
        return "Completed";
      case -1:
        return "Completed with Error";
      case 4:
        return "Stopped";
      case "Interrupted":
        return "Interrupted";
      case "Done":
        return "Done";
      case "Requested":
        return "Requested";
      case "Stopped":
        return "Stopped";
      case "Temporary error":
        return "Temporary error";
      case "Running":
        return "Scanning...";
      default:
        return "Loading...";
    }
  };

  const statusMessage = getStatusMessage();
  const lastUpdatedDate = formatDate(endTime);
  const user = JSON.parse(localStorage.getItem("user"));
  const isAdmin = user?.member_level == 9;
  useEffect(() => {

  }, [endTime]);

  return (
    <React.Fragment>
      <div>
        <div className="fontfam text-white breadcrumb-main">
          {title ? (
            <div className="fs-5 fw-bold">{title}</div>
          ) : (
            <Breadcrumb>
              {breadcrumbItems.map((item, index) => (
                <React.Fragment key={index}>
                  <Breadcrumb.Item
                    className="breadcrumb-list"
                    as="div"
                    href="#"
                    active={index === breadcrumbItems.length - 1}
                  >
                    {item}
                  </Breadcrumb.Item>
                  {index < breadcrumbItems.length - 2 && (
                    <span className="mx-2" > - </span>
                  )}
                </React.Fragment>
              ))}
              {showScanning && (
                <span className="d-flex">
                  {status === 3 || status === "Done" ? (
                    <Check
                      height={25}
                      width={25}
                      style={{ margin: "0px 2px 0px 7px" }}
                    />
                  ) : status === "Interrupted" ||
                    status === "Stopped" ||
                    status === 4 ||
                    status === "Temporary error" ? (
                    <i
                      className="bi bi-exclamation-circle d-inline-block"
                      style={{ margin: "0px 5px 0px 10px" }}
                    ></i>
                  ) : (
                    <ThreeCircles
                      height="20"
                      width="20"
                      color="#fff"
                      ariaLabel="circles-loading"
                      wrapperStyle={{ margin: "0px 5px 0px 10px" }}
                      wrapperClass=""
                      visible={true}
                    />
                  )}{" "}
                  {statusMessage}
                </span>
              )}
            </Breadcrumb>
          )}
          <div className="d-flex align-items-center">
            {isLastUpdate ? (
              <div>
                <span className="fw-bold">Last Updated</span> | {lastUpdatedDate}
              </div>
            ) : null}
            {
              isStopScan &&
              <div className="">
                <span>
                  {
                    (status == 0 || status == 1 || status == 2) && isAdmin ?
                      (
                        <>
                          <div className="d-flex align-items-center">
                            <div className="scanner-text-container mx-2">
                              <span className="fw-bold">Scanning in Progress</span>
                            </div>
                            <div className="d-flex align-items-center cursor-pointer" onClick={scannerStop}>
                              <Stop />
                              <span className="stop-text">
                                Stop
                              </span>
                            </div>
                          </div>

                        </>
                      ) :
                      status == 4 ?
                        <div className="color-grey-dim mx-2">
                          <span className="fw-bold">Scanning Stopped</span>
                        </div> :
                        ""
                  }

                </span>
              </div>

            }

          </div>

        </div>



        <div className="horizontal-rule"></div>

      </div>
    </React.Fragment>
  );
}

export default BreadCrumb;
