import React, { useEffect, useState } from "react";
import { Formik, Form, Field } from "formik";
import { useNavigate, useParams } from "react-router-dom";
import * as Yup from "yup";
import axios from "../../util/axios";
import logopng from "../../assets/images/logo.png";
import {
  FormGroup,
  FloatingLabel,
  FormControl,
  Button,
  Stack,
} from "react-bootstrap";
import { ToastContainer, toast } from "react-toastify";
import Linker from "../../components/Linker";
import "react-toastify/dist/ReactToastify.css";
import { EyeFill, EyeSlashFill } from "react-bootstrap-icons";
import PasswordRequirements from "../../components/password-requirement/PasswordRequirements";
import { showToast } from "../../util/toasts";

const schema = Yup.object().shape({
  password: Yup.string()
    .matches(
      /^(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$&*]).{8,}$/,
      "Password must be at least 8 characters, with at least one uppercase letter, one number, and one special character"
    )
    .required("Password is required"),
  confirmPassword: Yup.string()
    .oneOf([Yup.ref("password"), null], "Confirm Password does not match")
    .required("Confirm Password is required"),
});

const ResetPassword = () => {
  const navigate = useNavigate();
  const routeParams = useParams();
  const [submitBtn, setSubmitBtn] = useState({});
  const [invitation, setInvitation] = useState(null);
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [isPasswordValid, setIsPasswordValid] = useState(false);
  const initialValues = {
    code: "",
    password: "",
    confirmPassword: "",
    user_id: null,
  };
  const [isLoading, setIsLoading] = useState(false);

  let API_ENDPOINT_NODE = "/reset-password";

  const init = async () => {
    await fetchInvitation();
  };
  const fetchInvitation = async () => {
    const params = {
      code: routeParams.code,
    };
    try {
      const { data } = await axios.get("/reset-invitation", { params });
      setInvitation(data.data);
      if (data.data.code) {
        initialValues.code = data.data.code;
      }
    } catch (err) {
      if (err.response.status === 404) {
        setTimeout(() => {
          navigate("/auth/login?iu=1");
        }, 500);
      }
    }
  };
  const handleSubmit = (values, helpers) => {
    setIsLoading(true);
    if (invitation) {
      values.invitation_id = invitation.id;
      values.user_id = invitation.user_id;
    }
    axios
      .post(API_ENDPOINT_NODE, values)
      .then((response) => {
        const data = response.data;
        console.log("API response:", response);

        showToast(data.message, "success", "top-center", "light");

        setTimeout(() => {
          navigate("/auth/login");
        }, 2000);
      })
      .catch((e) => {
        setIsLoading(false);
        if (e.response && e.response.status === 422) {
          const errors = e.response.data;
          errors.forEach((error) => {
            helpers.setFieldError(error.path, error.msg);
          });
        } else {
          showToast(
            `${e?.response?.data?.message}`,
            "error",
            "top-center",
            "light",
            { autoClose: 5000 }
          );
        }
      });
  };

  useEffect(() => {
    if (isLoading) {
      setSubmitBtn({ disabled: true, label: "Please wait.." });
    } else {
      setSubmitBtn({ disabled: false, label: "Reset" });
    }
    init();
  }, [isLoading]);

  useEffect(() => {
    init();
  }, []);

  return (
    <React.Fragment>
      {/* new reset password */}
      <div className="reset-fragment">
        <div className="reset-main">
          <img src={logopng} alt="Logo" className="mt-3 d-block mx-auto img-fluid logo-img" /> 
          <h2 className="mt-4 text-center ">Reset Password </h2>
          <div className="reset-form">
            <div className="reset-input">
              <Formik
                validationSchema={schema}
                initialValues={initialValues}
                onSubmit={handleSubmit}
                render={({
                  handleChange,
                  handleSubmit,
                  handleBlur,
                  values,
                  errors,
                  validateForm,
                }) => (
                  <Form>
                    <Field
                      name="password"
                      render={({ field, form: { touched, errors }, meta }) => (
                        <FormGroup className="mt-3 " controlId="password">
                          <label controlId="floatingPassword">
                            New password{" "}
                          </label>
                          <FormControl
                            placeholder="Enter a new password "
                            type={showPassword ? "text" : "password"}
                            {...field}
                            className={
                              meta.touched && meta.error
                                ? "is-invalid password-field"
                                : ""
                            }
                          />
                          {meta.touched && meta.error ? (
                            <div className="invalid-feedback">{meta.error}</div>
                          ) : null}
                        </FormGroup>
                      )}
                    />
                    <Field
                      name="confirmPassword"
                      render={({ field, form: { touched, errors }, meta }) => (
                        <FormGroup
                          className="mt-3 "
                          controlId="confirmPassword"
                        >
                          <label controlId="floatingConfirmPassword">
                            Confirm password
                          </label>
                          <FormControl
                            placeholder="Confirm Your Password"
                            type={showConfirmPassword ? "text" : "password"}
                            {...field}
                            className={
                              meta.touched && meta.error
                                ? "is-invalid password-field position-relative"
                                : ""
                            }
                          />
                          {meta.touched && meta.error ? (
                            <div className="invalid-feedback">{meta.error}</div>
                          ) : null}
                        </FormGroup>
                      )}
                    />
                    <hr />
                    <div className="mt-2">
                      <PasswordRequirements
                        password={values.password}
                        setPasswordValid={setIsPasswordValid}
                      />
                    </div>
                    <Button
                      type="submit"
                      className="btn btn-primary btn-full mx-auto mt-4 "
                      id="singup-btn"
                      disabled={submitBtn.disabled}
                    >
                      {submitBtn.label}
                    </Button>
                    <div className="d-flex justify-content-center align-items-center ">
                      <Linker
                        to={"/auth/login"}
                        title="Proceed to Login"
                        className="m-1 cancel "
                      >
                        {"Back to login"}
                      </Linker>
                    </div>
                  </Form>
                )}
              />
            </div>
          </div>
        </div>
      </div>
      <ToastContainer position={toast.POSITION.BOTTOM_RIGHT} theme="dark" />
    </React.Fragment>
  );
};

export default ResetPassword;
