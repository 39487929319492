import React, { useMemo, useState } from "react";
import "./CurrentStatus.scss";
import { OverlayTrigger, Popover } from "react-bootstrap";
import { ReactComponent as InfoIcon } from "../../assets/images/circle-info.svg";
import moment from "moment";

const CurrentStatus = ({ status, tooltipInfo, headerKeys }) => {
  const [showTooltip, setShowTooltip] = useState(false);

  const handleMouseEnter = () => {
    setShowTooltip(true);
  };

  const handleMouseLeave = () => {
    setShowTooltip(false);
  };

  const backgroundColor = (colorName) => {
    switch (colorName) {
      case 1:
        return "#3CE2C1";
      case 2:
        return "#87C2F3";
      case 0:
        return "#FFFFFF";
      case 3:
        return "#A7A7B1";
      default:
        return;
    }
  };

  const label =
    status == 1
      ? "New"
      : status == 2
        ? "Changed"
        : status == 0
          ? "Unchanged"
          : status == 3
            ? "Removed"
            : "";

  const isValidISODate = (dateString) => {
    const regex = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}\.\d{3}Z$/;
    return regex.test(dateString);
  };

  const formatItemValue = useMemo(
    () => (value) => {
      return Array.isArray(value)
        ? value
          ?.map((el, index) => {
            if (typeof el === "string") {
              return index === value.length - 1 ? el : `${el} | `;
            }
            if (el?.login) {
              return `Username: ${el.login} | Password: ${el.password}`;
            }
          })
        : typeof value === "string"
          ? isValidISODate(value)
            ? moment(value).format("DD/MM/YYYY")
            : value === ""
              ? "-"
              : value
          : typeof value === "boolean" || typeof value === "number"
            ? !value || value === 0
              ? "No"
              : "Yes"
            : "-";
    },
    []
  );

  const memoizedTooltipInfo = useMemo(() => {
    return tooltipInfo
      ?.filter((item) => item !== null && item !== undefined) 
      .map((item) => ({
        oldValue: formatItemValue(item?.old),
        newValue: formatItemValue(item?.new),
        field: item?.field,
      }));
  }, [tooltipInfo, formatItemValue]);

  return (
    <div
      className="current-status-main"
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <div
        style={{ backgroundColor: backgroundColor(status) }}
        className={`current-state-color-container `}
      ></div>
      <div>{label}</div>
      {status === 2 ? (
        <OverlayTrigger
          show={showTooltip}
          placement="top"
          overlay={
            <Popover
              id={`tooltip-${status}`}
              className="changed-custom-popover-arrow"
            >
              <Popover.Body
                className="changed-tool-body"
                style={{
                  paddingTop: "20px",
                  paddingRight: "10px",
                  paddingLeft: "0px",
                }}
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
              >
                <div
                  style={{
                    maxHeight: "215px",
                    overflowY: "auto",
                    padding: "0px 5px",
                    borderRadius: "10px",
                    scrollbarWidth: "thin",
                  }}
                >
                  <ul>
                    {memoizedTooltipInfo?.map((item) => (
                      <li key={`${item.field}-${item.newValue}`}>
                        <div style={{ color: "#67676A" }}>
                          {headerKeys[item?.field]} changes
                        </div>
                        <div
                          style={{
                            color: "#0A0A0E",
                            fontWeight: 500,
                          }}
                        >
                          From: {item.oldValue}
                        </div>
                        <div
                          style={{
                            color: "#0A0A0E",
                            fontWeight: "bold",
                            marginBottom: "10px",
                          }}
                        >
                          To: {item.newValue}
                        </div>
                      </li>
                    ))}
                  </ul>
                </div>
              </Popover.Body>
            </Popover>
          }
        >
          <InfoIcon className="cursor-pointer current-status-icon" />
        </OverlayTrigger>
      ) : null}
    </div>
  );
};

export default CurrentStatus;
