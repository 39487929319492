import React from "react";

const CriticalityTag = ({ level }) => {
  const levelLabels = {
    1: "Low",
    2: "Medium",
    3: "High",
  };
  const levelLabel = levelLabels[level] || "Unknown";

  return (
    <div className="issues-assets-tag d-flex align-items-center me-2 mb-2">
      <span className="text-unwrap">{levelLabel} Criticality</span>
    </div>
  );
};

export default CriticalityTag;
