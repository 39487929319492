import React, { useState, useEffect, useRef, useContext, useMemo } from "react";
import WebScanner from "../../../../components/web-scanner/WebScanner";
import WebUpdate from "../../../../components/website-updates/WebUpdate";
import BaseTable from "../../../../components/table/BaseTable";
import BaseFilter from "../../../../components/filter/BaseFilter";
import axios from "../../../../util/axios";
import { useParams, useOutletContext, useNavigate } from "react-router-dom";
import { ThreeCircles } from "react-loader-spinner";
import GenericCard from "../../../../components/card/GenericCard";
import DecisionToggle from "../../../../components/badge/DecisionToggle";
import DoughnutChart from "../../../../components/charts/DoughnutChart";
import NoData from "../../../../components/empty/NoData";
import BarChart from "../../../../components/charts/BarChart";
import { NoDataAfterScanning } from "../../../../components/empty/NoDataAfterScanning";
import { Badge } from "react-bootstrap";
import { ScannerContext } from "../../../../components/ScannerContext";
import { formatScannerName } from "../../../../helpers/formatScannerName.js";
import moment from "moment";
import CurrentStatus from "../../../../components/status/CurrentStatus.js";
import {
  webUpdatesFilter,
  webDataFillter,
} from "../../../../util/genericFunctions.js";
import TestedPopover from "../../../../components/shared/TestedPopover.js";
import FTPDefaultCredentials from "./FTP-Default-Credentials.js";
import {
  passAuthFilter,
  encryptionFilter,
  ftpAuthFilter,
  dictionaryAttackFilter,
} from "./filterOptions.js";
import { parseFilterString, applyCondition } from "../../../../util/conditions";
import { ftpTabsConfig } from "../../../../util/tabsConfig.js";

const FtpAuthentication = () => {
  const { dropdownItems } = useContext(ScannerContext);
  const { scanningStatus } = useOutletContext();
  const routeParams = useParams();
  const navigate = useNavigate();
  const [isDataisPartiallyProcessing, setIsDataisPartiallyProcessing] =
    useState(false);
  const [searchValue, setSearchValue] = useState("");
  const [noDataDoughtnutChart, setNoDataDoughtnutChart] = useState(false);
  const [noDataBarChart, setNoDataBarChart] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [activeFilters, setActiveFilters] = useState([]);
  const [webUpdatesData, setWebUpdatesData] = useState(null);

  const [ftpAuthFilters, setftpAuthFilters] = useState(ftpAuthFilter);
  const [chartData, setChartData] = useState({
    labels: ["Secured", "Unsecured"],
    datasets: [
      {
        data: [],
        backgroundColor: ["#3DDC97", "#FF6155"],
        hoverOffset: 4,
        borderColor: "transparent",
        borderWidth: 0,
        borderAlign: "center",
      },
    ],
  });
  const [isDataInProcess, setIsDataInProcess] = useState(false);
  const [data, setData] = useState([]);
  const [barChartType, setBarChartType] = useState([]);
  const [tabs, setTabs] = useState([]);
  const [barChartData, setBarChartData] = useState({
    labels: ["Vulnerable", "Not Vulnerable"],
    datasets: [
      {
        label: "",
        data: [],
        backgroundColor: ["#FF6155", "#3DDC97"],
        borderColor: ["rgb(255, 99, 132)", "rgb(75, 192, 192)"],
        borderWidth: 1,
      },
    ],
  });

  const updateTabsWithData = (data) => {
    const ips = Array.from(new Set(data.map((ftp) => ftp.ip))).map(
      (ip, index) => ({
        id: index,
        name: ip,
        type: "IP Address: " + ip,
        key: "1",
        active: false,
      })
    );

    const portNumber = Array.from(new Set(data.map((ftp) => ftp.port_no)))
      ?.filter((name) => name)
      .map((port, index) => ({
        id: index,
        name: port,
        type: "Port: " + port,
        key: "2",
        active: false,
      }));
    setTabs(
      ftpTabsConfig({
        ips,
        portNumber,
        encryptionFilter,
        passAuthFilter,
        dictionaryAttackFilter,
      })
    );
  };

  const columns = [
    { Header: "IP Address", accessor: "ip", isSortable: true, key: "ip" },
    {
      Header: "Port",
      accessor: "port_no",
      isSortable: true,
      key: "port_no",
    },
    {
      Header: "Encryption",
      key: "is_encrypted",
      isSortable: true,
      accessor: (row) => (
        <div
          className={` ${
            row?.is_encrypted === 1 ? "no-pointer" : "cursor-pointer"
          }`}
          onClick={() => {
            row.is_encrypted === 0 &&
              navigate(
                `/security-issues/${routeParams?.target_id}?ip=${row.ip}&armoryIdParam=arm-op2`
              );
          }}
        >
          <DecisionToggle
            label={row?.is_encrypted === 1 ? "Yes" : "No"}
            isIndication={row?.is_encrypted === 1 ? true : false}
          />
        </div>
      ),
    },
    {
      Header: "Anonymous Authentication",
      key: "pass_auth_method",
      isSortable: true,
      accessor: (row) => (
        <div
          className={` ${
            row?.pass_auth_method === 1 ? "cursor-pointer" : "no-pointer"
          }`}
          onClick={() => {
            row.pass_auth_method === 1 &&
              (row.is_encrypted === 0
                ? navigate(
                    `/security-issues/${routeParams?.target_id}?ip=${row.ip}&armoryIdParam=arm-ftp2`
                  )
                : navigate(
                    `/security-issues/${routeParams?.target_id}?ip=${row.ip}&armoryIdParam=arm-ftp4`
                  ));
          }}
        >
          <DecisionToggle
            label={row?.pass_auth_method === 1 ? "Yes" : "No"}
            isIndication={row?.pass_auth_method === 1 ? false : true}
          />
        </div>
      ),
    },
    {
      Header: <TestedPopover title="Dictionary Attack" />,
      key: "username",
      isSortable: true,
      width: "28",
      accessor: (row) => <FTPDefaultCredentials row={row} />,
    },
    {
      Header: "First Detected",
      key: "first_detected",
      isSortable: true,
      accessor: (row) => {
        return (
          <div>
            {row?.first_detected
              ? moment(row?.first_detected).format("DD/MM/YY")
              : ""}
          </div>
        );
      },
    },
    {
      Header: "Current State",
      key: "current_state",
      isSortable: true,
      accessor: (row) => {
        return (
          <div>
            {row?.change_status != null ? (
              <CurrentStatus
                status={row?.change_status}
                tooltipInfo={row?.changes}
                headerKeys={{
                  ip: "IP Address",
                  port: "Port",
                  is_encryption: "Encryption",
                  pass_auth_method: "Anonymous Authentication",
                  creds: "Default Credentials",
                  first_detected: "First Detected",
                  change_status: "Current State",
                }}
              />
            ) : (
              ""
            )}
          </div>
        );
      },
    },
  ];

  const CircularChartoptions = {
    responsive: true,
    maintainAspectRatio: false,
    cutout: "80%",
    plugins: {
      legend: {
        display: false,
      },
      title: {
        display: false,
        text: "Secured vs Unsecured Email Configuration",
        color: "white",
        font: {
          family: "Kodchasan",
          size: 24,
        },
      },
    },
    onHover: (event, elements) => {
      event.native.target.style.cursor = elements.length
        ? "pointer"
        : "default";
    },
  };

  const barChartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: true,
        position: "bottom",
        labels: {
          color: "white",
          fillStyle: "none",
          padding: 30,
          usePointStyle: true,
          pointStyle: "rectRounded",
          boxSize: 0,
          boxWidth: 0,
          font: {
            family: "Kodchasan",
          },
        },
      },
      tooltip: {
        backgroundColor: "white",
        titleColor: "black",
        bodyColor: "black",
        padding: 20,
        yPadding: 90,
        callbacks: {
          title: function () {
            return "";
          },
          label: function (context) {
            const label = context.dataset.label || "";
            const value = context.raw;
            return `${label}: ${value}`;
          },
        },
        yAlign: "bottom",
        xAlign: "center",
        titleFont: {
          family: "Kodchasan",
        },
        bodyFont: {
          family: "Kodchasan",
        },
      },
      datalabels: {
        color: "white",
        anchor: "end",
        align: "top",
        formatter: (value, context) => {
          return value;
        },
        font: {
          family: "Kodchasan",
        },
      },
    },
    scales: {
      y: {
        beginAtZero: true,
        ticks: {
          color: "white",
          precision: 0,
          font: {
            family: "Kodchasan",
          },
        },
        border: { dash: [4, 4] },

        grid: {
          color: "rgba(255, 255, 255, 0.1)",
          borderDash: [10, 10],
        },
      },
      x: {
        ticks: {
          color: "white",
          font: {
            family: "Kodchasan",
          },
        },
        display: true,
        grid: {
          color: "transparent",
          drawOnChartArea: true,
          borderDash: [10, 10],
          borderDashOffset: function (context) {
            return context.index === context.chart.data.labels.length ? 0 : 10;
          },
        },
        border: {
          color: "#959595",
          width: 1,
        },
      },
    },
    layout: {
      padding: {
        top: 30,
        bottom: -40,
      },
    },
    onHover: (event, elements) => {
      event.native.target.style.cursor = elements.length
        ? "pointer"
        : "default";
    },
  };

  const handleWebUpdateSort = (name) => {
    setActiveFilters(webUpdatesFilter(name, activeFilters));
  };

  const handleSearchChange = (e) => {
    setSearchValue(e.target.value);
  };

  const fetchFtpData = async () => {
    try {
      const { data } = await axios.get(`scans/${routeParams?.target_id}/ftp`);
      if (data?.groups) {
        setWebUpdatesData(data?.groups?.changes);

        setChartData({
          labels: ["Secured", "Unsecured"],
          datasets: [
            {
              data: [
                data?.groups?.auth?.true || 0,
                data?.groups?.auth?.false || 0,
              ],
              backgroundColor: ["#3DDC97", "#FF6155"],
              hoverOffset: 4,
              borderColor: "transparent",
              borderWidth: 0,
              borderAlign: "center",
            },
          ],
        });
        setBarChartData({
          labels: ["Vulnerable", "Not Vulnerable"],
          datasets: [
            {
              label: "Vulnerable",
              data: [
                data?.groups?.creds?.true || 0,
                data?.groups?.creds?.false || 0,
              ],
              backgroundColor: ["#FF6155", "#3DDC97"],
              borderColor: ["rgb(255, 99, 132)", "rgb(75, 192, 192)"],
              borderWidth: 1,
              maxBarThickness: 70,
              borderRadius: {
                topLeft: 5,
                topRight: 5,
              },
            },
            // {
            //   label: "Not Found",
            //   data: [data?.groups?.creds?.false || 0],
            //   backgroundColor: ["#3DDC97"],
            //   borderColor: ["rgb(75, 192, 192)"],
            //   borderWidth: 1,
            //   maxBarThickness: 70,
            //   borderRadius: {
            //     topLeft: 5,
            //     topRight: 5,
            //   },
            // },
          ],
        });
      }
      if (data?.ftp?.length > 0) {
        let tableData = data?.ftp?.map((item) => {
          return {
            id: item?.id,
            ip: item?.ip?.ip,
            port_no: item?.scan_ports?.port,
            is_encrypted: item?.scan_ports?.is_encrypted ? 1 : 0,
            pass_auth_method: item?.is_ftp_anon ? 1 : 0,
            creds: item?.creds,
            username: item?.creds?.length > 0 ? item?.creds[0]?.login : "",
            password: item?.creds?.length > 0 ? item?.creds[0]?.password : "",
            username2: item?.creds?.length > 0 ? item?.creds[1]?.login : "",
            password2: item?.creds?.length > 0 ? item?.creds[1]?.password : "",
            username3: item?.creds?.length > 0 ? item?.creds[2]?.login : "",
            password3: item?.creds?.length > 0 ? item?.creds[2]?.password : "",
            first_detected: item?.first_detected,
            change_status: item?.change_status,
            changes: item?.changes,
          };
        });
        updateTabsWithData(tableData);
        setData(tableData);
      }
      if (
        scanningStatus === -1 ||
        scanningStatus === 3 ||
        scanningStatus === 4 ||
        scanningStatus === 1 ||
        scanningStatus === 2
      ) {
        if (data?.groups?.creds?.true || data?.groups?.creds?.false) {
          setNoDataBarChart(false);
        } else {
          setNoDataBarChart(true);
        }
        if (data?.groups?.auth?.true || data?.groups?.auth?.false) {
          setNoDataDoughtnutChart(false);
        } else {
          setNoDataDoughtnutChart(true);
        }
      }
      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
    }
  };

  const fetchIntervalRef = useRef();
  useEffect(() => {
    clearInterval(fetchIntervalRef.current);

    if (scanningStatus === 0) {
      setIsLoading(false);
      setIsDataInProcess(true);
    } else {
      setIsDataInProcess(false);
      if (
        scanningStatus === 3 ||
        scanningStatus === -1 ||
        scanningStatus === 4
      ) {
        setIsDataisPartiallyProcessing(false);
        setIsLoading(true);
        fetchFtpData();
      } else if (scanningStatus === 2 || scanningStatus === 1) {
        setIsDataisPartiallyProcessing(true);
        setIsLoading(true);
        fetchFtpData();
        fetchIntervalRef.current = setInterval(fetchFtpData, 5000);
      }
    }
    return () => {
      clearInterval(fetchIntervalRef.current);
    };
  }, [scanningStatus, routeParams?.target_id]);

  const handleFilterChange = (updatedActiveFilters, updatedTabs) => {
    setActiveFilters(updatedActiveFilters);
    setTabs(updatedTabs);
  };

  const removeFilter = (updatedFilters, updatedTabs) => {
    setActiveFilters(updatedFilters);
    setTabs(updatedTabs);
  };

  const statusMap = { new: 1, changed: 2, unchanged: 0 };

  const groupedFilters = activeFilters.reduce((acc, filter) => {
    if (!acc[filter.eventKey]) {
      acc[filter.eventKey] = [];
    }
    acc[filter.eventKey].push(filter);
    return acc;
  }, {});

  const filteredFtp = data.filter((ftp) => {
    const matchesFilters = Object.keys(groupedFilters).every((eventKey) => {
      return groupedFilters[eventKey].some((filter) => {
        const lowerCaseFilter =
          filter.eventKey == "2" ? filter.name : filter.name.toLowerCase();
        if (eventKey === "1") {
          return ftp.ip && ftp.ip.toLowerCase() === lowerCaseFilter;
        } else if (eventKey === "2") {
          return ftp.port_no && ftp.port_no == lowerCaseFilter;
        } else if (eventKey === "4") {
          return (
            (lowerCaseFilter === "no" && !ftp.is_encrypted) ||
            (lowerCaseFilter === "yes" && ftp.is_encrypted)
          );
        } else if (eventKey === "3") {
          return (
            (lowerCaseFilter === "no" && !ftp.pass_auth_method) ||
            (lowerCaseFilter === "yes" && ftp.pass_auth_method)
          );
        } else if (eventKey === "5") {
          if (filter.name === "Unsecured") {
            return (
              !ftp.is_encrypted ||
              ftp.pass_auth_method ||
              (Array.isArray(ftp.creds) &&
                ftp.creds.some((cred) => cred?.login === null))
            );
          } else if (filter.name === "Secured") {
            return (
              ftp.is_encrypted &&
              !ftp.pass_auth_method &&
              (!ftp.creds ||
                (Array.isArray(ftp.creds) &&
                  ftp.creds.every(
                    (cred) => cred?.login !== null && cred?.login !== ""
                  ) &&
                  ftp.username !== "" &&
                  ftp.username2 !== "" &&
                  ftp.username3 !== ""))
            );
          }
        } else if (eventKey === "7") {
          const isVulnerable = ftp.creds?.length > 0;
          if (lowerCaseFilter === "not vulnerable") {
            return !isVulnerable;
          } else if (lowerCaseFilter === "vulnerable") {
            return isVulnerable;
          }
        } else if (eventKey === "6") {
          return ftp?.change_status === statusMap[filter.name.toLowerCase()];
        } else if (eventKey === "advanced-filter") {
          const parsedFilters = parseFilterString(filter.name);
          return parsedFilters.every((ol) => {
            const { column, condition, value } = ol;
            switch (column) {
              case "ip":
                return applyCondition(
                  ftp.ip?.toLowerCase(),
                  condition,
                  value?.toLowerCase()
                );
              case "port":
                return applyCondition(
                  ftp.port_no?.toLowerCase(),
                  condition,
                  value?.toLowerCase()
                );
              case "pass_auth_method":
                return (
                  (value?.toLowerCase() === "no" && !ftp.pass_auth_method) ||
                  (value?.toLowerCase() === "yes" && ftp.pass_auth_method)
                );
              case "is_encrypted":
                return (
                  (value?.toLowerCase() === "no" && !ftp.is_encrypted) ||
                  (value?.toLowerCase() === "yes" && ftp.is_encrypted)
                );
              case "creds":
                const isVulnerable = ftp.creds?.length > 0;
                if (
                  value?.toLowerCase() === "not vulnerable" ||
                  value?.toLowerCase() === "yes"
                ) {
                  return condition == "is" ? !isVulnerable : isVulnerable;
                } else if (
                  value?.toLowerCase() === "vulnerable" ||
                  value?.toLowerCase() === "no"
                ) {
                  return condition == "is" ? isVulnerable : !isVulnerable;
                }
              case "current_state":
                return applyCondition(
                  ftp?.change_status,
                  condition,
                  statusMap[value.toLowerCase()]
                );

              default:
                return false;
            }
          });
        }
        return false;
      });
    });

    const matchesSearch =
      searchValue === "" ||
      (ftp.ip && ftp.ip.toLowerCase().includes(searchValue.toLowerCase())) ||
      (ftp.port_no &&
        ftp.port_no
          .toString()
          .toLowerCase()
          .includes(searchValue.toLowerCase()));

    return matchesFilters && matchesSearch;
  });
  const exportRows = useMemo(() => {
    return filteredFtp.map((ftp) => [
      ftp.ip,
      ftp.port_no,
      ftp.is_encrypted === 1 ? "Yes" : "No",
      ftp.pass_auth_method === 1 ? "Yes" : "No",
      ftp.creds?.length > 0
        ? `"${
            ftp.username
              ? `Username:${ftp.username}, Password:${ftp.password}`
              : ""
          }${
            ftp.creds?.length === 2
              ? `, Username2:${ftp.username2}, Password2:${ftp.password2}`
              : ""
          }${
            ftp.creds?.length === 3
              ? `, Username3:${ftp.username3}, Password3:${ftp.password3}`
              : ""
          }"`
        : "None",
    ]);
  }, [filteredFtp]);
  const toggleFilter = (name, filters) => {
    return filters.map((filter) => {
      if (filter.name === name) {
        return { ...filter, active: !filter.active };
      }
      return filter;
    });
  };
  const handleChartClick = (
    name,
    eventKey = "5",
    filterType = "FTP Auth. Method"
  ) => {
    const updatedFilters = toggleFilter(name, ftpAuthFilters);
    setftpAuthFilters(updatedFilters);
    setActiveFilters((prevActiveFilters) => {
      const isFilterAlreadyActive = prevActiveFilters.some(
        (filter) => filter.name === name && filter.eventKey === eventKey
      );
      if (isFilterAlreadyActive) {
        return prevActiveFilters.filter((filter) => filter.name !== name);
      } else {
        return webUpdatesFilter(name, prevActiveFilters, eventKey, filterType);
      }
    });
    setTabs((prevTabs) =>
      prevTabs.map((tab) => {
        if (tab.eventKey === eventKey) {
          return {
            ...tab,
            data: updatedFilters,
          };
        }
        return tab;
      })
    );
  };
  const handleBarChartClick = (name) => {
    const filterValue = name === "Vulnerable" ? "No" : "Yes";
    const updatedFilters = webUpdatesFilter(
      filterValue,
      activeFilters,
      "7",
      "Dictionary Attack"
    );
    const updatedCertificateExpiration = tabs[5].data.map((item) => ({
      ...item,
      active: updatedFilters.some((filter) => filter.name === item.name),
    }));
    setTabs((prevTabs) =>
      prevTabs.map((tab, index) =>
        index === 5 ? { ...tab, data: updatedCertificateExpiration } : tab
      )
    );
    setActiveFilters(updatedFilters);
  };
  const SecurityIssueEncryption = (ip, type) => {
    if (!type) {
      navigate(
        `/security-issues/${routeParams?.target_id}?ip=${ip}&armoryIdParam=arm-op2`
      );
    }
  };
  const SecurityIssueAnonymous = (ip, type) => {
    if (!type) {
      navigate(
        `/security-issues/${routeParams?.target_id}?ip=${ip}&armoryIdParam=arm-ftp2`
      );
    }
  };
  return (
    <React.Fragment>
      {isLoading ? (
        <div className="content-loader">
          <ThreeCircles
            visible={true}
            height="60"
            width="60"
            color="#ffff"
            ariaLabel="three-circles-loading"
            wrapperClass=""
          />
        </div>
      ) : (
        <div className="main_container_style">
          <div className="pb-4 row top-container-main web-updates-container">
            <div className="col-12 col-lg-6 h-100 web-updates-col-7">
              <div className="row gap-4 top-container-main-row">
                <div className="col-12 h-50">
                  {/* <GenericCard
                    children={<ComparisonChart cardsData={cardsData[0]} />}
                    title={"SSH"}
                  /> */}
                  <GenericCard
                    children={
                      isDataInProcess ? (
                        <NoData />
                      ) : isDataisPartiallyProcessing &&
                        noDataDoughtnutChart ? (
                        <NoData />
                      ) : noDataDoughtnutChart ? (
                        <NoDataAfterScanning />
                      ) : (
                        <DoughnutChart
                          data={chartData}
                          options={CircularChartoptions}
                          onHandleClick={handleChartClick}
                        />
                      )
                    }
                    title={"FTP Authentication Method"}
                    isCentered={false}
                  />
                </div>
                <div className="col-12 h-50">
                  <div className="row h-100">
                    <div className="col-5">
                      <GenericCard
                        children={
                          <WebUpdate
                            data={webUpdatesData}
                            onSort={handleWebUpdateSort}
                            dataInProcess={
                              isDataInProcess || isDataisPartiallyProcessing
                            }
                          />
                        }
                        title={"Updates"}
                      />
                    </div>
                    <div className="col-7">
                      <GenericCard
                        children={
                          isDataInProcess ? (
                            <NoData />
                          ) : isDataisPartiallyProcessing && noDataBarChart ? (
                            <NoData />
                          ) : noDataBarChart ? (
                            <NoDataAfterScanning />
                          ) : (
                            <BarChart
                              barData={barChartData}
                              optionsData={barChartOptions}
                              onBarClick={handleBarChartClick}
                              isData={
                                !!(
                                  barChartType &&
                                  barChartType.no !== undefined &&
                                  barChartType.yes !== undefined
                                )
                              }
                              dynamicLabels={["Vulnerable", "Not Vulnerable"]}
                              isChartLabelsOnTop
                            />
                          )
                        }
                        title={"FTP Dictionary Attack"}
                        subtitle={"View Issues"}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12 col-lg-6 h-100 web-updates-col-5">
              <WebScanner
                title={"FTP Authentication Security Scanner"}
                isscanner={false}
              />
            </div>
          </div>
          <div className="px-10 pb-3">
            {/* <div className="table-container">
              <div className="table-left">Results</div>
              <div>
                <BaseInput
                  type="text"
                  placeholder="Search..."
                  className="search-input"
                  icon={Search}
                  value={searchValue}
                  onChange={handleSearchChange}
                />
              </div>
            </div> */}
            <div className="py-1">
              <BaseFilter
                className="mt-3 mb-3"
                tabs={tabs}
                totalRecords={filteredFtp.length}
                onFilterChange={handleFilterChange}
                activeFilters={activeFilters}
                removeFilter={removeFilter}
                exportTitle={`FTPAuthenticationSecurity_${formatScannerName(
                  dropdownItems.filter((item) => {
                    return item.target_uuid === routeParams?.target_id;
                  })[0]?.title
                )}-${moment().format("DDMMMYYYY").toUpperCase()}`}
                exportHeader={[
                  "IP Address",
                  "Port",
                  "Encryption",
                  "Anonymous Authentication",
                  "Default Credentials",
                ]}
                exportRows={exportRows}
                isDatainProcess={isDataInProcess || isDataisPartiallyProcessing}
                searchValue={searchValue}
                onSearchChange={handleSearchChange}
                tableData={filteredFtp}
                isSearch={true}
              />
            </div>
            <div>
              <BaseTable
                className="mt-3 mb-3"
                columns={columns}
                data={filteredFtp}
                selectable={true}
                showCheckboxes={false}
                isDatainProcess={isDataInProcess || isDataisPartiallyProcessing}
                loading={isLoading}
              />
            </div>
          </div>
        </div>
      )}
    </React.Fragment>
  );
};

export default FtpAuthentication;
