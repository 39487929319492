import React, { useCallback, useEffect } from "react";
import { useState } from "react";

import { Triangle, ProgressBar } from "react-loader-spinner";
import {
  Button,
  Col,
  Container,
  FloatingLabel,
  FormCheck,
  FormControl,
  FormGroup,
  FormSelect,
  Image,
  OverlayTrigger,
  Row,
  Table,
  Tooltip,
} from "react-bootstrap";

import axios from "../../../util/axios";
import { Link, json, useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import EditableField from "../../../components/EditableField";
import { Field, Form, Formik } from "formik";
import { showToast } from "../../../util/toasts";

const AHref = ({ id, children, title, ...props }) => (
  <OverlayTrigger overlay={<Tooltip id={id}>{title}</Tooltip>}>
    <Link {...props}>{children}</Link>
  </OverlayTrigger>
);

const News = () => {
  const [item, setItem] = useState([]);
  const [id, setId] = useState("");
  const [title, setTitle] = useState("");
  const routeParams = useParams();
  const navigate = useNavigate();

  let typeHeading = "Pending";
  let type = null;
  if ("approved" == routeParams?.type) {
    typeHeading = "Approved";
    type = 1;
  }
  if ("rejected" == routeParams?.type) {
    typeHeading = "Rejected";
    type = 2;
  }

  const [fetching, setFetching] = useState(false);

  const initialValues = {
    id: "",
    title: "",
    open_text: "",
  };

  const handleSubmit = (values, helpers) => {
    setFetching(true);

    axios
      .post("/admin/news/update", values)
      .then((response) => {
        const data = response.data;
        setFetching(false);
        if (data.id > 0) {
          showToast(
            `News (${data.title}) has been updated`,
            "success",
            "top-center",
            "light",
            { autoClose: 5000 }
          );
          setTimeout(() => {
            navigate("/admin/news/");
          }, 500);
        }
      })
      .catch((e) => {
        setFetching(false);
        if (e.response.status === 422) {
          for (const fieldKey in e.response.data) {
            const field = e.response.data[fieldKey];
            helpers.setFieldError(field.path, field.msg);
          }
        }
      });
  };
  const init = async () => {
    if (routeParams?.id) {
      setFetching(true);
      await fetchItem(routeParams.id);
      setFetching(false);
    }
  };
  const fetchItem = async (id) => {
    try {
      try {
        let res = await axios.get("/admin/news/view", { params: { id } });
        console.log("data", res.data);
        setTitle(res.data.title);
        for (const key of ["id", "title", "open_text", "is_approved"]) {
          if ("is_approved" == key) {
            if (res.data[key] && res.data[key] <= 1) res.data[key] = 1;
          }
          initialValues[key] = res.data[key];
        }
      } catch (error) {
        console.log(error);
        throw error;
      }
    } finally {
      // setFetching(false);
      console.log("sfetching", fetching);
    }
  };

  useEffect(() => {
    init();
    console.log("loading...");
  }, [type]);

  return (
    <div>
      <Container className="py-5 max-80" fluid>
        <h1 className="fw-normal mb-5">
          <Image src="/images/icon-news.png" width={50} rounded /> Edit News :{" "}
          <small>{title ? title : id}</small>
        </h1>

        <Formik
          initialValues={initialValues}
          onSubmit={handleSubmit}
          render={({
            handleChange,
            handleSubmit,
            handleBlur,
            values,
            errors,
            validateForm,
          }) => (
            <Form>
              {/* Title Field */}
              <Field
                name="title"
                render={({ field, form: { touched, errors }, meta }) => (
                  <FormGroup controlId="title" className="my-3">
                    <FloatingLabel controlId="floatingPassword" label="Title">
                      <FormControl
                        type={"text"}
                        size="lg"
                        value={field.value}
                        onChange={field.onChange}
                        placeholder="News Title"
                        className={
                          meta.touched && meta.error ? "is-invalid" : ""
                        }
                        {...field}
                      />
                      {meta.touched && meta.error ? (
                        <div className="invalid-feedback">{meta.error}</div>
                      ) : null}
                    </FloatingLabel>
                  </FormGroup>
                )}
              />
              {/* id field */}
              <Field name="id" type="hidden" />

              {/* Open Field */}
              <Field
                name="open_text"
                render={({ field, form: { touched, errors }, meta }) => (
                  <FormGroup controlId="open_text" className="my-3">
                    <FloatingLabel controlId="floatingPassword" label="AI Text">
                      <FormControl
                        as="textarea"
                        size="lg"
                        style={{ height: "150px" }}
                        value={field.value}
                        onChange={field.onChange}
                        placeholder="News Title"
                        className={
                          meta.touched && meta.error ? "is-invalid" : ""
                        }
                        {...field}
                      />
                      {meta.touched && meta.error ? (
                        <div className="invalid-feedback">{meta.error}</div>
                      ) : null}
                    </FloatingLabel>
                  </FormGroup>
                )}
              />

              <Field
                as="select"
                name="is_approved"
                render={({ field, form: { touched, errors }, meta }) => (
                  <FormGroup controlId="action" className="">
                    <FloatingLabel
                      controlId="floatingSenderName"
                      label="Aproved Status"
                    >
                      <FormSelect
                        value={field.value}
                        onChange={field.onChange}
                        className={
                          meta.touched && meta.error ? "is-invalid" : " "
                        }
                        {...field}
                      >
                        <option value="">Select Action</option>
                        <option value="1">Approve</option>
                        <option value="2">Reject</option>
                      </FormSelect>
                      {meta.touched && meta.error ? (
                        <div className="invalid-feedback">{meta.error}</div>
                      ) : null}
                    </FloatingLabel>
                  </FormGroup>
                )}
              />
              <Button
                type="submit"
                className="btn bg-primary btn-full my-3"
                id="singup-btn"
                disabled={fetching}
              >
                {fetching ? "Submitting" : "Submit"}
                <ProgressBar
                  height="30"
                  width="30"
                  color="#000"
                  ariaLabel="triangle-loading"
                  wrapperStyle={{
                    justifyContent: "center",
                    marginLeft: "10px",
                  }}
                  wrapperClassName=""
                  visible={fetching}
                />
              </Button>
            </Form>
          )}
        />
      </Container>
    </div>
  );
};

export default News;
