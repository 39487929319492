import { Button, Modal } from "react-bootstrap";
import BaseCheckbox from "../../components/form/BaseCheckbox";
import React, { useEffect, useState } from "react";

const AssetsModal = ({
  showModal,
  handleOnHide,
  actionType,
  items,
  handleSubmit,
  handleModifyCriticalitySubmit,
}) => {
  const [selectedValue, setSelectedValue] = useState("Medium");

  const radioData = [{ name: "High" }, { name: "Medium" }, { name: "Low" }];
  useEffect(() => {
    if (actionType === 3 && items.length === 1) {
      const criticality = items[0]?.criticality;
      if (criticality === 3) {
        setSelectedValue("High");
      } else if (criticality === 2) {
        setSelectedValue("Medium");
      } else if (criticality === 1) {
        setSelectedValue("Low");
      } else {
        setSelectedValue("Medium");
      }
    } else {
      setSelectedValue("Medium");
    }
  }, [items, actionType]);

  const handleRadioChange = (event) => {
    setSelectedValue(event.target.value);
  };
  return (
    <Modal show={showModal} centered onHide={handleOnHide} className="w-70">
      <Modal.Body className="securityissueCard_modal">
        <div
          className="securityissueCard_modal_section"
          style={{
            padding: "20px 13px 10px",
          }}
        >
          <div>
            <Modal.Title>
              <h3 className="securityissueCard_modal_header">
                {actionType === 3
                  ? items?.length > 1
                    ? `Modify Asset Criticality for ${items.length} assets`
                    : "Modify Asset Criticality"
                  : `Are you sure you want to reject ${
                      items?.length === 1
                        ? actionType === 1
                          ? "this Hostname"
                          : "this IP"
                        : actionType === 1
                        ? `${items.length} Hostnames`
                        : `${items.length} IPs`
                    }?`}
              </h3>
              <div className="securityissueCard-modal_content">
                {actionType === 3
                  ? "Set the asset's criticality to reflect its importance to your organization"
                  : actionType === 1
                  ? `${
                      items?.length === 1 ? "This Hostname" : "These hostnames"
                    } will no longer be included in the asset inventory.`
                  : "The following hostnames will also be rejected and will no longer be included in the asset inventory."}
              </div>

              {actionType == 2 && (
                <div>
                  {items.map((el) => (
                    <div
                      style={{
                        borderTop: "1px solid #A39C9C",
                        padding: "12px 0px 0px",
                        marginTop: "16px",
                      }}
                    >
                      <div
                        style={{
                          fontSize: "13px",
                          color: "white",
                          opacity: 0.6,
                          fontWeight: 300,
                        }}
                      >
                        Related to IP - {el.ip}
                      </div>
                      {el?.relatedHosts?.map((ol) => (
                        <div
                          style={{
                            fontWeight: 300,
                            paddingLeft: "12px",
                            marginTop: "8px",
                          }}
                        >
                          {ol}
                        </div>
                      ))}
                    </div>
                  ))}
                </div>
              )}
              {actionType === 3 && (
                <div className="securityissueCard-modal_radio_group mt-4 criticality-radio-btn">
                  {radioData.map((item) => (
                    <BaseCheckbox
                      key={item.name}
                      type="radio"
                      name="radioGroup"
                      label={item.name}
                      value={item.name}
                      checked={selectedValue === item.name}
                      onChange={handleRadioChange}
                    />
                  ))}
                </div>
              )}
            </Modal.Title>
          </div>
          <div className="securityissueCard_modal_button">
            <Button
              className="securityissueCard_button fs-12"
              onClick={handleOnHide}
            >
              Cancel
            </Button>

            {actionType !== 3 && (
              <Button
                className="securityissueCard_button fs-12"
                onClick={handleSubmit}
              >
                Yes Reject
              </Button>
            )}
            {actionType === 3 && (
              <Button
                className="securityissueCard_button fs-12"
                onClick={() =>
                  handleModifyCriticalitySubmit(items, selectedValue)
                }
              >
                Apply
              </Button>
            )}
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default AssetsModal;
