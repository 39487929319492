import React from 'react';
import { ReactComponent as EmptybellIcon } from "../../assets/images/empty-bell-icon.svg";

const NotificationEmptyState = () => {
  return (
    <div className="no-notifications">
      <EmptybellIcon />
      <p className="notifications-empty">No Notifications At The Moment</p>
      <p className="notifications-text">Our notifications will appear here once there's something to share</p>
    </div>
  );
};

export default NotificationEmptyState;
