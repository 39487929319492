export const conditionMapping = {
  ip: [
    { label: "Is", value: "is" },
    { label: "Is Not", value: "is_not" },
    { label: "Starts With", value: "starts_with" },
  ],
  port: [
    { label: "Is", value: "is" },
    { label: "Is Not", value: "is_not" },
  ],
  service: [
    { label: "Is", value: "is" },
    { label: "Is Not", value: "is_not" },
  ],
  product: [
    { label: "Is", value: "is" },
    { label: "Is Not", value: "is_not" },
    { label: "Is Empty", value: "is_empty" },
    { label: "Is Not Empty", value: "is_not_empty" },
  ],
  is_encrypted: [{ label: "Is", value: "is" }],
  current_state: [
    { label: "Is", value: "is" },
    { label: "Is Not", value: "is_not" },
  ],
  host: [
    { label: "Is", value: "is" },
    { label: "Is Not", value: "is_not" },
    { label: "Starts With", value: "starts_with" },
    { label: "Ends With", value: "ends_with" },
    { label: "Contains", value: "contains" },
  ],
  domain: [
    { label: "Is", value: "is" },
    { label: "Is Not", value: "is_not" },
    { label: "Starts With", value: "starts_with" },
    { label: "Ends With", value: "ends_with" },
    { label: "Contains", value: "contains" },
  ],
  email: [
    { label: "Is", value: "is" },
    { label: "Is Not", value: "is_not" },
    { label: "Ends With", value: "ends_with" },
    { label: "Contains", value: "contains" },
  ],
  password: [{ label: "Is", value: "is" }],
  hashed_password: [{ label: "Is", value: "is" }],
  database_name: [
    { label: "Is", value: "is" },
    { label: "Is Not", value: "is_not" },
  ],
  pass_auth_method: [{ label: "Is", value: "is" }],
  auth_methods: [
    { label: "Is", value: "is" },
    { label: "Is Not", value: "is_not" },
  ],
  creds: [{ label: "Is", value: "is" }],
  has_hosting_provider: [{ label: "Is", value: "is" }],
  hosting_provider: [
    { label: "Is", value: "is" },
    { label: "Contains", value: "contains" },
    { label: "Doesn’t Contains", value: "does_not_contain" },
  ],
  has_waf: [{ label: "Is", value: "is" }],
  waf_name: [
    { label: "Is", value: "is" },
    { label: "Is Not", value: "is_not" },
    { label: "Contains", value: "contains" },
    { label: "Doesn’t Contains", value: "does_not_contain" },
  ],
  manufacturer: [
    { label: "Is", value: "is" },
    { label: "Contains", value: "contains" },
    { label: "Doesn’t Contains", value: "does_not_contain" },
  ],
  has_cms: [{ label: "Is", value: "is" }],
  cms_name: [
    { label: "Is", value: "is" },
    { label: "Is Not", value: "is_not" },
    { label: "Contains", value: "contains" },
    { label: "Doesn’t Contains", value: "does_not_contain" },
  ],
  has_cdn: [{ label: "Is", value: "is" }],
  cdn_name: [
    { label: "Is", value: "is" },
    { label: "Is Not", value: "is_not" },
    { label: "Contains", value: "contains" },
    { label: "Doesn’t Contains", value: "does_not_contain" },
  ],
  is_spf_secured: [
    { label: "Is", value: "is" },
    { label: "Is Not", value: "is_not" },
  ],
  is_dmarc_secured: [
    { label: "Is", value: "is" },
    { label: "Is Not", value: "is_not" },
  ],
  is_secured: [{ label: "Is", value: "is" }],
  common_name: [
    { label: "Is", value: "is" },
    { label: "Is Not", value: "is_not" },
    { label: "Starts With", value: "starts_with" },
    { label: "Ends With", value: "ends_with" },
    { label: "Contains", value: "contains" },
  ],
  is_expired: [{ label: "Is", value: "is" }],
  issuer: [
    { label: "Is", value: "is" },
    { label: "Is Not", value: "is_not" },
  ],
  days: [
    { label: "Are", value: "are" },
    { label: "Are Not", value: "are_not" },
    { label: "Above", value: "above" },
    { label: "Below", value: "below" },
  ],
  ciphers: [{ label: "Are", value: "are" }],
  protocols: [{ label: "Are", value: "are" }],
  status: [
    { label: "Is", value: "is" },
    { label: "Is Not", value: "is_not" },
  ],
  hosting_provider: [
    { label: "Is", value: "is" },
    { label: "Is Not", value: "is_not" },
  ],
  cdn_name: [
    { label: "Is", value: "is" },
    { label: "Is Not", value: "is_not" },
  ],
  hosting_provider_discovery: [
    { label: "Is", value: "is" },
    { label: "Is Not", value: "is_not" },
  ],
  cdn_name_discovery: [
    { label: "Is", value: "is" },
    { label: "Is Not", value: "is_not" },
  ],
  number_issues: [
    { label: "Is", value: "is" },
    { label: "Is Not", value: "is_not" },
    { label: "Above", value: "above" },
    { label: "Below", value: "below" },
  ],
  registrar: [
    { label: "Is", value: "is" },
    { label: "Is Not", value: "is_not" },
  ],
  dnssec: [{ label: "Is", value: "is" }],
  nameservers: [
    { label: "Is", value: "is" },
    { label: "Is Not", value: "is_not" },
  ],
  exploitability: [
    { label: "Is", value: "is" },
    { label: "Is Not", value: "is_not" },
    { label: "Above", value: "above" },
    { label: "Below", value: "below" },
  ],
  impact: [
    { label: "Is", value: "is" },
    { label: "Is Not", value: "is_not" },
    { label: "Above", value: "above" },
    { label: "Below", value: "below" },
  ],
  risk_score: [
    { label: "Is", value: "is" },
    { label: "Is Not", value: "is_not" },
    { label: "Above", value: "above" },
    { label: "Below", value: "below" },
  ],
  issue_name: [
    { label: "Is", value: "is" },
    { label: "Starts With", value: "starts_with" },
    { label: "Contains", value: "contains" },
  ],
  asset_criticality: [
    { label: "Is", value: "is" },
    { label: "Is Not", value: "is_not" },
  ],
  days_open: [
    { label: "Is", value: "is" },
    { label: "Is Not", value: "is_not" },
    { label: "Above", value: "above" },
    { label: "Below", value: "below" },
  ],
  cvss: [
    { label: "Is", value: "is" },
    { label: "Is Not", value: "is_not" },
    { label: "Above", value: "above" },
    { label: "Below", value: "below" },
  ],
  epss: [
    { label: "Is", value: "is" },
    { label: "Is Not", value: "is_not" },
    { label: "Above", value: "above" },
    { label: "Below", value: "below" },
  ],
  cisa_kev: [{ label: "Is", value: "is" }],
  armory_tested: [{ label: "Is", value: "is" }],
  issue_status: [
    { label: "Is", value: "is" },
    { label: "Is Not", value: "is_not" },
  ],
  discovered_by: [{ label: "Is", value: "is" }],
  network_zone: [{ label: "Is", value: "is" }],
  criticality: [{ label: "Is", value: "is" }],
  service_category: [{ label: "Is", value: "is" }],
  first_detected: [
    { label: "Last 24 Hours", value: "last_24_hours" },
    { label: "Last 7 Days", value: "last_7_days" },
    { label: "Last 30 Days", value: "last_30_days" },
    { label: "From", value: "from" },
    { label: "Until", value: "until" },
    { label: "Between", value: "between" },
  ],
  host_status_code: [
    { label: "Is", value: "is" },
    { label: "Is Not", value: "is_not" },
  ],
  first_seen_at: [
    { label: "Last 24 Hours", value: "last_24_hours" },
    { label: "Last 7 Days", value: "last_7_days" },
    { label: "Last 30 Days", value: "last_30_days" },
    { label: "From", value: "from" },
    { label: "Until", value: "until" },
    { label: "Between", value: "between" },
  ],
  registration_date: [
    { label: "Last 24 Hours", value: "last_24_hours" },
    { label: "Last 7 Days", value: "last_7_days" },
    { label: "Last 30 Days", value: "last_30_days" },
    { label: "From", value: "from" },
    { label: "Until", value: "until" },
    { label: "Between", value: "between" },
  ],
  validity: [
    { label: "Last 24 Hours", value: "last_24_hours" },
    { label: "Last 7 Days", value: "last_7_days" },
    { label: "Last 30 Days", value: "last_30_days" },
    { label: "From", value: "from" },
    { label: "Until", value: "until" },
    { label: "Between", value: "between" },
  ],
  https: [{ label: "Is", value: "is" }],
  http: [{ label: "Is", value: "is" }],
  login_page: [
    { label: "Is", value: "is" },
    { label: "Contains", value: "contains" },
    { label: "Ends With", value: "ends_with" },
  ],
  name: [
    { label: "Is", value: "is" },
    { label: "Is Not", value: "is_not" },
  ],
  url: [
    { label: "Is", value: "is" },
    { label: "Contains", value: "contains" },
  ],
  title: [
    { label: "Contains", value: "contains" },
    { label: "Doesn’t Contains", value: "does_not_contain" },
  ],
  description: [
    { label: "Contains", value: "contains" },
    { label: "Doesn’t Contains", value: "does_not_contain" },
  ],
  root_domain: [
    { label: "Is", value: "is" },
    { label: "Is Not", value: "is_not" },
    { label: "Starts With", value: "starts_with" },
    { label: "Ends With", value: "ends_with" },
    { label: "Contains", value: "contains" },
  ],
  subdomain: [
    { label: "Is", value: "is" },
    { label: "Is Not", value: "is_not" },
    { label: "Starts With", value: "starts_with" },
    { label: "Ends With", value: "ends_with" },
    { label: "Contains", value: "contains" },
  ],
  hosting: [
    { label: "Is", value: "is" },
    { label: "Is Not", value: "is_not" },
  ],
  cdn: [
    { label: "Is", value: "is" },
    { label: "Is Not", value: "is_not" },
  ],
};

export const getConditionsForColumn = (column) => {
  return conditionMapping[column];
};

export const parseFilterString = (filterString) => {
  const rules = filterString.split("|");
  return rules.map((rule) => {
    const [column, condition, value] = rule.split(",");
    return { column, condition, value };
  });
};

export const applyCondition = (fieldValue, condition, value) => {
  try {
    const currentDate = new Date();
    const fieldDate = new Date(fieldValue);

    const calculatePastDate = (days) => {
      const pastDate = new Date();
      pastDate.setDate(currentDate.getDate() - days);
      return pastDate;
    };

    switch (condition) {
      case "is":
        return fieldValue === value;
      case "is_not":
        return fieldValue !== value;
      case "starts_with":
        return fieldValue?.startsWith(value);
      case "ends_with":
        return fieldValue?.endsWith(value);
      case "contains":
        return fieldValue?.includes(value);
      case "does_not_contain":
        return !fieldValue?.includes(value);
      case "is_empty":
        return !fieldValue || fieldValue?.trim() === "";
      case "is_not_empty":
        return fieldValue && fieldValue?.trim() !== "";
      case "are":
        return fieldValue == value;
      case "are_not":
        return fieldValue != value;
      case "above":
        return parseFloat(fieldValue) > parseFloat(value);
      case "below":
        return parseFloat(fieldValue) < parseFloat(value);
      case "last_7_days":
        const last7Days = calculatePastDate(7);
        return fieldDate >= last7Days && fieldDate <= currentDate;
      case "last_30_days":
        const last30Days = calculatePastDate(30);
        return fieldDate >= last30Days && fieldDate <= currentDate;
      case "from":
        const fromDate = new Date(value);
        return fieldDate >= fromDate && fieldDate <= currentDate;
      case "until":
        const untilDate = new Date(value);
        return fieldDate >= untilDate && fieldDate <= currentDate;
      case "between":
        const [startDate, endDate] = value
          .split(" - ")
          .map((date) => new Date(date));
        return fieldDate >= startDate && fieldDate <= endDate;
      default:
        return false;
    }
  } catch (e) {
    console.error("Error: ", e);
  } finally {
    return false;
  }
};
