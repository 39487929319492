import React, { useState } from "react";
import Offcanvas from "react-bootstrap/Offcanvas";
import { ReactComponent as Close } from "../../assets/images/xmark.svg";
import { ReactComponent as ArrowDown } from "../../assets/images/chevron-green-down.svg";
import { HostnameLink } from "../textLink/TextLink";

const SensitiveDataPopup = ({ show, handleClose, data, ...props }) => {
  const [explanationOpen, setExplanationOpen] = useState(true);
  const toggleExplanation = () => {
    setExplanationOpen(!explanationOpen);
  };
  return (
    <>
      <Offcanvas
        className="scanner-canvas custom-offcanvas"
        style={{ width: "40%" }}
        show={show}
        onHide={handleClose}
        placement="end"
        {...props}
      >
        <Offcanvas.Header>
          <div className="sidebar-header ">
            <div className="header-upper-block">
              <div className="header-title">{data?.category}</div>
              <Close
                onClick={handleClose}
                className="close-icon cursor-pointer"
              />
            </div>
            <div className="header-lower-block">
              <span>
                Hostname{" "}
                <span style={{ marginLeft: "10px" }}>
                  {" "}
                  <HostnameLink hostname={data?.domain} isNotDomain />
                </span>
              </span>
            </div>
            <div className="header-lower-block">
              <span
                style={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                URL{" "}
                <span style={{ marginLeft: "10px" }}>
                  {" "}
                  <HostnameLink hostname={data?.url} />
                </span>
              </span>
            </div>
          </div>
        </Offcanvas.Header>
        <hr className="popup-hr-main" />
        <Offcanvas.Body>
          <div className="sidebar-body">
            <div style={{ marginBottom: "28px" }}>
              <h3 className="text-white m-0">Title</h3>
            </div>
            <div className="title">
              <p>{data?.title}</p>
            </div>
            <div className="popup-dropdown" onClick={toggleExplanation}>
              <ArrowDown
                height={16}
                width={16}
                className={`base-drop-down-arrow ${
                  explanationOpen ? "open" : ""
                }`}
              />
              <h3 className="text-white">Description</h3>
            </div>
            {explanationOpen && (
              <div className="title text-white">
                <p className="text-white">{data?.description}</p>
              </div>
            )}
          </div>
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
};

export default SensitiveDataPopup;
