export const certificateExpierdFilterOptions = [
  {
    id: 0,
    name: "Expired",
    type: "Certificate Expiration: Expired",
    key: "8",
    active: false,
  },
  {
    id: 1,
    name: "<30 days",
    type: "Certificate Expiration: < 30 days",
    key: "8",
    active: false,
  },
  {
    id: 2,
    name: "<60 days",
    type: "Certificate Expiration: <60 days",
    key: "8",
    active: false,
  },
  {
    id: 4,
    name: "<90 days",
    type: "Certificate Expiration: <90 days",
    key: "8",
    active: false,
  },
  {
    id: 1,
    name: ">90 days",
    type: "Certificate Expiration: >90 days",
    key: "8",
    active: false,
  },
];

export const supportedCipherFilterOptions = [
  {
    id: 0,
    name: "Secured",
    type: "Supported Ciphers: Secured",
    key: "11",
    active: false,
  },
  {
    id: 1,
    name: "Unsecured",
    type: "Supported Ciphers: Unsecured",
    key: "11",
    active: false,
  },
];

export const supportedProtocolFilterOptions = [
  {
    id: 0,
    name: "Secured",
    type: "Supported Protocols: Secured",
    key: "12",
    active: false,
  },
  {
    id: 1,
    name: "Unsecured",
    type: "Supported Protocols: Unsecured",
    key: "12",
    active: false,
  },
];

export const certificateSecurityFilterOptions = [
  {
    id: 0,
    name: "Secured Certificate",
    type: "Certificate Security: Secured Certificate",
    key: "9",
    active: false,
  },
  {
    id: 1,
    name: "Weak Cipher",
    type: "Certificate Security: Weak Cipher",
    key: "9",
    active: false,
  },
  {
    id: 2,
    name: "Unsecured Protocol",
    type: "Certificate Security: Unsecured Protocol",
    key: "9",
    active: false,
  },
];

export const filterMap = {
  "<30 days": (days) => days >= 0 && days <= 30,
  "<60 days": (days) => days >= 30 && days <= 60,
  "<90 days": (days) => days >= 60 && days <= 90,
  ">90 days": (days) => days >= 90,
  Expired: (days) => days < 0,
};
export const SecurityfilterMap = {
  "Secured Certificate": (cert) =>
    !cert.ciphers?.insecure?.length && !cert.protocols?.insecure?.length,
  "Weak Cipher": (cert) => cert.ciphers?.insecure?.length > 0,
  "Unsecured Protocol": (cert) => cert.protocols?.insecure?.length > 0,
};
