import React from "react";
import { OverlayTrigger, Popover } from "react-bootstrap";
import { ThreeCircles } from "react-loader-spinner";
import Moment from "react-moment";
import ClickableBarChart from "../../portal/assets-inventory/CriticalityBarChart.js";
import { ReactComponent as LinkIcon } from "../../assets/images/external-link-icon.svg";
import CurrentState from "../status/CurrentState.js";
import moment from "moment";

const getFilteredColumns = (
  allColumnsObj,
  servicesStatus,
  navigate,
  SecurityIssueCard,
  routeParams
) => {
  return [
    { Header: "Domain", accessor: "domain", key: "domain", isSortable: true },
    {
      Header: "Hostname",
      key: "host",
      accessor: (row) =>
        row?.host.length > 26 ? (
          <OverlayTrigger
            placement="top"
            overlay={
              <Popover id={`tooltip-${row?.host}`} className="custom-popover-arrow">
                <Popover.Body className="comming-tool-body">
                  <label className="comming-text-tool">{row?.host}</label>
                </Popover.Body>
              </Popover>
            }
          >
            <div
              style={{
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
                width: "190px",
              }}
            >
              {row?.host.slice(0, 26) + "..."}
            </div>
          </OverlayTrigger>
        ) : (
          row?.host
        ),
      isSortable: true,
    },
    { Header: "IP Address", accessor: "ip", key: "ip", isSortable: true },
    {
      Header: "Hosting Provider",
      key: "hosting_provider",
      isSortable: true,
      width: 20,
      accessor: (row) => {
        let statusLabel = "Scanning";
        let statusIcon = <ThreeCircles height={18} width={18} color="white" />;
        let statusColor = "#fff";

        return !row.hosting_provider &&
          servicesStatus?.find((s) => s.id === 7)?.status < 3 ? (
          <div style={{ color: statusColor, display: "flex", alignItems: "center" }}>
            {statusIcon} <span style={{ marginLeft: "5px" }}>{statusLabel}</span>
          </div>
        ) : !row.hosting_provider ? (
          "-"
        ) : (
          row.hosting_provider
        );
      },
    },
    {
      Header: "CDN",
      key: "cdn_name",
      width: 25,
      isSortable: true,
      accessor: (row) => {
        let statusLabel = "Scanning";
        let statusIcon = <ThreeCircles height={18} width={18} color="white" />;
        let statusColor = "#fff";
        let hostsCdn = "-";
        return !row.cdn_name &&
          servicesStatus?.find((s) => s.id === 9)?.status < 3 ? (
          <div style={{ color: statusColor, display: "flex", alignItems: "center" }}>
            {statusIcon} <span style={{ marginLeft: "5px" }}>{statusLabel}</span>
          </div>
        ) : !row.cdn_name ? (
          hostsCdn
        ) : (
          row.cdn_name
        );
      },
    },
    {
      Header: "Archive Date",
      key: "archive_date",
      width: 25,
      isSortable: true,
      accessor: (row) => {
        return (
          <div>
            {row.archive_date ? (
              <Moment format="DD/MM/YYYY">{row.archive_date}</Moment>
            ) : (
              "-"
            )}
          </div>
        );
      },
    },
    {
      Header: "Current State",
      key: "current_state",
      isSortable: true,
      accessor: (row) => {
        return (
          <div>
            <CurrentState
              rejected={row?.is_rejected}
              removed={row?.is_removed}
            />
          </div>
        );
      },
    },
    {
      Header: "Network Zone",
      key: "network_zone",
      width: 25,
      isSortable: true,
      accessor: (row) => {
        if (row.network_zone === null || row.network_zone === undefined) {
          return "-";
        }
        const text =
          row.network_zone === 1
            ? "On-Premise"
            : row.network_zone === 2
            ? "Cloud-Hosted"
            : "Unknown";
        return (
          <div className="cursor-pointer web-interface-container">{text}</div>
        );
      },
    },
    {
      Header: "Service Category",
      key: "service_category",
      width: 50,
      isSortable: false,
      accessor: (row) => {
        if (!row.scan_ip_categories || row.scan_ip_categories.length === 0) {
          return "-";
        }
        const serviceCategories = row.scan_ip_categories;
        const categoryArray = serviceCategories.map(
          (service) => service.service_category.title
        );
        const displayCategories = categoryArray.slice(0, 2);
        const remainingCategories = categoryArray.slice(2);
        const popover = (
          <Popover
            id={`popover-categories-${row.id}`}
            className="custom-popover-arrow"
          >
            <Popover.Body className="comming-tool-body d-grid scrollable-checkbox-list">
              {remainingCategories.map((category, i) => (
                <span key={i} className="text-black port-box">
                  <span>·</span> {category}
                </span>
              ))}
            </Popover.Body>
          </Popover>
        );

        return (
          <div className="">
            <div className="flex gap-2">
              {displayCategories.map((category, i) => (
                <div
                  className="cursor-pointer service-category-chip w-mx-content"
                  key={i}
                >
                  {category}
                </div>
              ))}

              {remainingCategories.length > 0 && (
                <OverlayTrigger
                  trigger={["hover", "focus"]}
                  placement="top"
                  overlay={popover}
                >
                  <div className="cursor-pointer service-category-chip">
                    +{remainingCategories.length}
                  </div>
                </OverlayTrigger>
              )}
            </div>
          </div>
        );
      },
    },
    {
      Header: "Web Interface",
      key: "web_interface",
      width: 25,
      isSortable: true,
      accessor: (row) => {
        const protocol = row.host_protocol || "https";
        const host = row.host;
        if (
          row.host_status_code === null ||
          row.host_status_code === "" ||
          row.host_status_code === undefined
        ) {
          return (
            <div className="cursor-pointer web-interface-container">No</div>
          );
        }
        return (
          <a
            href={`${protocol}://${host}`}
            target="_blank"
            rel="noopener noreferrer"
            className="web-interface-link"
          >
            <div className="cursor-pointer web-interface-container">
              <p className="underline m-0">
                Yes (Status Code {row.host_status_code})
              </p>
              <LinkIcon />
            </div>
          </a>
        );
      },
    },
    {
      Header: "Asset Criticality",
      key: "asset_criticality",
      isSortable: true,
      accessor: (row) => {
        return (
          <div className="d-flex align-items-center">
            <div className="cursor-pointer">
              <ClickableBarChart
                criticality={row?.criticality}
                barClickable={false}
              />
            </div>
          </div>
        );
      },
    },
    {
      Header: "Security Issues",
      key: "issues",
      isSortable: true,
      accessor: (row) => (
        <div
          onClick={() => SecurityIssueCard(row)}
          className="cursor-pointer"
          style={{
            textDecoration: row?.number_issues > 0 ? "underline" : "initial",
          }}
        >
          {row?.number_issues || "-"}
        </div>
      ),
    },
    {
      Header: "Discovered By",
      key: "discovered_by",
      isSortable: true,
      accessor: (row) => (
        <div>
          {row?.discovered_by === 2
            ? "User’s input"
            : row?.discovered_by === 1
            ? "Armory"
            : "-"}
        </div>
      ),
    },
    {
      Header: "First Detected",
      key: "first_detected",
      isSortable: true,
      accessor: (row) => (
        <div>
          {row?.first_detected
            ? moment(row?.first_detected).format("DD/MM/YY")
            : "-"}
        </div>
      ),
    },
  ].filter((column) => {
    const found = allColumnsObj.find((sc) => sc.key === column.key);
    return found ? found.visibility : false;
  });
};

export default getFilteredColumns;
