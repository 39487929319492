import { ReactComponent as ExternalLinkIcon } from "../../assets/images/external-link-icon.svg";

export const TextLink = ({ hostname }) => {
  return (
    <a
      href={`http://${hostname}`}
      target="_blank"
      rel="noopener noreferrer"
      className="hostname-link "
      style={{
        textDecoration: "none",
        maxWidth: "300px",
        textWrap: "nowrap",
        textOverflow: "ellipsis",
        overflow: "hidden",
      }}
    >
      {hostname} <ExternalLinkIcon className="ms-1" />
    </a>
  );
};

export const HostnameLink = ({ hostname, isNotDomain }) => (
  <a
    href={hostname}
    target="_blank"
    rel="noopener noreferrer"
    className="hostname-link"
    style={{
      textDecoration: isNotDomain ? "none" : "underline",
    }}
  >
    {hostname} {!isNotDomain && <ExternalLinkIcon className="ms-1" />}
  </a>
);
