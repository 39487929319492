import React, { useEffect, useState } from "react";
import {
  _fetch,
  registerCredential,
  unregisterCredential,
} from "../../helpers/client";
import {
  Badge,
  Button,
  Card,
  Col,
  Container,
  Image,
  OverlayTrigger,
  Row,
  Table,
  Tooltip,
} from "react-bootstrap";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "../../util/axios";
import { Triangle } from "react-loader-spinner";
import { Link } from "react-router-dom";
import { showToast } from "../../util/toasts";
const AHref = ({ id, children, title, ...props }) => (
  <OverlayTrigger overlay={<Tooltip id={id}>{title}</Tooltip>}>
    <Link {...props}>{children}</Link>
  </OverlayTrigger>
);
function CredentialMaker({ icon, title }) {
  const [displayBtn, setDisplayBtn] = useState(false);
  const [credentialBtn, setCredentialBtn] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [credentials, setCredentials] = useState([]);

  async function fetchCredentials(page, pageSize) {
    try {
      let res = await axios.get("credentials", {
        params: { page: page, pageSize: pageSize },
      });
      console.log("data", res.data);
      setCredentials(res.data);
      return res.data;
    } catch (error) {
      console.log(error);
      throw error;
    }
  }
  const init = async () => {
    await fetchCredentials();
  };
  useEffect(() => {
    if (isLoading) {
      setCredentialBtn({ disabled: true, label: "Adding Credential ..." });
    } else {
      setCredentialBtn({
        disabled: false,
        label: "Add a Credential",
      });
    }
  }, [isLoading]);
  useEffect(() => {
    init();
    if (window.PublicKeyCredential) {
      window.PublicKeyCredential.isUserVerifyingPlatformAuthenticatorAvailable().then(
        (uvpaa) => {
          console.log("creds", uvpaa);
          if (uvpaa) {
            setDisplayBtn(true);
          } else {
            setDisplayBtn(false);
          }
        }
      );
    } else {
      setDisplayBtn(false);
    }
  }, []);
  const handleClick = () => {
    setIsLoading(true);
    registerCredential()
      .then(async (user) => {
        console.log("yes", user);
        await fetchCredentials();
        setIsLoading(false);

        showToast(
          "Authentication Method has been added.",
          "success",
          "top-center",
          "light"
        );
      })
      .catch((e) => {
        showToast("Something went wrong!", "warn", "top-center", "light");
        setIsLoading(false);
      });
  };

  const handleDeleteClick = (e, item) => {
    e.preventDefault();

    let elem = e.currentTarget;

    let release = (action) => {
      if (action) {
        elem.removeAttribute("is-loading");
        elem.innerHTML = elem.getAttribute("label");
      } else {
        let elemLabel = elem.getAttribute("label");
        if (!elemLabel) {
          let lastLabel = elem.innerHTML;
          elem.setAttribute("label", lastLabel);
        }
        elem.setAttribute("is-loading", 1);
        elem.innerHTML += " Deleting...";
      }
    };
    if (elem.getAttribute("is-loading") !== "1" && item) {
      let parentElem = elem.parentElement.nodeName;
      e.currentTarget.disabled = true;

      release(false);

      unregisterCredential(item.id)
        .then(async (user) => {
          console.log("yes", user);
          await fetchCredentials();
          release(true);

          showToast(
            "Authentication Method has been removed.",
            "success",
            "top-center",
            "light"
          );
        })
        .catch((e) => {
          release(true);

          showToast("Something went wrong!", "warn", "top-center", "light");

          // elem.removeAttribute("is-loading");
        });
    } else {
      console.log("locked for now....");
      // release(true);
    }
  };
  const renderCredential = (item) => {
    // let userHandle = JSON.parse(JSON.parse(JSON.stringify(item.userHandle)));
    let temp = item.userHandle;
    temp = temp.replace(/'/g, '"');
    temp = temp.replace(/~!~/g, "'");
    temp = JSON.parse(temp);
    console.log("data", temp);
    // let userHandle = JSON.parse(temp);
    return (
      <Card bg="secondary">
        <Card.Header className="d-flex justify-content-between">
          Browser: {temp.client.name}
          <Badge className="text-capitalize" bg="dark">
            {temp.device.type}
          </Badge>
        </Card.Header>
        <Card.Body className="d-flex justify-content-between">
          {temp.os.name} {temp.os.version}
          <p>Used #: {item.signatureCount}</p>
        </Card.Body>
        <Card.Footer>
          <AHref
            title="Remove Credential"
            className="text-danger "
            onClick={(e) => handleDeleteClick(e, item)}
          >
            <i className="bi bi-trash"></i>{" "}
          </AHref>
        </Card.Footer>
      </Card>
    );
  };
  return (
    <React.Fragment>
      <h1 className="fw-normal mb-5">
        <Image src="/images/icon-biometric.png" width={50} rounded /> Credential
        Manager
      </h1>
      <Row className="news-items rounded my-3 mb-4  rounded shadow-sm">
        {credentials.length > 0 ? (
          credentials.map((item, index) => (
            <Col
              key={index}
              className="p-3 d-flex flex-column justify-content-between p-3"
              md="6"
              lg="3"
            >
              {renderCredential(item)}
            </Col>
          ))
        ) : (
          <Card bg="primary">
            <Card.Body>No Credentials Found!</Card.Body>
          </Card>
        )}
      </Row>
      <Triangle
        height="80"
        width="80"
        color="#145f95"
        ariaLabel="triangle-loading"
        wrapperStyle={{ justifyContent: "center" }}
        wrapperClassName=""
        visible={isLoading}
      />
      {displayBtn ? (
        <Button
          type="submit"
          className="btn my-3"
          variant="danger"
          id="singup-btn"
          onClick={handleClick}
          disabled={credentialBtn.disabled}
        >
          <i className="bi bi-fingerprint"></i> {credentialBtn.label}
        </Button>
      ) : (
        ""
      )}
    </React.Fragment>
  );
}

export default CredentialMaker;
