import React, { useEffect, useState } from "react";
import {
  Button,
  Col,
  Container,
  FloatingLabel,
  FormControl,
  FormGroup,
  FormCheck,
  Row,
  Card,
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import { Formik, Form, Field } from "formik";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import PhoneField from "../../components/PhoneField";
import axios from "../../util/axios";
import * as Yup from "yup";
import { detectCountry } from "../../util/country";
import { Triangle } from "react-loader-spinner";
import SocialShare from "../../components/SocialShare";

import { showToast } from "../../util/toasts";

const schema = Yup.object().shape({
  phone: Yup.string().required("Please provide phone."),
});

const ShareForm = (props) => {
  const navigate = useNavigate();
  const [country, setCountry] = useState("");
  const [isCopied, setIsCopied] = useState(false);

  const [isLoading, setIsLoading] = useState(false);
  const { item } = props;
  let shareUrl = item.shorten;
  const API_ENDPOINT_NODE = "urls/share";

  let initialValues = {
    phone: "",
    id: item.id,
  };
  const init = async () => {
    setCountry(await detectCountry());
  };
  useEffect(() => {
    //passing getData method to the lifecycle method
    init();
  }, []);
  const copyCode = (txt) => {
    try {
      navigator.clipboard
        // again doesn't make sense to use here
        // couponRef.current.innerHTML
        // since you got access to post.coupon
        .writeText(txt)
        .then(() => {
          setIsCopied(true);
          // console.log('my derr')
        })
        .catch((err) => {
          console.log("error", err.message);
        });
    } catch (e) {
      console.log("error", e);
    }
  };
  const buildShareTitle = (item) => {
    return `Hi ${item.recipient_name}, I think you will like to see this \n `;
  };
  const handleSubmit = (values, helpers) => {
    setIsLoading(true);

    axios
      .post(API_ENDPOINT_NODE, values)
      .then((response) => {
        setIsLoading(false);
        const data = response.data;

        showToast(data.message, "success", "top-center", "light");
        helpers.resetForm();
        setTimeout(() => {
          navigate("/urls");
        }, 500);
      })
      .catch((e) => {
        setIsLoading(false);
        if (e.response.status === 422) {
          for (const fieldKey in e.response.data) {
            const field = e.response.data[fieldKey];
            helpers.setFieldError(field.path, field.msg);
          }
        }
      });
  };

  return (
    <>
      <Card className="url-form">
        <Card.Body>
          <Card.Title className="text-uppercase text-white p-1 pb-3" as="h3">
            <i className="bi bi-check-lg pe-2" title="Share via SMS"></i>
            Share This URL
          </Card.Title>
          <div>
            <div className="text-black p-2 rounded">
              <div className="h3">
                <i className="bi bi-link-45deg pe-2"></i> {item.shorten}
              </div>
            </div>
          </div>
          <div>
            <Formik
              validationSchema={schema}
              initialValues={initialValues}
              onSubmit={handleSubmit}
              render={({
                handleChange,
                handleSubmit,
                handleBlur,
                values,
                errors,
                validateForm,
              }) => (
                <Form>
                  {/* Phone Field */}
                  <Row>
                    <Col className="my-2">
                      <PhoneField
                        name="phone"
                        type="text"
                        label="Enter your phone"
                        country={country}
                      />
                      <Field name="id" type="hidden" />
                    </Col>
                  </Row>
                  <Row className="my-4">
                    <Col className="my-2" md="6">
                      <Button
                        type="submit"
                        className="btn  btn-full"
                        id="singup-btn"
                        disabled={isLoading}
                      >
                        <i
                          className="bi bi-chat-square-dots pe-2"
                          title="Share via SMS"
                        ></i>
                        {isLoading ? "Sending SMS" : "Share via SMS"}
                      </Button>
                    </Col>
                    <Col
                      className="my-2 d-flex flex-wrap justify-content-center mt-sm-0 "
                      md="6"
                    >
                      <OverlayTrigger
                        overlay={
                          <Tooltip id="tooltip-disabled">
                            {isCopied ? "Copied" : "Copy to clipboard"}
                          </Tooltip>
                        }
                      >
                        <Link
                          onClick={() => {
                            copyCode(item.shorten);
                          }}
                          className="fs-1 me-3"
                        >
                          <i
                            className={
                              "bi bi-clipboard" + (isCopied ? "-check" : "")
                            }
                          ></i>
                        </Link>
                      </OverlayTrigger>
                      <OverlayTrigger
                        overlay={
                          <Tooltip id="tooltip-disabled">
                            Generate another locator
                          </Tooltip>
                        }
                      >
                        <Link to="/urls" className="fs-1 me-3 ">
                          <i className="bi bi-cloud-plus"></i>
                        </Link>
                      </OverlayTrigger>
                      <SocialShare
                        url={shareUrl}
                        title={buildShareTitle(item)}
                      />
                    </Col>
                  </Row>
                </Form>
              )}
            />
            <div className="d-flex flex-wrap justify-content-center mt-sm-0 mt-4 pt-3"></div>
          </div>
        </Card.Body>
      </Card>
    </>
  );
};

export default ShareForm;
