import React, { useEffect, useState } from "react";
import {
  Button,
  Col,
  Container,
  FloatingLabel,
  FormControl,
  FormGroup,
  Row,
} from "react-bootstrap";
import { Formik, Form, Field } from "formik";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "../../../util/axios";
import { showToast } from "../../../util/toasts";

const validate = (values) => {
  const errors = {};
  if (!values.org_name) {
    errors.org_name = "Please enter organization name.";
  }
  return errors;
};

const InvitationForm = ({ onOrganizationAdded }) => {
  const [submitBtn, setSubmitBtn] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  let API_ENDPOINT_NODE = "/organizations";
  const initialValues = {
    org_name: "",
    ip_limit: 0,
    alert_limit:0
  };

  const handleSubmit = (values, helpers) => {
    setIsLoading(true);
    console.log(values);

    axios
      .post(API_ENDPOINT_NODE, values)
      .then((response) => {
        setIsLoading(false);
        const data = response.data;
        helpers.resetForm();
        onOrganizationAdded();
        // getAllOrganizations();

        showToast(data.message, "success", "top-center", "light");
      })
      .catch((e) => {
        setIsLoading(false);
        if (e.response.status === 422) {
          for (const fieldKey in e.response.data) {
            const field = e.response.data[fieldKey];
            helpers.setFieldError(field.path, field.msg);
          }
        }
      });
  };
  useEffect(() => {
    if (isLoading) {
      setSubmitBtn({ disabled: true, label: "Adding organization.." });
    } else {
      setSubmitBtn({ disabled: false, label: "Add organization" });
    }
  }, [isLoading]);

  return (
    <React.Fragment>
      <Container className="max-80 my-5 invitation-form-set">
        <Row>
          <Col>
            <h2 className="my-5 text-center">Add Organization</h2>
            <Formik
              validate={validate}
              initialValues={initialValues}
              onSubmit={handleSubmit}
              render={() => (
                <Form>
                  {/* Organization name Field */}
                  <Field
                    name="org_name"
                    render={({ field, form: { touched, errors }, meta }) => (
                      <FormGroup controlId="org_name" className="my-3">
                        <FloatingLabel
                          controlId="floatingPassword"
                          label="Enter organization name"
                        >
                          <FormControl
                            type={"text"}
                            size="lg"
                            value={field.value}
                            onChange={field.onChange}
                            placeholder=""
                            className={
                              meta.touched && meta.error ? "is-invalid" : ""
                            }
                            {...field}
                          />
                          {meta.touched && meta.error ? (
                            <div className="invalid-feedback">{meta.error}</div>
                          ) : null}
                        </FloatingLabel>
                      </FormGroup>
                    )}
                  />

                  <Field
                    name="ip_limit"
                    render={({ field, form: { touched, errors }, meta }) => (
                      <FormGroup controlId="ip_limit" className="my-3">
                        <FloatingLabel
                          controlId="floatingPassword"
                          label="Enter ip limit"
                        >
                          <FormControl
                            type={"number"}
                            size="lg"
                            value={field.value}
                            onChange={field.onChange}
                            placeholder=""
                            className={
                              meta.touched && meta.error ? "is-invalid" : ""
                            }
                            {...field}
                          />
                          {meta.touched && meta.error ? (
                            <div className="invalid-feedback">{meta.error}</div>
                          ) : null}
                        </FloatingLabel>
                      </FormGroup>
                    )}
                  />
                  <Field
                    name="alert_limit"
                    render={({ field, form: { touched, errors }, meta }) => (
                      <FormGroup controlId="alert_limit" className="my-3">
                        <FloatingLabel
                          controlId="floatingPassword"
                          label="Enter ip alert limit"
                        >
                          <FormControl
                            type={"number"}
                            size="lg"
                            value={field.value}
                            onChange={field.onChange}
                            placeholder=""
                            className={
                              meta.touched && meta.error ? "is-invalid" : ""
                            }
                            {...field}
                          />
                          {meta.touched && meta.error ? (
                            <div className="invalid-feedback">{meta.error}</div>
                          ) : null}
                        </FloatingLabel>
                      </FormGroup>
                    )}
                  />

                  <Button
                    type="submit"
                    className="btn bg-primary btn-full my-3"
                    id="singup-btn"
                    disabled={submitBtn.disabled}
                  >
                    {submitBtn.label}
                  </Button>
                </Form>
              )}
            />
          </Col>
        </Row>
      </Container>
    </React.Fragment>
  );
};

export default InvitationForm;
