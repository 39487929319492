import React from "react";
import { FormCheck } from "react-bootstrap";
import { isAdmin, isNormalUser } from "../../util/constants";

const ScanTypeSelector = ({
  selectedService,
  setSelectedService,
  setFieldValue,
}) => {
  return (
    <div className="row">
      <div className="col-12 checkbox-scan">
        {!isNormalUser() && (
          <FormCheck
            inline
            label="Full Scan"
            className="me-3"
            type="checkbox"
            name="scan_type"
            id="fullScan"
            checked={selectedService === 0}
            onChange={() => {
              setSelectedService(0);
              setFieldValue("scan_type", 0);
            }}
          />
        )}

        {!isNormalUser() && (
          <FormCheck
            inline
            label="Demo Scan"
            type="checkbox"
            name="scan_type"
            id="demoScan"
            checked={selectedService === 1}
            onChange={() => {
              setSelectedService(1);
              setFieldValue("scan_type", 1);
            }}
          />
        )}

        {!isNormalUser() && (
          <FormCheck
            inline
            label="Select Scan"
            type="checkbox"
            name="service"
            id="selectedScan"
            checked={selectedService === 4}
            onChange={() => {
              setSelectedService(4);
              setFieldValue("scan_type", 4);
            }}
          />
        )}
      </div>
    </div>
  );
};

export default ScanTypeSelector;
