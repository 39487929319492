import React, { createContext, useState, useCallback } from "react";

export const ServiceCallContext = createContext();

export const ServiceCallProvider = ({ children }) => {
  const [callService, setCallService] = useState(true);

  const triggerServiceCall = useCallback(() => {
    setCallService(true);
  }, []);

  const resetServiceCall = useCallback(() => {
    setCallService(false);
  }, []);

  return (
    <ServiceCallContext.Provider
      value={{ callService, triggerServiceCall, resetServiceCall }}
    >
      {children}
    </ServiceCallContext.Provider>
  );
};
