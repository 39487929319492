import { Popover } from "react-bootstrap";

export const sensitivePopoverContent = (
  id,
  info,
  onMousePopoverEnter,
  onMousePopoverLeave,
  onClickPopover
) => (
  <Popover id={`popover-${id}`} className="custom-popover-arrow">
    <Popover.Body
      className="comming-tool-body"
      onMouseEnter={() => onMousePopoverEnter(id, true)}
      onMouseLeave={() => onMousePopoverLeave(id, false)}
    >
      <span className="text-black mt-2">To review full information </span>
      <div
        className="text-start cursor-pointer mb-1"
        onClick={(e) => {
          e.stopPropagation();
          onClickPopover(id, info);
        }}
      >
        <span className="text-link text-start">View Details</span>
      </div>
    </Popover.Body>
  </Popover>
);
