// LeftNavbar.js
import React, { useState, useEffect, useContext } from "react";
import { Col, Collapse, ListGroup, Button } from "react-bootstrap";
import Accordion from 'react-bootstrap/Accordion';
import { Link, useLocation, } from "react-router-dom";
import { ReactComponent as Close } from "../../assets/images/xmark.svg";
import { ReactComponent as ArrowDown } from "../../assets/images/chevron-green-down.svg";
import DashedImage from "../../assets/images/Frame.png";
import LeftNavbar from "./LeftNavbar";
import BaseDropdown from '../../components/form/BaseDropdown'
import axios from "../../util/axios";
import { useParams } from "react-router-dom";
import { ScannerContext } from '../../components/ScannerContext';

const SideNavbar = ({ open, onToggle, targetId }) => {
  const { fetchTargets, dropdownItems } = useContext(ScannerContext);
  const routeParams = useParams();
  const location = useLocation();
  const { pathname ,search } = location;
  const [selectedItem, setSelectedItem] = useState(null);
  const [loading, setLoading] = useState(false);
  const [disableScanner, setDisableScanner] = useState(false)
  const handleServiceChange = (item) => {
    setSelectedItem(item);
  };

  const user = JSON.parse(localStorage.getItem("user"));
  const userLevel = user?.member_level;

  useEffect(() => {
    if (selectedItem !== null) {
      targetId(selectedItem?.target_uuid)

    }
    if (selectedItem?.target_uuid) {
      fetchScannerDetails(selectedItem.target_uuid)
    }
  }, [selectedItem]);

  const fetchScannerDetails = async (id) => {
    try {
      const { data } = await axios.get(`scans/${id}`);
      setDisableScanner(false)
    } catch (err) {
      if (err?.response?.data?.status == false) {
        setDisableScanner(true)
      }
    }
  };
  useEffect(() => {
    if (dropdownItems?.length > 0) {
      if (routeParams?.id || routeParams?.target_id) {
        let item;
        let id = routeParams?.id || routeParams?.target_id
        dropdownItems?.map((el) => {
          if (el.target_uuid == id) {
            item = el
          }
        })
        setSelectedItem(item)
      } else {
        setSelectedItem(dropdownItems[0])
      }
    }
  }, [dropdownItems,routeParams, search])

  const init = async () => {
    fetchTargets();
  };

  useEffect(() => {
    init();
  }, [fetchTargets]);

  return (
    <React.Fragment>
      <Collapse in={open} dimension="width"  className={`sidebar ${open ? "bounce-enter-active" : "bounce-leave-active"}`}>
        <Col lg="auto" md="12" className="p-0 sidebar-col  border-end custom-sidebar">
          <ListGroup className="list-group border-0 rounded-0 text-sm-start align-items-sm-start      custom-list-group">
            <div className="d-flex justify-content-between  align-items-center w-100">
              <Link to="/" className={`custom-list-item d-flex justify-content-between align-items-center ${pathname === "/" ? "active" : ""}`}>
                <span className="sidebar-top-heading">TARGET SELECTION</span>
              </Link>
              <Button className="btn-icon me-3" onClick={onToggle} >
                <Close />
              </Button>
            </div>
          </ListGroup>
          <div className="p-3">
            {dropdownItems.length > 0 ? (
              <BaseDropdown
                items={dropdownItems}
                selectedItem={selectedItem}
                onSelect={handleServiceChange}
                title="Targets"
                isLoading={loading}
                userLevel = {userLevel}
              />
            ) :(
              (userLevel === 0 && dropdownItems.length == 0) || userLevel === 1 || userLevel === 9 ? (
                <Link to="/assets-inventory/new" className="add-button header-title">
                  <i className="bi bi-plus-circle mx-2"></i>Add New Target
                </Link>
              ) : null
            )}
          </div>
          {/* <div className="sidebar-sub-heading mt-3">
            <span >ATTACK SURFACE</span>
          </div> */}

          {/* <Accordion defaultActiveKey={['0']} alwaysOpen className="custom-accordion ">
            <Accordion.Item eventKey="0" className="custom-accordion-item">
              <Accordion.Header className="custom-accordion-header"><ArrowDown height={25} width={25} className="accordion-arrow pe-2" /> SUMMARY </Accordion.Header>

              <Accordion.Body className="custom-accordion-body sub-body-disabled w-90" style={{
                backgroundImage: `url(${DashedImage
                  })`
              }}>

              </Accordion.Body>
            </Accordion.Item>
          </Accordion> */}
          {/* <Accordion defaultActiveKey={['0']} alwaysOpen className="custom-accordion sub-button">
            <Accordion.Item eventKey="0" className="custom-accordion-item">
              <Accordion.Header className="custom-accordion-header">
                <ArrowDown height={25} width={25} className="accordion-arrow pe-2" /> SUMMARY
              </Accordion.Header>
              <Accordion.Body
                className={`custom-accordion-body menu-disabled overview w-100 mr-5 `}
                style={{ backgroundImage: `url(${DashedImage})`, cursor: 'pointer' }}

              >
                <span style={{ fontSize: 15 }}>overview</span>

              </Accordion.Body>
            </Accordion.Item>
          </Accordion> */}
          <Accordion defaultActiveKey={['0']} alwaysOpen className="custom-accordion sub-button">
            <Accordion.Item eventKey="0" className="custom-accordion-item">
              <Accordion.Header className="custom-accordion-header">
                <ArrowDown height={25} width={25} className="accordion-arrow pe-2" /> SCANNERS IN ACTION
              </Accordion.Header>
              <Accordion.Body className="accordion-body-main child-accordian-body">
                <Accordion className="custom-accordion sub-accordion sub-body">
                  <LeftNavbar
                    open={true}
                    selectedItem={selectedItem}
                    disableScanner={disableScanner}
                  />
                </Accordion>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </Col>
      </Collapse>
    </React.Fragment>
  );
};

export default SideNavbar;
