import React from "react";
import "./CurrentStatus.scss";

const CurrentState = ({ rejected, removed }) => {
  const label = rejected ? "Rejected" : removed ? "Removed" : "";
  const backgroundColor = (colorName) => {
    switch (colorName) {
      case 'Rejected':
        return "#846C6C";
      case 'Removed':
        return "#A7A7B1";
      default:
        return;
    }
  };
  return (
    <div className="current-status-main">
      <div style={{ backgroundColor: backgroundColor(label) }} className={`current-state-color-container`}></div>
      <div>{label}</div>
    </div>
  );
};

export default CurrentState;
