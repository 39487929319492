export const spfMapping = {
  "No SPF Record Found": "arm-mx1",
  "Syntax Errors": "arm-mx2",
  "More then 10 DNS Lookups": "arm-mx3",
  "Using Multiple Records": "arm-mx4",
  "Softfail Use (~all)": "arm-mx5",
  "Using Deprecated SPF Mechanisms": "arm-mx6",
};

export const dmarcMapping = {
  "No Record Found": "arm-mx7",
  "Incorrect DMARC Syntax": "arm-mx9",
  "p=none": "arm-mx10",
};

export const getArmoryID = (type, status) => {
  if (type === "SPF") {
    return spfMapping[status] || "";
  } else if (type === "DMARC") {
    return dmarcMapping[status] || "";
  }
  return "";
};
