import React from 'react';
import { Button, ButtonGroup, Col, Container, Dropdown, DropdownButton, Image, Nav, NavDropdown, Navbar, Row } from 'react-bootstrap';
import Moment from 'react-moment';
import { decodeHTML } from 'entities';
import { Link } from 'react-router-dom';
import axios from "../../util/axios";
import { ReactComponent as NewspaperIcon } from "../../assets/images/nav-icon-newspaper.svg";
import { showPromiseToast } from '../../util/toasts'

const ExportLeaks = ({ keyword }) => {
    const handleExport = (keyword) => {

        let pdfGenerator = axios.get("leaks/export", {
            params: { keyword: keyword },
        });
        showPromiseToast(
            pdfGenerator,
            {
                pending: {
                    theme: 'dark',
                    render() {
                        return "Generating PDF..."
                    },
                    icon: false,
                },
                success: {
                    autoClose: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    theme: 'dark',
                    render({ data }) {
                        return <React.Fragment>
                            Leaks PDF available for download. <br />
                            <a className='mt-3 btn btn-primary btn-text' href={data?.data?.url} target='_blank'>Download</a>

                        </React.Fragment>
                    },
                    // other options
                    icon: NewspaperIcon,
                },
                error: {
                    render({ data }) {
                        // When the promise reject, data will contains the error
                        return "Something went wrong, please try again!"
                    }
                }
            }
        )
    };
    return (
        <React.Fragment>
            <Button variant="text" className='btn' onClick={() => { handleExport(keyword) }}>Download PDF</Button>
        </React.Fragment>
    )
}

export default ExportLeaks;