import React from "react";
import { useEffect, useState } from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { toast } from "react-toastify";
import {
  Button,
  Col,
  Container,
  Form,
  FormGroup,
  FloatingLabel,
  Row,
  Card,
  ListGroup,
  ListGroupItem,
  Stack,
  InputGroup,
  Table,
} from "react-bootstrap";

import axios from "../../util/axios";
import core from "../../util/core";
import Linker from "../../components/Linker";

// Comonents
import { Triangle } from "react-loader-spinner";
import History from "./History";
import AddAlert from "./AddAlert";
import ExportLeaks from "./ExportLeaks";
import Ellipsiser from "../../components/Ellipsiser";
import UnHash from "./UnHash";

import { showToast } from "../../util/toasts";

const Leaks = () => {
  //   const params = new URLSearchParams(window.location.search); // id=123
  const [searchParams, setSearchParams] = useSearchParams();
  const [keyword, setKeyword] = useState("");

  const [data, setData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [showHistory, setShowHistory] = useState(true);

  // define
  let handleSubmit = async (e, val) => {
    if (e) e.preventDefault();
    let tmpKeyword = keyword;
    if (val) {
      setKeyword(val);
      tmpKeyword = val;
    }
    setIsLoading(true);
    setShowHistory(false);
    try {
      console.log("my kdye", tmpKeyword);
      const res = await axios.get("leaks", { params: { keyword: tmpKeyword } });
      console.log(res.data.data);
      setData(res.data);
      setIsLoading(false);
    } catch (error) {
      console.log(error);
      setIsLoading(false);

      if (error.response.status === 429) {
        setData([]);

        showToast(error.response.data, "error", "top-center", "light", {
          autoClose: 10000,
        });
      }
    }
  };

  useEffect(() => {
    if (searchParams.has("keyword") && searchParams.has("keyword") != "") {
      handleSubmit(null, searchParams.get("keyword"));
    }
  }, [searchParams]);

  // Render Leaks info
  let renderInfo = (data) => {
    let total = 0;
    // if (data && data.data && data.data.total) {
    //     total = data.data.total * 1;
    // }
    if (data && data.data && data.data.rows) {
      total = data.data.total * 1 || data.data.rows.length;
    }
    if (data && data.advanced && data.advanced.total) {
      total += data.advanced.total;
    }
    return data &&
      ((data.data && data.data.rows) ||
        (data.advanced && data.advanced.rows)) ? (
      <>
        <div className="d-flex justify-content-between">
          <div className="fw-bold fs-6 text-white my-3">
            {total.toLocaleString("en")} results found
          </div>
          <ExportLeaks keyword={keyword} />
        </div>
        {/* normal */}
        {data.data?.rows &&
          data.data.rows.map((item) => {
            var randomColor = Math.floor(Math.random() * 16777215).toString(16);
            const border = () =>
              `5px solid hsla(${Math.random() * 360}, 50%, 50%, 1)`;

            return (
              <div className="my-2 rounded border p-md-2 p-2 mx-auto fs-md-3 main_container_style ">
                <Table variant="custom" borderless>
                  <tbody>
                    {Object.entries(item["_source"]).map(([key, val]) => {
                      return (
                        <tr>
                          <th
                            nowrap={true}
                            width="150"
                            className="text-capitalize"
                          >
                            {key}
                          </th>
                          <td className="text-end text-md-start custom-vals">
                            <Ellipsiser>{val}</Ellipsiser>
                            {"hashed_password" === key ? (
                              <UnHash hash={val} />
                            ) : (
                              ""
                            )}
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </Table>
              </div>
            );
          })}
        {/* Advanced */}
        {data.advanced?.rows &&
          data.advanced?.rows.map((row) => {
            return (
              <div className="my-2 rounded border p-md-2 p-2 mx-auto fs-md-3 ">
                <Table variant="custom" borderless>
                  <tbody>
                    {Object.entries(
                      core.removeKey(core.cleanMe(row), "id")
                    ).map(([key, val]) => {
                      return (
                        <tr>
                          <th
                            nowrap={true}
                            width="150"
                            className="text-capitalize"
                          >
                            {key}
                          </th>
                          <td className="text-end text-md-start custom-val">
                            <Ellipsiser>{val}</Ellipsiser>
                            {"hashed_password" === key ? (
                              <UnHash hash={val} />
                            ) : (
                              ""
                            )}
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </Table>
              </div>
            );
          })}
      </>
    ) : !isLoading ? (
      <div className="text-center py-3">No Record Found</div>
    ) : (
      ""
    );
  };

  return (
    <React.Fragment>
      <div className="main_container_style">
        <Form id="searchForm" onSubmit={handleSubmit}>
          <Row className="align-items-center mb-3">
            <Col>
              <InputGroup className="d-flex input-with-icon">
                <div>
                  <i className="bi bi-search append-icon mt-4"></i>
                </div>
                <Form.Control
                  type="text"
                  placeholder="Enter"
                  size="llg"
                  className="border rounded"
                  id={"keyword-input"}
                  name="keyword-input"
                  required
                  value={keyword}
                  onFocus={(e) => {
                    setShowHistory(false);
                  }}
                  onBlur={(e) => {
                    if (e.target.value == "") {
                      setShowHistory(true);
                    }
                  }}
                  onChange={(e) => {
                    setKeyword(e.target.value);
                  }}
                />
              </InputGroup>
            </Col>
            <Col xs="auto" className="ps-0 d-flex justify-content-between">
              <Button type="submit" className="btn-full">
                Search
              </Button>
              {/* {!showHistory && keyword ? (
              <>
                <AddAlert keyword={keyword} />
              </>
            
            ) : (
              <Linker
                to={"/leaks/alerts"}
                title="Manage Alerts"
                className="m-1 ms-2 "
              >
                <img alt="" src={`/images/icon-mini-alerts.png`} width={40} />
              </Linker>
            )} */}
            </Col>
          </Row>

          <Row>
            <Col>
              <div className={showHistory ? "aa" : "d-none"}>
                <History />
              </div>
              <div className={showHistory && !data ? "d-none" : ""}>
                {isLoading ? (
                  <Triangle
                    height="80"
                    width="80"
                    color="#fff"
                    ariaLabel="triangle-loading"
                    wrapperStyle={{ justifyContent: "center" }}
                    wrapperClassName=""
                    visible={true}
                  />
                ) : (
                  renderInfo(data)
                )}
              </div>
            </Col>
          </Row>
        </Form>
      </div>
    </React.Fragment>
  );
};

export default Leaks;
