import React, { useCallback, useEffect } from "react";
import { useState } from "react";

import { Triangle, ProgressBar } from "react-loader-spinner";
import {
  Button,
  Col,
  Container,
  FloatingLabel,
  FormCheck,
  FormGroup,
  FormSelect,
  Image,
  OverlayTrigger,
  Row,
  Table,
  Tooltip,
} from "react-bootstrap";

import axios from "../../../util/axios";
import { Link, useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import EditableField from "../../../components/EditableField";
import { Field, Form, Formik } from "formik";
import NewSingle from "../../admin/news/NewsSingle";
import Confirm from "../../../components/Confirm";
import ConfirmationModalContextProvider from "../../../components/ModalConfirmationContext";
import InfiniteScroll from "react-infinite-scroller";
import { showToast } from "../../../util/toasts";

async function loadItems(type, page, pageSize) {
  try {
    let res = await axios.get("/admin/news", {
      params: { type, page: page, pageSize: pageSize },
    });
    console.log("data", res.data);
    return res.data;
  } catch (error) {
    console.log(error);
    throw error;
  }
}
const News = () => {
  const [items, setItems] = useState([]);
  const [total, setTotal] = useState("");
  const [page, setPage] = useState(1);
  const routeParams = useParams();
  const navigate = useNavigate();

  let typeHeading = "Pending";
  let type = null;
  if ("approved" == routeParams?.type) {
    typeHeading = "Approved";
    type = 1;
  }
  if ("rejected" == routeParams?.type) {
    typeHeading = "Rejected";
    type = 2;
  }
  const pageSize = 20;
  const [fetching, setFetching] = useState(false);
  const [submitMod, setSubmitMod] = useState(false);

  const [checked, setChecked] = useState([]);

  const handleCheckAllChange = (e) => {
    setChecked(e.target.checked ? items.map((c) => c.id) : []);
  };

  const handleNewsChange = (e, news) => {
    setChecked((prevChecked) =>
      e.target.checked
        ? [...prevChecked, news.id]
        : prevChecked.filter((item) => item !== news.id)
    );
  };

  const handleSubmit = (values, helpers) => {
    setFetching(true);

    axios
      .post("/admin/news", values)
      .then((response) => {
        const data = response.data;
        setFetching(false);
        if (data.message) {
          fetchItems();

          showToast(data.message, "success", "top-center", "light");
          helpers.resetForm();
        }
      })
      .catch((e) => {
        setFetching(false);
        if (e.response.status === 422) {
          for (const fieldKey in e.response.data) {
            const field = e.response.data[fieldKey];
            helpers.setFieldError(field.path, field.msg);
          }
        }
      });
  };
  const init = async () => {
    setFetching(true);
    await fetchItems();
    setFetching(false);
  };

  const fetchItems = useCallback(
    async (pageTmp, refresh = false) => {
      if (fetching) {
        return;
      }

      setFetching(true);

      try {
        const data = await loadItems(type, page, pageSize);
        console.log("refresh", refresh);
        if (refresh) {
          setItems(data.items);
        } else {
          setItems([...items, ...data.items]);
        }
        setPage(data.nextPage);
        setTotal(data.total);
      } finally {
        setFetching(false);
      }
    },
    [items, fetching, page, pageSize, type]
  );

  const hasMoreItems = !!page;

  const loader = (
    <div key="loader" className="loader">
      <Triangle
        height="80"
        width="80"
        color="#fff"
        ariaLabel="triangle-loading"
        wrapperStyle={{ justifyContent: "center" }}
        wrapperClassName=""
      />
    </div>
  );
  useEffect(() => {
    setPage(1);
    fetchItems(page, true);
  }, [type]);

  return (
    <React.Fragment>
      <Container className="py-5 max-80 admin" fluid>
        <h1 className="fw-normal mb-5">
          <Image src="/images/icon-news.png" width={50} rounded /> News (
          {typeHeading})
        </h1>

        <Formik
          initialValues={{
            friends: items,
            news: items,
            mod: "",
          }}
          onSubmit={handleSubmit}
          render={({
            handleChange,
            handleSubmit,
            handleBlur,
            values,
            errors,
            validateForm,
          }) => (
            <Form>
              <ConfirmationModalContextProvider>
                <Row>
                  <Col className="d-flex justify-content-end mb-3">
                    <FormSelect
                      aria-label="Select News Type"
                      onChange={(e) => {
                        setTimeout(() => {
                          navigate("/admin/news/" + e.target.value);
                        }, 500);
                      }}
                    >
                      <option value="" selected={!type}>
                        Pending
                      </option>
                      <option value="approved" selected={1 == type}>
                        Approved
                      </option>
                      <option value="rejected" selected={2 == type}>
                        Rejected
                      </option>
                    </FormSelect>
                    <Button
                      type="button"
                      name="refresh"
                      variant="info"
                      className="btn ms-3"
                      onClick={init}
                    >
                      <i className="bi bi-lightning-charge-fill "></i> Refresh
                    </Button>
                  </Col>
                </Row>
                <InfiniteScroll
                  loadMore={fetchItems}
                  hasMore={hasMoreItems}
                  loader={loader}
                  className="container-fluids "
                >
                  {items.map((item) => (
                    <NewSingle
                      key={item.id}
                      item={item}
                      fetchItems={() => {
                        setPage(0);
                        fetchItems(page, true);
                      }}
                    />
                  ))}
                  {items.length <= 0 ? (
                    <Row>
                      <Col>Sorry! no news found.</Col>
                    </Row>
                  ) : (
                    ""
                  )}
                </InfiniteScroll>
              </ConfirmationModalContextProvider>
            </Form>
          )}
        />
      </Container>
    </React.Fragment>
  );
};

export default News;
