import React, { useEffect, useRef, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import axios from "../../util/axios";
import { useParams, useLocation } from "react-router-dom";
import Breadcrumb from "../../components/breadcrumbs/BreadCrumb";
import { closeToast, showToast } from "../../util/toasts";
import CreateTarget from "../create-target/CreateTarget";

const DomainScan = () => {
  const { id } = useParams();
  const [target, setTarget] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const location = useLocation();
  const path = location.pathname;
  const title = path.includes("/edit") ? ["Edit Target"] : ["Create Target"];
  const toastShownRef = useRef(null);

  useEffect(() => {
    const fetchTargets = async () => {
      if (!id) {
        setTarget([]);
        return;
      }
      setIsLoading(true);
      try {
        const response = await axios.get(`/targets/${id}`);
        const hostList = [];
        response.data?.hosts_list?.split(",")?.map((el) => {
          hostList.push({
            host: el?.trim(),
          });
        });
        const updatedTargets = {
          scanInfo: {
            ...response.data,
            hosts: hostList,
            id: response.data?.id,
            scan_name: response.data?.title,
            description: response.data?.description,
            scan_target_hosts: response.data?.scan_target_hosts,
          },
        };

        setTarget(updatedTargets);
      } catch (error) {
        showToast(
          "Failed to fetch data: " + error.message,
          "error",
          "top-center",
          "light"
        );
        console.error(error);
      } finally {
        setIsLoading(false);
      }
    };
    fetchTargets();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    if (id && !toastShownRef.current) {
      toastShownRef.current = showToast(
        <div style={{ textWrap: "nowrap" }}>
          <b>Edit target allows for the addition of new assets only</b>
          <br />
          To manage existing assets, please navigate to the Asset Inventory page
        </div>,
        "info",
        "top-center",
        "light",
        {
          style: { width: "fit-content", alignItems: "center" },
        },
        false
      );
    }
    return () => {
      closeToast(toastShownRef.current);
      toastShownRef.current = null;
    };
  }, [id]);

  return (
    <>
      <Breadcrumb title={title} isScanning={false} isLastUpdate={false} />
      <Container fluid className="mt-5 px-4">
        <Row>
          <Col className="px-5">
            {!isLoading && <CreateTarget scan={target} />}
          </Col>
        </Row>
      </Container>
    </>
  );
};
export default DomainScan;
