import React, { useMemo } from "react";
import { ipRegex } from "../../util/constants";
import { ThreeCircles } from "react-loader-spinner";

const DomainModal = ({
  isOpen,
  onClose,
  onConfirm,
  domain,
  isRejectMode,
  showDomain,
  isDomainLoading,
}) => {

  const { domainsToDisplay, isMultipleDomains, isIp, actionText } =
    useMemo(() => {
      const domainsToDisplay = Array.isArray(domain) ? domain : [domain];
      const isMultipleDomains = domainsToDisplay.length > 1;
      const isIp = domainsToDisplay.every((el) => ipRegex.test(el));
      const actionText = isRejectMode ? "Reject" : "Scan";

      return { domainsToDisplay, isMultipleDomains, isIp, actionText };
    }, [domain, isRejectMode]);

  if (!isOpen) return null;

  return (
    <div className="asset-modal-overlay">
      <div className="asset-modal-content">
        <div className="domain-header">
          <h4>
            {isMultipleDomains
              ? `Are you sure you want to ${actionText.toLowerCase()} ${
                  domainsToDisplay.length
                } selected ${isIp ? "IP Addresses" : "hostnames"}?`
              : `Are you sure you want to ${actionText.toLowerCase()} ${
                  domainsToDisplay[0]
                } ${
                  showDomain ? "domain" : `${isIp ? "IP Address" : "hostname"}`
                }?`}
          </h4>
        </div>
        <p>
          {isMultipleDomains
            ? `These ${isIp ? "IP Addresses" : "hostnames"} will be ${
                actionText.toLowerCase() == "scan" ? "scanned" : "rejected"
              } ${
                isRejectMode
                  ? "and will not appear in future scans."
                  : "for related assets, which will automatically be added to the asset inventory."
              }`
            : `This ${
                showDomain ? "domain" : `${isIp ? "IP Address" : "hostname"}`
              } will be ${
                actionText.toLowerCase() == "scan" ? "scanned" : "rejected"
              } ${
                isRejectMode
                  ? "and will not appear in future scans."
                  : "for related assets, which will automatically be added to the asset inventory."
              }`}
        </p>

        {isMultipleDomains && <hr />}

        {isMultipleDomains ? (
          <div
            className="domain-list"
            style={{ maxHeight: "150px", overflowY: "scroll" }}
          >
            {domainsToDisplay.map((d) => (
              <div className="list-domains" key={d}>
                {d}
              </div>
            ))}
          </div>
        ) : (
          <div className="single-domain"></div>
        )}

        <div className="asset-modal-actions">
          <button className="btn asset-modal-button" onClick={onClose}>
            Cancel
          </button>
          <button
            className="btn asset-modal-button"
            onClick={() =>
              onConfirm(domainsToDisplay, isRejectMode, showDomain)
            }
            disabled={isDomainLoading}
          >
            {isDomainLoading ? (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <ThreeCircles
                  visible={true}
                  height="20"
                  width="20"
                  color="#398ffe"
                  ariaLabel="three-circles-loading"
                  wrapperClass=""
                />
              </div>
            ) : (
              actionText
            )}
          </button>
        </div>
      </div>
    </div>
  );
};

export default DomainModal;
