import React, { useState, useEffect, useRef, useMemo } from "react";
import { OverlayTrigger, Popover } from "react-bootstrap";
import { ReactComponent as Arrow } from "../../../../assets/images/port-arrow.svg";
import { ReactComponent as WarningIcon } from "../../../../assets/images/warning-icon.svg";
import { ReactComponent as CheckCircle } from "../../../../assets/images/check-circle.svg";
import moment from "moment";
import CurrentStatus from "../../../../components/status/CurrentStatus.js";
import { HostnameLink } from "../../../../components/textLink/TextLink.js";
import { sensitivePopoverContent } from "../../../../components/tooltip/Tooltip.js";

const DataList = ({ data, openDetails }) => {
  const scrollContainerRef = useRef(null);
  const initialCollapses = useMemo(() => {
    const collapsesState = {};
    data.forEach((item) => {
      collapsesState[item.id] = item.data.length > 0;
    });
    return collapsesState;
  }, [data]);
  const [collapses, setCollapses] = useState(initialCollapses);
  const [showPopover, setShowPopover] = useState({});

  const toggleCollapse = (id) => {
    setCollapses((prevState) => ({
      ...prevState,
      [id]: !prevState[id],
    }));
  };

  const handlePopoverVisibility = (id, isVisible) => {
    setShowPopover((prev) => ({ ...prev, [id]: isVisible }));
  };

  const onMousePopoverEnter = (id) => {
    handlePopoverVisibility(id, true);
  };

  const onMousePopoverLeave = (id) => {
    handlePopoverVisibility(id, false);
  };

  const onClickPopover = (id, info) => {
    openDetails(info);
    setShowPopover((prev) => ({ ...prev, [id]: false }));
  };

  return data.map((item) => (
    <div className="sensitive-exposed-data-list-table" key={item.id}>
      <div
        className="left-data-container"
        onClick={() => item.data?.length > 0 && toggleCollapse(item.id)}
        style={{ width: !collapses[item.id] ? "auto" : "200px" }}
      >
        {item.data?.length > 0 && (
          <Arrow
            className={
              !collapses[item.id] ? "arrow-rotated-up" : "arrow-rotated-down"
            }
          />
        )}
        <span>{item.name}</span>
      </div>
      <div
        className={`right-data-container ${collapses[item.id] ? "" : ""}`}
        style={{
          width: !collapses[item.id] ? "auto" : "calc(100% - 200px)",
        }}
      >
        {!collapses[item.id] ? (
          <div className="total-data-details">
            <div className="divider" />
            {item.data.length > 0 ? (
              <div className="detail-text">
                <WarningIcon /> <span>{item.domains}</span>{" "}
                {item.domains == 1 ? "Hostname" : "Hostnames"},{" "}
                <span>{item.urls}</span> {item.urls == 1 ? "URL" : "URLs"}
              </div>
            ) : (
              <div className="detail-text">
                <CheckCircle /> No results found on recent scanning
              </div>
            )}
          </div>
        ) : (
          <div
            className="sensitive-collapse-table-container"
            ref={scrollContainerRef}
          >
            <div className="data-collapse-table">
              <table>
                <thead>
                  <tr style={{ paddingTop: "16px" }}>
                    <th>Hostname</th>
                    <th>URL</th>
                    <th>Title</th>
                    <th>Description</th>
                    <th>First Detected</th>
                    <th
                      style={{
                        minWidth: "150px",
                      }}
                    >
                      Current State
                    </th>
                    <th className={`button-data th-button border-left`}>
                      <div
                        style={{
                          height: "10vh",
                        }}
                      />
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {item?.data?.map((info, index) => (
                    <tr
                      className="data-table-border"
                      key={`${item.id}-${index}`}
                    >
                      <td>
                        <HostnameLink hostname={info.domain} isNotDomain />
                      </td>
                      <td>
                        <HostnameLink hostname={info.url} />
                      </td>
                      <td>
                        {info.title?.length > 40 ? (
                          <OverlayTrigger
                            trigger="click"
                            placement="top"
                            show={showPopover[`title-${item.id}-${index}`]}
                            overlay={sensitivePopoverContent(
                              `title-${item.id}-${index}`,
                              info,
                              onMousePopoverEnter,
                              onMousePopoverLeave,
                              onClickPopover
                            )}
                          >
                            <span
                              className="title-with-popover"
                              onMouseEnter={() =>
                                handlePopoverVisibility(
                                  `title-${item.id}-${index}`,
                                  true
                                )
                              }
                              onMouseLeave={() =>
                                handlePopoverVisibility(
                                  `title-${item.id}-${index}`,
                                  false
                                )
                              }
                            >
                              {info.title?.slice(0, 40)}...
                            </span>
                          </OverlayTrigger>
                        ) : (
                          info.title
                        )}
                      </td>
                      <td>
                        {info.description?.length > 40 ? (
                          <OverlayTrigger
                            trigger="click"
                            placement="top"
                            show={
                              showPopover[`description-${item.id}-${index}`]
                            }
                            overlay={sensitivePopoverContent(
                              `description-${item.id}-${index}`,
                              info,
                              onMousePopoverEnter,
                              onMousePopoverLeave,
                              onClickPopover
                            )}
                          >
                            <span
                              className="title-with-popover"
                              onMouseEnter={() =>
                                handlePopoverVisibility(
                                  `description-${item.id}-${index}`,
                                  true
                                )
                              }
                              onMouseLeave={() =>
                                handlePopoverVisibility(
                                  `description-${item.id}-${index}`,
                                  false
                                )
                              }
                            >
                              {info.description?.slice(0, 40)}...
                            </span>
                          </OverlayTrigger>
                        ) : (
                          info.description
                        )}
                      </td>
                      <td>{moment(info.first_detected).format("DD/MM/YY")}</td>
                      <td>
                        <CurrentStatus
                          status={info.change_status}
                          tooltipInfo={info?.changes}
                          headerKeys={{
                            category: "Category",
                            hostname: "Hostname",
                            url: "URL",
                            title: "Title",
                            description: "Description",
                            first_detected: "First Detected",
                            change_status: "Current State",
                          }}
                        />
                      </td>
                      <td
                        className={`button-data border-left`}
                        onClick={() => openDetails(info)}
                      >
                        <div>View Details</div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        )}
      </div>
    </div>
  ));
};

export default DataList;
