import { webDataFillter,webDataArchiveFillter } from "./genericFunctions";

export const assetsDiscoveryTabsConfig = ({
  domainName = [],
  hostName = [],
  ipName = [],
  statusName = [],
  hostingName = [],
  cdnName = [],
  networkZoneFilterOptions = [],
  serviceCategorName = [],
  webInterfaceName = [],
  criticalityFilterOptions = [],
  secutiryIssues = [],
  discoveredByFilterOptions = [],
  firstDetectedFilterOptions = [],
}) => {
  return [
    {
      eventKey: "1",
      title: "Domain",
      contentTitle: "Domain",
      data: Array.from(domainName),
      data_key: "domain",
    },
    {
      eventKey: "2",
      title: "Hostname",
      contentTitle: "Hostname",
      data: Array.from(hostName),
      data_key: "host",
    },
    {
      eventKey: "3",
      title: "IP Address",
      contentTitle: "IP Address",
      data: Array.from(ipName),
      data_key: "ip",
    },
    {
      eventKey: "4",
      title: "Status",
      contentTitle: "Status",
      data: Array.from(statusName),
      data_key: "status",
    },
    {
      eventKey: "5",
      title: "Hosting Provider",
      contentTitle: "Hosting Provider",
      data: Array.from(hostingName),
      data_key: "hosting_provider_discovery",
    },
    {
      eventKey: "6",
      title: "CDN",
      contentTitle: "CDN",
      data: Array.from(cdnName),
      data_key: "cdn_name_discovery",
    },
    {
      eventKey: "11",
      title: "Network Zone",
      contentTitle: "Network Zone",
      data: Array.from(networkZoneFilterOptions),
      data_key: "network_zone",
    },
    {
      eventKey: "12",
      title: "Service Category",
      contentTitle: "Service Category",
      data: Array.from(serviceCategorName),
      data_key: "service_category",
    },
    {
      eventKey: "15",
      title: "Web Interface",
      contentTitle: "Web Interface",
      data: Array.from(webInterfaceName),
      data_key: "host_status_code",
    },
    {
      eventKey: "13",
      title: "Asset Criticality",
      contentTitle: "Asset Criticality",
      data: Array.from(criticalityFilterOptions),
      data_key: "criticality",
    },
    {
      eventKey: "9",
      title: "Security Issues",
      contentTitle: "Security Issues",
      data: secutiryIssues,
      data_key: "number_issues",
    },
    {
      eventKey: "10",
      title: "Discovered By",
      contentTitle: "Discovered By",
      data: Array.from(discoveredByFilterOptions),
      data_key: "discovered_by",
    },
    {
      eventKey: "14",
      title: "First Detected",
      contentTitle: "First Detected",
      data: firstDetectedFilterOptions,
      data_key: "first_detected",
      isDatePicker: true,
    },
    {
      eventKey: "8",
      title: "Current State",
      contentTitle: "Current State",
      data: webDataFillter("8"),
      data_key: "current_state",
    },
  ];
};

export const assetsInventoryTabsConfig = ({
  domains = [],
  hosts = [],
  ips = [],
  statusFilterOptions = [],
  hostingProvider = [],
  cdn = [],
  ports = [],
  secutiryIssues = [],
}) => {
  return [
    {
      eventKey: "1",
      title: "Domain",
      contentTitle: "Domain",
      data: Array.from(domains),
      data_key: "domain",
    },
    {
      eventKey: "3",
      title: "Hostname",
      contentTitle: "Hostname",
      data: Array.from(hosts),
      data_key: "host",
    },
    {
      eventKey: "2",
      title: "IP Address",
      contentTitle: "IP Address",
      data: Array.from(ips),
      data_key: "ip",
    },
    {
      eventKey: "5",
      title: "Status",
      contentTitle: "Status",
      data: statusFilterOptions,
      data_key: "status",
    },
    {
      eventKey: "4",
      title: "Hosting Provider",
      contentTitle: "Hosting Provider",
      data: Array.from(hostingProvider),
      data_key: "hosting_provider_discovery",
    },
    {
      eventKey: "6",
      title: "CDN",
      contentTitle: "CDN",
      data: Array.from(cdn),
      data_key: "cdn_name_discovery",
    },
    {
      eventKey: "7",
      title: "Ports",
      contentTitle: "Ports",
      data: Array.from(ports),
      data_key: "port",
    },
    {
      eventKey: "8",
      title: "Security Issues",
      contentTitle: "Security Issues",
      data: Array.from(secutiryIssues),
      data_key: "number_issues",
    },
  ];
};
export const assetsArchiveTabsConfig = ({
  domainName = [],
  hostName = [],
  ipName = [],
  hostingName = [],
  cdnName = [],
  networkZoneFilterOptions = [],
  serviceCategorName = [],
  webInterfaceName = [],
  criticalityFilterOptions = [],
  secutiryIssues = [],
  discoveredByFilterOptions = [],
  firstDetectedFilterOptions = [],
}) => {
  return [
    {
      eventKey: "1",
      title: "Domain",
      contentTitle: "Domain",
      data: Array.from(domainName),
      data_key: "domain",
    },
    {
      eventKey: "2",
      title: "Hostname",
      contentTitle: "Hostname",
      data: Array.from(hostName),
      data_key: "host",
    },
    {
      eventKey: "3",
      title: "IP Address",
      contentTitle: "IP Address",
      data: Array.from(ipName),
      data_key: "ip",
    },
    {
      eventKey: "5",
      title: "Hosting Provider",
      contentTitle: "Hosting Provider",
      data: Array.from(hostingName),
      data_key: "hosting_provider_discovery",
    },
    {
      eventKey: "6",
      title: "CDN",
      contentTitle: "CDN",
      data: Array.from(cdnName),
      data_key: "cdn_name_discovery",
    },
    {
      eventKey: "11",
      title: "Network Zone",
      contentTitle: "Network Zone",
      data: Array.from(networkZoneFilterOptions),
      data_key: "network_zone",
    },
    {
      eventKey: "12",
      title: "Service Category",
      contentTitle: "Service Category",
      data: Array.from(serviceCategorName),
      data_key: "service_category",
    },
    {
      eventKey: "15",
      title: "Web Interface",
      contentTitle: "Web Interface",
      data: Array.from(webInterfaceName),
      data_key: "host_status_code",
    },
    {
      eventKey: "13",
      title: "Asset Criticality",
      contentTitle: "Asset Criticality",
      data: Array.from(criticalityFilterOptions),
      data_key: "criticality",
    },
    {
      eventKey: "9",
      title: "Security Issues",
      contentTitle: "Security Issues",
      data: secutiryIssues,
      data_key: "number_issues",
    },
    {
      eventKey: "10",
      title: "Discovered By",
      contentTitle: "Discovered By",
      data: Array.from(discoveredByFilterOptions),
      data_key: "discovered_by",
    },
    {
      eventKey: "14",
      title: "First Detected",
      contentTitle: "First Detected",
      data: firstDetectedFilterOptions,
      data_key: "first_detected",
      isDatePicker: true,
    },
    {
      eventKey: "8",
      title: "Current State",
      contentTitle: "Current State",
      data: webDataArchiveFillter("8"),
      data_key: "current_state",
    },
  ];
};
export const securityIssuesTabsConfig = ({
  uniqueDomains = [],
  uniqueHost = [],
  uniqueIpLists = [],
  Exploitabilities = [],
  impacts = [],
  scores = [],
  titles = [],
  criticalityFilterList = [],
  firstSeenFilterOptions = [],
  daysOpen = [],
  CVSS = [],
  EPSS = [],
  cisaKevFilterList = [],
  armoryTestFilterList = [],
  issueStateFilterList = [],
  scanners=[],
}) => {
  return [
    {
      eventKey: "10",
      title: "Domain",
      contentTitle: "Domain",
      data: Array.from(uniqueDomains),
      data_key: "domain",
    },
    {
      eventKey: "11",
      title: "Hostname",
      contentTitle: "Hostname",
      data: Array.from(uniqueHost),
      data_key: "host",
    },
    {
      eventKey: "12",
      title: "IP Address",
      contentTitle: "IP Address",
      data: Array.from(uniqueIpLists),
      data_key: "ip",
    },
    {
      eventKey: "5",
      title: "Exploitability",
      contentTitle: "Exploitability",
      data: Array.from(Exploitabilities),
      data_key: "exploitability",
    },
    {
      eventKey: "4",
      title: "Impact",
      contentTitle: "Impact",
      data: Array.from(impacts),
      data_key: "impact",
    },
    {
      eventKey: "8",
      title: "Risk Score",
      contentTitle: "Risk Score",
      data: Array.from(scores),
      data_key: "risk_score",
    },
    {
      eventKey: "13",
      title: "Title",
      contentTitle: "Title",
      data: Array.from(titles),
      data_key: "issue_name",
    },

    {
      eventKey: "14",
      title: "Critical Asset",
      contentTitle: "Critical Asset",
      data: Array.from(criticalityFilterList),
      data_key: "asset_criticality",
    },
    {
      eventKey: "15",
      title: "First Seen",
      contentTitle: "First Seen",
      data: firstSeenFilterOptions,
      data_key: "first_seen_at",
      isDatePicker: true,
    },
    {
      eventKey: "16",
      title: "Days Open",
      contentTitle: "Days Open",
      data: daysOpen,
      data_key: "days_open",
    },
    {
      eventKey: "6",
      title: "CVSS",
      contentTitle: "CVSS",
      data: Array.from(CVSS),
      data_key: "cvss",
    },
    {
      eventKey: "7",
      title: "EPSS",
      contentTitle: "EPSS",
      data: Array.from(EPSS),
      data_key: "epss",
    },
    {
      eventKey: "17",
      title: "CISA KEV",
      contentTitle: "CISA KEV",
      data: Array.from(cisaKevFilterList),
      data_key: "cisa_kev",
    },
    {
      eventKey: "18",
      title: "Status",
      contentTitle: "Status",
      data: webDataFillter("18", "Status"),
      data_key: "status",
    },
    {
      eventKey: "19",
      title: "Armory Tested",
      contentTitle: "Armory Tested",
      data: Array.from(armoryTestFilterList),
      data_key: "armory_tested",
    },
    {
      eventKey: "20",
      title: "Issue State",
      contentTitle: "Issue State",
      data: Array.from(issueStateFilterList),
      data_key: "issue_status",
    },
    {
      eventKey: "21",
      title: "Scanner",
      contentTitle: "Scanner",
      data: Array.from(scanners),
      data_key: "scanner",
    },
  ];
};

export const portsTabsConfig = ({
  ips = [],
  portsName = [],
  serviceName = [],
  versionName = [],
  encryptedProtocols = [],
}) => {
  return [
    {
      eventKey: "1",
      title: "IP Address",
      contentTitle: "IP Address",
      data: Array.from(ips),
      data_key: "ip",
    },
    {
      eventKey: "2",
      title: "Ports",
      contentTitle: "Ports",
      data: Array.from(portsName),
      data_key: "port",
    },
    {
      eventKey: "3",
      title: "Service",
      contentTitle: "Service",
      data: Array.from(serviceName),
      data_key: "service",
    },
    {
      eventKey: "4",
      title: "Name & Version",
      contentTitle: "Name & Version",
      data: Array.from(versionName),
      data_key: "product",
    },
    {
      eventKey: "5",
      title: "Encrypted Protocol",
      contentTitle: "Encrypted Protocol",
      data: encryptedProtocols,
      data_key: "is_encrypted",
    },
    {
      eventKey: "6",
      title: "Current State",
      contentTitle: "Current State",
      data: webDataFillter("6"),
      data_key: "current_state",
    },
  ];
};

export const sshTabsConfig = ({
  ips = [],
  portNumber = [],
  authName = [],
  passAuthFilter = [],
  dictionaryAttackFilter = [],
}) => {
  return [
    {
      eventKey: "1",
      title: "IP Address",
      contentTitle: "IP Address",
      data: Array.from(ips),
      data_key: "ip",
    },
    {
      eventKey: "2",
      title: "Port",
      contentTitle: "Port",
      data: Array.from(portNumber),
      data_key: "port",
    },
    {
      eventKey: "7",
      title: "Auth. Method",
      contentTitle: "Auth. Method",
      data: Array.from(authName),
      data_key: "auth_methods",
    },
    {
      eventKey: "3",
      title: "Password Auth.",
      contentTitle: "Password Auth.",
      data: Array.from(passAuthFilter),
      data_key: "pass_auth_method",
    },
    {
      eventKey: "5",
      title: "Dictionary Attack",
      contentTitle: "Dictionary Attack",
      data: Array.from(dictionaryAttackFilter),
      data_key: "creds",
    },
    {
      eventKey: "6",
      title: "Current State",
      contentTitle: "Current State",
      data: webDataFillter("6"),
      data_key: "current_state",
    },
  ];
};

export const ftpTabsConfig = ({
  ips = [],
  portNumber = [],
  encryptionFilter = [],
  passAuthFilter = [],
  dictionaryAttackFilter = [],
}) => {
  return [
    {
      eventKey: "1",
      title: "IP Address",
      contentTitle: "IP Address",
      data: Array.from(ips),
      data_key: "ip",
    },
    {
      eventKey: "2",
      title: "Port",
      contentTitle: "Port",
      data: Array.from(portNumber),
      data_key: "port",
    },
    {
      eventKey: "4",
      title: "Encryption",
      contentTitle: "Encryption",
      data: Array.from(encryptionFilter),
      data_key: "is_encrypted",
    },
    {
      eventKey: "3",
      title: "Anonymous Auth.",
      contentTitle: "Anonymous Auth.",
      data: Array.from(passAuthFilter),
      data_key: "pass_auth_method",
    },
    {
      eventKey: "7",
      title: "Dictionary Attack",
      contentTitle: "Dictionary Attack",
      data: Array.from(dictionaryAttackFilter),
      data_key: "creds",
    },
    {
      eventKey: "6",
      title: "Current State",
      contentTitle: "Current State",
      data: webDataFillter("6"),
      data_key: "current_state",
    },
  ];
};

export const emailSecurityTabsConfig = ({
  domains = [],
  spfData = [],
  dmarcData = [],
  emailConfigurationFilters = [],
}) => {
  return [
    {
      eventKey: "1",
      title: "Domain",
      contentTitle: "Domain",
      data: Array.from(domains),
      data_key: "host",
    },

    {
      eventKey: "3",
      title: "SPF",
      contentTitle: "SPF",
      data: Array.from(spfData),
      data_key: "is_spf_secured",
    },

    {
      eventKey: "4",
      title: "DMARC",
      contentTitle: "DMARC",
      data: Array.from(dmarcData),
      data_key: "is_dmarc_secured",
    },
    {
      eventKey: "2",
      title: "Secured vs Unsecured",
      contentTitle: "Secured vs Unsecured",
      data: Array.from(emailConfigurationFilters),
      data_key: "is_secured",
    },
    {
      eventKey: "6",
      title: "Current State",
      contentTitle: "Current State",
      data: webDataFillter("6"),
      data_key: "current_state",
    },
  ];
};

export const hostingProviderTabsConfig = ({
  ips = [],
  hostingFilterOptions = [],
  hostingName = [],
}) => {
  return [
    {
      eventKey: "1",
      title: "IP Address",
      contentTitle: "IP Address",
      data: Array.from(ips),
      data_key: "ip",
    },
    {
      eventKey: "3",
      title: "Hosting Provider",
      contentTitle: "Hosting Provider",
      data: Array.from(hostingFilterOptions),
      data_key: "has_hosting_provider",
    },
    {
      eventKey: "2",
      title: "Hosting Provider Name",
      contentTitle: "Hosting Provider Name",
      data: Array.from(hostingName),
      data_key: "hosting_provider",
    },
    {
      eventKey: "6",
      title: "Current State",
      contentTitle: "Current State",
      data: webDataFillter("6"),
      data_key: "current_state",
    },
  ];
};

export const dnsTabsConfig = ({
  hostName = [],
  registrarName = [],
  registeredOnFilterOptions = [],
  expiredOnFilterOptions = [],
  dnsFilterOption = [],
  nameServers = [],
}) => {
  return [
    {
      eventKey: "1",
      title: "Domain",
      contentTitle: "Domain",
      data: Array.from(hostName),
      data_key: "host",
    },
    {
      eventKey: "2",
      title: "Registrar",
      contentTitle: "Registrar",
      data: Array.from(registrarName),
      data_key: "registrar",
    },
    {
      eventKey: "7",
      title: "Registered On",
      contentTitle: "Registered On",
      data: registeredOnFilterOptions,
      data_key: "registration_date",
      isDatePicker: true,
    },
    {
      eventKey: "8",
      title: "Expires On",
      contentTitle: "Expires On",
      data: expiredOnFilterOptions,
      data_key: "validity",
      isDatePicker: true,
    },
    {
      eventKey: "4",
      title: "DNSSEC",
      contentTitle: "DNSSEC",
      data: Array.from(dnsFilterOption),
      data_key: "dnssec",
    },
    {
      eventKey: "3",
      title: "Name Servers",
      contentTitle: "Name Servers",
      data: Array.from(nameServers),
      data_key: "nameservers",
    },
    {
      eventKey: "6",
      title: "Current State",
      contentTitle: "Current State",
      data: webDataFillter("6"),
      data_key: "current_state",
    },
  ];
};

export const loginPagesSecurityTabsConfig = ({
  hostnames = [],
  loginPages = [],
  httpsFilterOptions = [],
  httpFilterOptions = [],
}) => {
  return [
    {
      eventKey: "1",
      title: "Hostname",
      contentTitle: "Hostname",
      data: hostnames,
      data_key: "host",
    },
    {
      eventKey: "2",
      title: "Login Page",
      contentTitle: "Login Page",
      data: loginPages,
      data_key: "login_page",
    },
    {
      eventKey: "3",
      title: "Login Over HTTPS",
      contentTitle: "Login Over HTTPS",
      data: httpsFilterOptions,
      data_key: "https",
    },
    {
      eventKey: "4",
      title: "Login Over HTTP",
      contentTitle: "Login Over HTTP",
      data: httpFilterOptions,
      data_key: "http",
    },
    {
      eventKey: "7",
      title: "Current State",
      contentTitle: "Current State",
      data: webDataFillter("7"),
      data_key: "current_state",
    },
  ];
};

export const wafTabsConfig = ({
  websites = [],
  wafsFilterOptions = [],
  wafNames = [],
  manufacturers = [],
}) => {
  return [
    {
      eventKey: "1",
      title: "Hostname",
      contentTitle: "Hostname",
      data: websites,
      data_key: "host",
    },

    {
      eventKey: "3",
      title: "WAF Indication",
      contentTitle: "WAF Indication",
      data: wafsFilterOptions,
      data_key: "has_waf",
    },
    {
      eventKey: "2",
      title: "WAF Name",
      contentTitle: "WAF Name",
      data: Array.from(wafNames),
      data_key: "waf_name",
    },
    {
      eventKey: "4",
      title: "WAF Manufacturer",
      contentTitle: "WAF Manufacturer",
      data: Array.from(manufacturers),
      data_key: "manufacturer",
    },
    {
      eventKey: "6",
      title: "Current State",
      contentTitle: "Current State",
      data: webDataFillter("6"),
      data_key: "current_state",
    },
  ];
};

export const cmsTabsConfig = ({
  hostname = [],
  hasCMSFilter = [],
  cmsName = [],
}) => {
  return [
    {
      eventKey: "1",
      title: "Hostname",
      contentTitle: "Hostname",
      data: Array.from(hostname),
      data_key: "host",
    },
    {
      eventKey: "2",
      title: "CMS",
      contentTitle: "CMS",
      data: Array.from(hasCMSFilter),
      data_key: "has_cms",
    },
    {
      eventKey: "3",
      title: "CMS Name",
      contentTitle: "CMS Name",
      data: Array.from(cmsName),
      data_key: "cms_name",
    },
    {
      eventKey: "6",
      title: "Current State",
      contentTitle: "Current State",
      data: webDataFillter("6"),
      data_key: "current_state",
    },
  ];
};

export const cdnTabsConfig = ({
  hostname = [],
  hasCDNFilter = [],
  cdnName = [],
}) => {
  return [
    {
      eventKey: "1",
      title: "Hostname",
      contentTitle: "Hostname",
      data: Array.from(hostname),
      data_key: "host",
    },
    {
      eventKey: "2",
      title: "CDN",
      contentTitle: "CDN",
      data: Array.from(hasCDNFilter),
      data_key: "has_cdn",
    },
    {
      eventKey: "3",
      title: "CDN Name",
      contentTitle: "CDN Name",
      data: Array.from(cdnName),
      data_key: "cdn_name",
    },
    {
      eventKey: "6",
      title: "Current State",
      contentTitle: "Current State",
      data: webDataFillter("6"),
      data_key: "current_state",
    },
  ];
};

export const dataLeaksTabsConfig = ({
  hosts = [],
  emails = [],
  PasswordFilter = [],
  hashFilter = [],
  database = [],
}) => {
  return [
    {
      eventKey: "1",
      title: "Domain",
      contentTitle: "Domain",
      data: Array.from(hosts),
      data_key: "host",
    },
    {
      eventKey: "2",
      title: "Email",
      contentTitle: "Email",
      data: Array.from(emails),
      data_key: "email",
    },
    {
      eventKey: "4",
      title: "Password",
      contentTitle: "Password",
      data: Array.from(PasswordFilter),
      data_key: "password",
    },
    {
      eventKey: "5",
      title: "Hash",
      contentTitle: "Hash",
      data: Array.from(hashFilter),
      data_key: "hashed_password",
    },
    {
      eventKey: "3",
      title: "Leaked Database",
      contentTitle: "Leaked Database",
      data: Array.from(database),
      data_key: "database_name",
    },
    {
      eventKey: "6",
      title: "Current State",
      contentTitle: "Current State",
      data: webDataFillter("6"),
      data_key: "current_state",
    },
  ];
};


export const sensitiveTabsConfig = ({
  categories = [],
  hostName = [],
  urlName = [],
  titleName = [],
  descriptionName = [],
}) => {
  return [
    {
      eventKey: "1",
      title: "Category",
      contentTitle: "Category",
      data: Array.from(categories),
      data_key: "name",
    },
    {
      eventKey: "2",
      title: "Hostname",
      contentTitle: "Hostname",
      data: Array.from(hostName),
      data_key: "host",
    },
    {
      eventKey: "3",
      title: "Url",
      contentTitle: "Url",
      data: Array.from(urlName),
      data_key: "url",
    },
    {
      eventKey: "4",
      title: "Title",
      contentTitle: "Title",
      data: Array.from(titleName),
      data_key: "title",
    },
    {
      eventKey: "5",
      title: "Description",
      contentTitle: "Description",
      data: Array.from(descriptionName),
      data_key: "description",
    },
    {
      eventKey: "6",
      title: "Current State",
      contentTitle: "Current State",
      data: webDataFillter("6"),
      data_key: "current_state",
    },
  ];
};

export const newAssetDiscoveryTabsConfig = ({
  domains = [],
  hostnames = [],
  ips = [],
  hosting = [],
  cdn = [],
}) => {
  return [
    {
      eventKey: "1",
      title: "Domain",
      contentTitle: "Domain",
      data: Array.from(domains),
      data_key: "root_domain",
    },
    {
      eventKey: "2",
      title: "Hostname",
      contentTitle: "Hostname",
      data: Array.from(hostnames),
      data_key: "subdomain",
    },
    {
      eventKey: "3",
      title: "IP Address",
      contentTitle: "IP Address",
      data: Array.from(ips),
      data_key: "ip",
    },
    {
      eventKey: "4",
      title: "Hosting Provider",
      contentTitle: "Hosting Provider",
      data: Array.from(hosting),
      data_key: "hosting",
    },
    {
      eventKey: "5",
      title: "CDN",
      contentTitle: "CDN",
      data: Array.from(cdn),
      data_key: "cdn",
    },
  ];
};