import React, { useEffect, useState, useContext, useRef } from "react";
import BaseTable from "../../../../components/table/BaseTable";
import BaseFilter from "../../../../components/filter/BaseFilter";
import GenericCard from "../../../../components/card/GenericCard";
import WebScanner from "../../../../components/web-scanner/WebScanner";
import { useParams, useOutletContext } from "react-router-dom";
import { ThreeCircles } from "react-loader-spinner";
import WebUpdatesHorizontal from "../../../../components/website-updates/WebUpdatesHorizontal";
import { ReactComponent as HashGenericIcon } from "../../../../assets/images/hash-generic-icon.svg";
import { ReactComponent as EmailIcon } from "../../../../assets/images/email-icon.svg";
import { ReactComponent as GlobeIcon } from "../../../../assets/images/globe-icon.svg";
import { ReactComponent as KeyIcon } from "../../../../assets/images/key-icon.svg";
import CurrentStatus from "../../../../components/status/CurrentStatus.js";
import moment from "moment";
import { OverlayTrigger } from "react-bootstrap";
import Popover from "react-bootstrap/Popover";
import { PasswordDataInfo } from "./DataLeaksHelpers.js";
import { HashDataInfo } from "./DataLeaksHelpers.js";
import { AdditionalInfo } from "./DataLeaksHelpers.js";
import {
  webUpdatesFilter,
  webDataFillter,
} from "../../../../util/genericFunctions.js";
import { ScannerContext } from "../../../../components/ScannerContext";
import axios from "../../../../util/axios";
import { formatScannerName } from "../../../../helpers/formatScannerName.js";
import NoData from "../../../../components/empty/NoData";
import { showToast, showUpdateToast } from "../../../../util/toasts.js";
import { parseFilterString, applyCondition } from "../../../../util/conditions";
import { dataLeaksTabsConfig } from "../../../../util/tabsConfig.js";

const DataLeaks = () => {
  const { scanningStatus } = useOutletContext();
  const [searchValue, setSearchValue] = useState("");
  const { dropdownItems } = useContext(ScannerContext);
  const [isLoading, setIsLoading] = useState(false);
  const [isDataInProcess, setIsDataInProcess] = useState(false);
  const [isDataisPartiallyProcessing, setIsDataisPartiallyProcessing] =
    useState(false);
  const [dataLeaks, setDataLeaks] = useState([]);
  const routeParams = useParams();
  const [webUpdatesData, setWebUpdatesData] = useState(null);
  const [activeFilters, setActiveFilters] = useState([]);
  const [disabledCrackAll, setDisableCrackAll] = useState(true);
  const [statsData, setStatsData] = useState({
    domains: 0,
    emails: 0,
    hashes: 0,
    passwords: 0,
  });
  const [tabs, setTabs] = useState([]);

  const crackHash = async (row) => {
    try {
      const { data } = await axios.post(`dehash`, {
        ids: `${row?.id}`,
      });
      fetchDataLeaks();
    } catch (err) {
      console.error("Error on hashing crack:", err);
    }
  };

  const crackAllHash = async () => {
    const ids = [];
    dataLeaks?.map((el) => {
      if (el?.hashed_password && !el?.password) {
        ids.push(el.id);
      }
    });
    if (ids?.length > 0) {
      const toastId = showToast(
        "Cracking Hashes...",
        "info",
        "top-center",
        "light",
        {
          autoClose: false,
          isLoading: true,
        }
      );
      try {
        const { data } = await axios.post(`dehash`, {
          ids: ids?.join(","),
        });
        let successCount = 0;
        data?.data?.map((el) => {
          if (el?.status == "success") {
            successCount += 1;
          }
        });

        showUpdateToast(
          toastId,
          `${successCount}/${ids?.length} Hashes Cracked`,
          "success",
          false
        );
        fetchDataLeaks();
      } catch (err) {
        showUpdateToast(
          toastId,
          err?.response?.data?.message || "Something Went Wrong.",
          "error",
          false
        );
        console.error("Error on hashing crack:", err);
      }
    } else {
      showToast(
        "All Hashed are already Cracked",
        "error",
        "top-center",
        "light",
        {
          autoClose: 2500,
        }
      );
    }
  };

  const handleSearchChange = (e) => {
    setSearchValue(e.target.value);
  };

  const PasswordFilter = [
    {
      id: 0,
      name: "Found",
      type: "Password: Found",
      key: "4",
      active: false,
    },
    {
      id: 1,
      name: "Not Found",
      type: "Password: Not Found",
      key: "4",
      active: false,
    },
  ];

  const hashFilter = [
    {
      id: 0,
      name: "Found",
      type: "Hash: Found",
      key: "5",
      active: false,
    },
    {
      id: 1,
      name: "Not Found",
      type: "Hash: Not Found",
      key: "5",
      active: false,
    },
  ];

  const updateTabsWithData = (data) => {
    const hosts = Array.from(new Set(data.map((item) => item?.host))).map(
      (name) => ({
        id: name,
        name: name,
        type: "Domain: " + name,
        key: "1",
        active: false,
      })
    );

    const emails = Array.from(new Set(data.map((item) => item?.email))).map(
      (name) => ({
        id: name,
        name: name,
        type: "Email: " + name,
        key: "2",
        active: false,
      })
    );

    const database = Array.from(
      new Set(data.map((item) => item?.database_name))
    ).map((name) => ({
      id: name,
      name: name,
      type: "Leaked Database: " + name,
      key: "3",
      active: false,
    }));
    setTabs(
      dataLeaksTabsConfig({
        hosts,
        emails,
        PasswordFilter,
        hashFilter,
        database,
      })
    );
  };

  const getExportedRows = (dataForExport) => {
    const body = dataForExport.flatMap((data) => {
      const domain = data?.host || "";
      const email = data?.email || "";
      const password = data?.password || "";
      const hashed_password = data?.hashed_password || "";
      const database_name = data?.database_name || "";
      const breach_date =
        data?.breach_date && data?.breach_date !== "-"
          ? moment(data?.breach_date).format("DD/MM/YY")
          : "-";
      let info = [];
      if (data?.ip_address) {
        info.push(`IP: ${data.ip_address}`);
      }
      if (data?.phone) {
        info.push(`Phone: ${data.phone}`);
      }
      if (data?.first_name) {
        info.push(`First Name: ${data.first_name}`);
      }
      if (data?.last_name) {
        info.push(`Last Name: ${data.last_name}`);
      }
      if (data?.username) {
        info.push(`Username: ${data.username}`);
      }
      return [
        [
          domain,
          email,
          password,
          hashed_password,
          database_name,
          breach_date,
          info?.length > 0 ? info?.join(", ") : "-",
        ],
      ];
    });

    return body;
  };

  const columns = [
    { Header: "Domain", accessor: "host", isSortable: true },
    {
      Header: "Email",
      accessor: (row) => (
        <>
          <OverlayTrigger
            placement="top"
            overlay={
              <Popover
                id={`tooltip-${row.email}`}
                className="custom-popover-arrow"
              >
                <Popover.Body className="comming-tool-body">
                  <label className="comming-text-tool">{row.email}</label>
                </Popover.Body>
              </Popover>
            }
          >
            <div
              style={{
                maxWidth: "160px",
                overflow: "hidden",
                whiteSpace: "nowrap",
                textOverflow: "ellipsis",
              }}
            >
              {row?.email}
            </div>
          </OverlayTrigger>
        </>
      ),
      isSortable: true,
      key: "email",
    },
    {
      Header: "Password",
      accessor: (row) => <PasswordDataInfo rowData={row} />,
      isSortable: true,
      key: "password",
    },
    {
      Header: "Hash",
      accessor: (row) => <HashDataInfo rowData={row} onCrack={crackHash} />,
      isSortable: true,
      key: "hashed_password",
    },
    {
      Header: "Leaked Database",
      isSortable: true,
      accessor: "database_name",
      key: "database_name",
    },
    {
      Header: "Leaked Database Date",
      accessor: (row) => (
        <div>
          {row?.breach_date && row?.breach_date !== "-"
            ? moment(row?.breach_date).format("DD/MM/YY")
            : "-"}
        </div>
      ),
      isSortable: true,
      key: "breach_date",
    },
    {
      Header: "Additional Info",
      accessor: (row) => <AdditionalInfo info={row} />,
      isSortable: true,
      key: "hasAdditionInfo",
    },
    {
      Header: "First Detected",
      key: "first_detected",
      isSortable: true,
      accessor: (row) => {
        return (
          <div>
            {row.first_detected
              ? moment(row.first_detected).format("DD/MM/YY")
              : "-"}
          </div>
        );
      },
    },
    {
      Header: "Current State",
      key: "current_state",
      isSortable: true,
      accessor: (row) => {
        return (
          <div>
            {row.change_status != null ? (
              <CurrentStatus
                status={row.change_status}
                tooltipInfo={row.changes}
                headerKeys={{
                  domain: "Domain",
                  email: "Email",
                  pass: "Password",
                  hash: "Hash",
                  leaked_database: "Leaked Database",
                  leaked_database_date: "Leaked Database Date",
                  first_detected: "First Detected",
                  change_status: "Current State",
                }}
              />
            ) : (
              "-"
            )}
          </div>
        );
      },
    },
  ];

  const handleFilterChange = (updatedActiveFilters, updatedTabs) => {
    setActiveFilters(updatedActiveFilters);
    setTabs(updatedTabs);
  };

  const removeFilter = (updatedFilters, updatedTabs) => {
    setActiveFilters(updatedFilters);
    setTabs(updatedTabs);
  };

  const handleWebUpdateSort = (name) => {
    setActiveFilters(webUpdatesFilter(name, activeFilters));
  };

  const filtersStatsCard = (name, eventKey, filterType) => {
    setActiveFilters(
      webUpdatesFilter(name, activeFilters, eventKey, filterType)
    );
  };

  const statusMap = { new: 1, changed: 2, unchanged: 0 };

  const fetchDataLeaks = async () => {
    try {
      const { data } = await axios.get(
        `scans/${routeParams?.target_id}/dataleaks`
      );
      setStatsData({
        domains: data?.countGroup?.domains,
        emails: data?.countGroup?.emails,
        hashes: data?.countGroup?.hashes,
        passwords: data?.countGroup?.passwords,
      });
      let unChanged = 0;
      let newUpdates = 0;
      let disabledCrackAll_ = true;
      const filteredData = data?.data?.map((el) => {
        const fullName = el?.name;
        const nameParts = fullName?.split(" ");
        const firstName = nameParts?.[0];
        const lastName = nameParts?.slice(1)?.join(" ");
        if (el?.hashed_password && !el?.password) {
          disabledCrackAll_ = false;
        }
        if (el?.current_status === 2) {
          unChanged++;
        }
        if (el?.current_status === 1) {
          newUpdates++;
        }
        let hasAdditionInfo = false;
        if (el?.ip_address) {
          hasAdditionInfo = true;
        }
        if (el?.phone) {
          hasAdditionInfo = true;
        }
        if (el?.first_name) {
          hasAdditionInfo = true;
        }
        if (el?.last_name) {
          hasAdditionInfo = true;
        }
        if (el?.username) {
          hasAdditionInfo = true;
        }
        return {
          ...el,
          hasAdditionInfo,
          change_status: el?.current_status === 2 ? 0 : el?.current_status,
          first_name: firstName,
          last_name: lastName,
        };
      });
      setWebUpdatesData({
        changed: 0,
        constant: unChanged,
        new: newUpdates,
        removed: 0,
      });
      if (
        data?.data?.length == 0 &&
        (scanningStatus === 2 || scanningStatus === 1)
      ) {
        setIsDataInProcess(true);
      } else {
        setIsDataInProcess(false);
      }
      setDisableCrackAll(disabledCrackAll_);
      if (filteredData?.length > 0) {
        setDataLeaks(filteredData);
        updateTabsWithData(filteredData);
      }
      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
      setIsDataInProcess(true);
    }
  };

  const fetchIntervalRef = useRef();
  useEffect(() => {
    clearInterval(fetchIntervalRef.current);

    if (scanningStatus === 0) {
      setIsLoading(false);
      setIsDataInProcess(true);
    } else {
      setIsDataInProcess(false);
      if (
        scanningStatus === 3 ||
        scanningStatus === -1 ||
        scanningStatus === 4
      ) {
        setIsDataisPartiallyProcessing(false);
        setIsLoading(true);
        fetchDataLeaks();
      } else if (scanningStatus === 2 || scanningStatus === 1) {
        setIsDataisPartiallyProcessing(true);
        setIsLoading(true);
        fetchDataLeaks();
        fetchIntervalRef.current = setInterval(fetchDataLeaks, 5000);
      }
    }
    return () => {
      clearInterval(fetchIntervalRef.current);
    };
  }, [scanningStatus, routeParams?.target_id]);

  const groupedFilters = activeFilters.reduce((acc, filter) => {
    if (!acc[filter.eventKey]) {
      acc[filter.eventKey] = [];
    }
    acc[filter.eventKey].push(filter);
    return acc;
  }, {});

  const filteredData = dataLeaks.filter((leaks) => {
    const matchesFilters = Object.keys(groupedFilters).every((eventKey) => {
      return groupedFilters[eventKey].some((filter) => {
        const lowerCaseFilter = filter.name.toLowerCase();
        if (eventKey === "1") {
          return leaks?.host && leaks?.host.toLowerCase() === lowerCaseFilter;
        } else if (eventKey === "2") {
          return leaks?.email && leaks?.email == lowerCaseFilter;
        } else if (eventKey === "3") {
          return (
            leaks?.database_name &&
            leaks?.database_name.toLowerCase() === lowerCaseFilter
          );
        } else if (eventKey === "6") {
          return leaks?.change_status === statusMap[filter.name.toLowerCase()];
        } else if (eventKey === "4") {
          return (
            (lowerCaseFilter === "found" && leaks.password) ||
            (lowerCaseFilter === "not found" && !leaks.password)
          );
        } else if (eventKey === "5") {
          return (
            (lowerCaseFilter === "found" && leaks.hashed_password) ||
            (lowerCaseFilter === "not found" && !leaks.hashed_password)
          );
        } else if (eventKey === "advanced-filter") {
          const parsedFilters = parseFilterString(filter.name);
          return parsedFilters.every((ol) => {
            const { column, condition, value } = ol;
            switch (column) {
              case "host":
                return applyCondition(
                  leaks.host?.toLowerCase(),
                  condition,
                  value?.toLowerCase()
                );
              case "email":
                return applyCondition(
                  leaks.email?.toLowerCase(),
                  condition,
                  value?.toLowerCase()
                );
              case "database_name":
                return applyCondition(
                  leaks.database_name?.toLowerCase(),
                  condition,
                  value?.toLowerCase()
                );
              case "password":
                return (
                  (value?.toLowerCase() === "found" && leaks.password) ||
                  (value?.toLowerCase() === "not found" && !leaks.password)
                );
              case "hashed_password":
                return (
                  (value?.toLowerCase() === "found" && leaks.hashed_password) ||
                  (value?.toLowerCase() === "not found" &&
                    !leaks.hashed_password)
                );
              case "current_state":
                return applyCondition(
                  leaks?.change_status,
                  condition,
                  statusMap[value.toLowerCase()]
                );

              default:
                return false;
            }
          });
        }
        return false;
      });
    });

    const matchesSearch =
      searchValue === "" ||
      (leaks?.domain &&
        leaks?.domain.toLowerCase().includes(searchValue.toLowerCase())) ||
      (leaks?.database_name &&
        leaks?.database_name
          .toLowerCase()
          .includes(searchValue.toLowerCase())) ||
      (leaks?.email &&
        leaks?.email
          .toString()
          .toLowerCase()
          .includes(searchValue.toLowerCase()));

    return matchesFilters && matchesSearch;
  });

  return (
    <>
      {isLoading ? (
        <div className="content-loader">
          <ThreeCircles
            visible={true}
            height="60"
            width="60"
            color="#ffff"
            ariaLabel="three-circles-loading"
            wrapperClass=""
          />
        </div>
      ) : (
        <div className="main_container_style">
          <div className="pb-4 row top-container-main">
            <div className="col-12 col-lg-6 h-100">
              <div className="row gap-4 top-container-main-row">
                <div className="col-12 h-75">
                  <div className="row h-100">
                    <div className="col-6 mb-4 data-leaks">
                      <GenericCard
                        children={
                          isDataInProcess ? (
                            <NoData />
                          ) : (
                            <div className="flex-col gap-1 flex align-items-center">
                              <GlobeIcon className="mb-2" />
                              <div className="data-leaks-card-text">
                                Domains
                              </div>
                              <div className="data-leaks-card-value">
                                {statsData?.domains || 0}
                              </div>
                            </div>
                          )
                        }
                      />
                    </div>
                    <div className="col-6 mb-4 data-leaks">
                      <GenericCard
                        children={
                          isDataInProcess ? (
                            <NoData />
                          ) : (
                            <div className="flex-col gap-1 flex align-items-center">
                              <EmailIcon className="mb-2" />
                              <div className="data-leaks-card-text">Emails</div>
                              <div className="data-leaks-card-value">
                                {statsData?.emails || 0}
                              </div>
                            </div>
                          )
                        }
                      />
                    </div>
                    <div className="col-6 data-leaks">
                      <GenericCard
                        children={
                          isDataInProcess ? (
                            <NoData />
                          ) : (
                            <div className="flex-col gap-1 flex align-items-center">
                              <KeyIcon className="mb-2" />
                              <div className="data-leaks-card-text">
                                Passwords
                              </div>
                              <div
                                className="data-leaks-card-value"
                                style={{
                                  cursor:
                                    statsData?.passwords !== 0
                                      ? "pointer"
                                      : "initial",
                                  textDecoration:
                                    statsData?.passwords !== 0
                                      ? "underline"
                                      : "none",
                                }}
                                onClick={() => {
                                  if (statsData?.passwords) {
                                    filtersStatsCard("Found", "4", "Password");
                                  }
                                }}
                              >
                                {statsData?.passwords || 0}
                              </div>
                            </div>
                          )
                        }
                      />
                    </div>
                    <div className="col-6 data-leaks">
                      <GenericCard
                        children={
                          isDataInProcess ? (
                            <NoData />
                          ) : (
                            <div className="flex-col gap-1 flex align-items-center">
                              <HashGenericIcon className="mb-2" />
                              <div className="data-leaks-card-text">Hashes</div>
                              <div
                                className="data-leaks-card-value"
                                style={{
                                  cursor:
                                    statsData?.hashes !== 0
                                      ? "pointer"
                                      : "initial",
                                  textDecoration:
                                    statsData?.hashes !== 0
                                      ? "underline"
                                      : "none",
                                }}
                                onClick={() => {
                                  if (statsData?.hashes) {
                                    filtersStatsCard("Found", "5", "Hash");
                                  }
                                }}
                              >
                                {statsData?.hashes || 0}
                              </div>
                            </div>
                          )
                        }
                      />
                    </div>
                  </div>
                </div>
                <div className="col-12 h-25">
                  <div className="row h-100">
                    <div className="col-12">
                      <GenericCard
                        children={
                          <WebUpdatesHorizontal
                            data={webUpdatesData}
                            onSort={handleWebUpdateSort}
                            dataInProcess={
                              isDataInProcess || isDataisPartiallyProcessing
                            }
                          />
                        }
                        title={"Updates"}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12 col-lg-6 h-100">
              <WebScanner title={"Data Leaks Scanner"} />
            </div>
          </div>

          <div className="pb-5 pt-3">
            <div>
              <BaseFilter
                onFilterChange={handleFilterChange}
                removeFilter={removeFilter}
                activeFilters={activeFilters}
                tabs={tabs}
                className="mt-3"
                totalRecords={filteredData.length}
                exportTitle={`DataLeaks_${formatScannerName(
                  dropdownItems.filter((item) => {
                    return item.target_uuid === routeParams?.target_id;
                  })[0]?.title
                )}-${moment().format("DDMMMYYYY").toUpperCase()}`}
                searchValue={searchValue}
                onSearchChange={handleSearchChange}
                isSearch={true}
                isDatainProcess={isDataInProcess || isDataisPartiallyProcessing}
                exportRows={getExportedRows(filteredData)}
                exportHeader={[
                  "Domain",
                  "Email",
                  "Password",
                  "Hash",
                  "Leaked Database",
                  "Leaked Database Date",
                  "Additional Info",
                ]}
                isCrackHash
                handleHashCrack={crackAllHash}
                disableExport={filteredData?.length > 0 ? false : true}
                disabledCrackAll={disabledCrackAll}
                tableData={filteredData}
              />
            </div>
            <div>
              <div className="data-leaks-table  custom-x-scroll-table">
                <BaseTable
                  className="mt-3 mb-3"
                  columns={columns}
                  data={filteredData}
                  selectable={true}
                  showCheckboxes={false}
                  action={false}
                  isDatainProcess={
                    isDataInProcess || isDataisPartiallyProcessing
                  }
                  loading={isLoading}
                />
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default DataLeaks;
