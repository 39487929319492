import React, { useContext, useMemo } from "react";
import BaseFilter from "../../components/filter/BaseFilter";
import BulkSelection from "../bulk-selection/BulkSelection.js";
import { ScannerContext } from "../../components/ScannerContext";
import { formatScannerName } from "../../helpers/formatScannerName.js";
import moment from "moment";
import { useParams } from "react-router-dom";

const OptionsSection = ({
  cves,
  filteredCves,
  removeFilter,
  tabs,
  handleFilterChange,
  activeFilters,
  handleSortChange,
  searchValue,
  handleSearchChange,
  selectedSortValue,
  isChecked,
  selectedItems,
  onClearSelectRow,
  handleActionClick,
  actions,
  handleSelectionAll,
}) => {
  const { id } = useParams();
  const { dropdownItems } = useContext(ScannerContext);
  let maxRemediation = 0;

  // First pass to determine the maximum number of redemptions
  filteredCves.forEach((el) => {
    const remediationCount = el.remediation
      ? el.remediation.split("|").length
      : 0;
    maxRemediation = Math.max(maxRemediation, remediationCount);
  });
  const exportHeaders = [
    "Security Issue",
    "Asset",
    "Category",
    "Impact",
    "Exploitability",
    "CVSS",
    "EPSS",
    "Risk Score",
    "Summary",
    "Explanation",
  ];

  for (let i = 1; i <= maxRemediation; i++) {
    exportHeaders.push(i === 1 ? "Remediation" : `Remediation#${i}`);
  }
  const exportRows = useMemo(() => {
    return filteredCves.flatMap((el) => {
      // Determine the assets based on whether the switch is checked or not
      const assets = isChecked
        ? el.assets?.length > 0
          ? el.assets.map((asset) => {
              return asset?.host
                ? asset?.host
                : asset?.domain
                ? asset?.domain
                : asset.ip && asset.port
                ? `${asset.ip} Port: ${asset.port}`
                : asset.ip
                ? asset.ip
                : "-";
            })
          : [""]
        : // If isChecked is false, directly use the properties of `el` object
          [
            el.ip && el.port
              ? `${el.ip} Port: ${el.port}`
              : el?.host
              ? el?.host
              : el?.domain
              ? el?.domain
              : el.ip
              ? el.ip
              : "-",
          ];

      const remediations = el.remediation
        ? el.remediation
            .replace(
              "Please see the references for more resources supporting you with this task.",
              ""
            )
            .replace("Please see the references for more information.", "")
            .replace(/^\s+|\s+$/g, "")
            .split("|")
            .map((i) => `"${i.trim()}"`)
        : ["No Remediation found."];

      return assets.map((asset) => {
        return [
          el.issue_name,
          asset, // This will be the formatted string from the logic above
          el.scan_category,
          el.impact + "%",
          el?.exploitability + "%",
          el?.cvss,
          el?.epss,
          el.risk_score + "%",
          el.summary ? el.summary.replace(/,/g, "") : "",
          `${el.explanation.replace(/,/g, "") || ""}`,
          ...remediations,
        ];
      });
    });
  }, [filteredCves, isChecked]);
  const handleClearSelection = () => {
    if (onClearSelectRow) {
      onClearSelectRow();
    }
  };

  const handleMultipleAction = (data) => {
    handleActionClick(data.name, selectedItems, 2);
  };

  return (
    <React.Fragment>
      <div style={{ margin: "10px -22px 0px -8px" }}>
        <BaseFilter
          tabs={tabs}
          onFilterChange={handleFilterChange}
          activeFilters={activeFilters}
          removeFilter={removeFilter}
          className="mb-3"
          totalRecords={cves?.length}
          tableData={filteredCves}
          exportHeader={exportHeaders}
          exportTitle={`SecurityIssues_${formatScannerName(
            dropdownItems.filter((item) => {
              return item.target_uuid === id;
            })[0]?.title
          )}-${moment().format("DDMMMYYYY").toUpperCase()}`}
          exportRows={exportRows}
          showSortBy={true}
          handleSortChange={handleSortChange}
          options={[
            {
              label: "Risk Score High to Low",
              value: "rhl",
            },
            {
              label: "Risk Score Low to High",
              value: "rlh",
            },
            {
              label: "Impact Score High to Low",
              value: "ihl",
            },
            {
              label: "Impact Score Low to High",
              value: "ilh",
            },
            {
              label: "Exploitability Score High to Low",
              value: "ehl",
            },
            {
              label: "Exploitability Score Low to High",
              value: "elh",
            },

            {
              label: "CVSS High to Low",
              value: "chl",
            },
            {
              label: "CVSS Low to High",
              value: "clh",
            },
            {
              label: "EPSS High to Low",
              value: "ephl",
            },
            {
              label: "EPSS Low to High",
              value: "eplh",
            },
          ]}
          selectedSortValue={selectedSortValue}
          searchValue={searchValue}
          onSearchChange={handleSearchChange}
          isSearch={true}
          multiSelect={selectedItems.length}
          actions={actions}
          multiselectLabel={"Issues"}
          onClearSelectRow={handleClearSelection}
          onActionSelect={handleMultipleAction}
        />
      </div>
      {selectedItems.length > 0 && (
        <>
          <div>
            <BulkSelection
              actions={actions}
              onClearSelectRow={handleClearSelection}
              onActionSelect={handleMultipleAction}
              multiselectLabel={"Issues"}
              multiSelect={selectedItems.length}
              totalRecords={cves?.length}
              handleSelectionAll={handleSelectionAll}
            />
          </div>
        </>
      )}
    </React.Fragment>
  );
};

export default OptionsSection;
