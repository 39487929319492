import React from "react";
import { ThreeCircles } from "react-loader-spinner";

const ConfirmationModal = ({
  isOpen,
  onClose,
  onConfirm,
  domain,
  selectedDomains,
  isDomainLoading,
}) => {
  if (!isOpen) return null;

  const domainsToDisplay =
    selectedDomains.length > 0 ? selectedDomains : domain ? [domain] : [];

  return (
    <div className="asset-modal-overlay">
      <div className="asset-modal-content">
        <div className="domain-header">
          <h4>
            {domainsToDisplay.length > 1
              ? `Are you sure you want to scan ${domainsToDisplay.length} selected domains?`
              : `Are you sure you want to scan ${domainsToDisplay[0]} domain?`}
          </h4>
        </div>
        <p>
          {domainsToDisplay.length > 1
            ? `These domains will be scanned for related assets, which will automatically be added to the asset inventory.`
            : `This domain will be scanned for related assets, which will automatically be added to the asset inventory.`}
        </p>

        {domainsToDisplay.length > 1 && <hr />}

        {domainsToDisplay.length > 1 && (
          <div
            className="domain-list"
            style={{ maxHeight: "150px", overflowY: "scroll" }}
          >
            {domainsToDisplay.map((d) => (
              <div className="list-domains" key={d}>
                {d}
              </div>
            ))}
          </div>
        )}

        <div className="asset-modal-actions">
          <button className="btn asset-modal-button" onClick={onClose}>
            Cancel
          </button>
          <button
            className="btn asset-modal-button"
            onClick={() => onConfirm(domainsToDisplay)}
            disabled={isDomainLoading}
          >
            {isDomainLoading ? (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <ThreeCircles
                  visible={true}
                  height="20"
                  width="20"
                  color="#398ffe"
                  ariaLabel="three-circles-loading"
                  wrapperClass=""
                />
              </div>
            ) : (
              "Scan"
            )}
          </button>
        </div>
      </div>
    </div>
  );
};

export default ConfirmationModal;
