import React from "react";
function UtilizationBanner({ SubTitle, Title, Icon }) {
  return (
    <>
    <div className="banner">
      <div className="d-flex align-items-start toast-header border-0">
        <div className="me-2 mt-1">
          {Icon} 
        </div>
        <div className="me-auto">
          <p className="banner-title fs-5 fw-600 mb-0">{Title}</p>
          <small className="banner-title">{SubTitle}</small>
        </div>
      </div>
    </div>
    </> 
  );
}
export default UtilizationBanner;