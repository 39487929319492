import React, { useEffect, useState } from "react";
import {
  Button,
  Col,
  Container,
  FloatingLabel,
  FormControl,
  FormGroup,
  Row,
} from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { Formik, Form, Field } from "formik";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "../../../util/axios";
import { detectCountry } from "../../../util/country";
import { showToast } from "../../../util/toasts";

const validate = (values) => {
  const errors = {};

  if (!values.email) {
    errors.email = "Please enter valid email.";
  }

  if (!values.organization_id) {
    errors.organization_id = "Please Select An Organization.";
  }

  if (
    values.email &&
    !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)
  ) {
    errors.email = "Invalid email address";
  }

  return errors;
};

const InvitationForm = ({ allOrganizations }) => {
  const navigate = useNavigate();
  const [country, setCountry] = useState("");
  const [submitBtn, setSubmitBtn] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  let API_ENDPOINT_NODE = "/invite";
  const initialValues = {
    email: "",
    organization_id: "",
  };

  const init = async () => {
    setCountry(await detectCountry());
  };

  useEffect(() => {
    //passing getData method to the lifecycle method
    init();
  }, []);
  const handleSubmit = (values, helpers) => {
    setIsLoading(true);
    console.log(values);

    axios
      .post(API_ENDPOINT_NODE, values)
      .then((response) => {
        setIsLoading(false);
        const data = response.data;
        helpers.resetForm({
          values: {
            email: "",
            organization_id: "",
          },
        });

        showToast(data.message, "success", "top-center", "light");

        // setTimeout(() => {
        //   navigate("/auth/otp/" + data.code);
        // }, 500);
      })
      .catch((e) => {
        setIsLoading(false);
        if (e.response.status === 422) {
          for (const fieldKey in e.response.data) {
            const field = e.response.data[fieldKey];
            helpers.setFieldError(field.path, field.msg);
          }
        }
      });
  };

  let userRoles = [{id:0, name: "Normal User"}, {id:1, name: "Distributor"}, {id:9, name: "Admin"}]
  useEffect(() => {
    if (isLoading) {
      setSubmitBtn({ disabled: true, label: "Sending Invite.." });
    } else {
      setSubmitBtn({ disabled: false, label: "Send Invite" });
    }
  }, [isLoading]);
  return (
    <React.Fragment>
      <Container className="max-80 my-5 invitation-form-set">
        <Row>
          <Col>
            <h2 className="my-5 text-center">Send Invitation</h2>
            <Formik
              validate={validate}
              initialValues={initialValues}
              onSubmit={handleSubmit}
              render={({
                handleChange,
                handleSubmit,
                handleBlur,
                values,
                errors,
                validateForm,
              }) => (
                <Form className="form-height-set">
                  {/* Email Field */}
                  <Field
                    name="email"
                    render={({ field, form: { touched, errors }, meta }) => (
                      <FormGroup controlId="email" className="my-3">
                        <FloatingLabel
                          controlId="floatingPassword"
                          label="Enter invitation email"
                        >
                          <FormControl
                            type={"text"}
                            size="lg"
                            value={field.value}
                            onChange={field.onChange}
                            placeholder="John Doe"
                            className={
                              meta.touched && meta.error ? "is-invalid" : ""
                            }
                            {...field}
                          />
                          {meta.touched && meta.error ? (
                            <div className="invalid-feedback">{meta.error}</div>
                          ) : null}
                        </FloatingLabel>
                      </FormGroup>
                    )}
                  />

                  

                  {/* Organization Name Field */}
                  <Field name="organization_id">
                    {({ field, form: { touched, errors }, meta }) => (
                      <FormGroup controlId="organization_id" className="my-3">
                        <FloatingLabel label="Select organization name">
                          <FormControl
                            as="select"
                            size="lg"
                            {...field}
                            value={field.value}
                            className={`form-control-height ${
                              meta.touched && meta.error ? "is-invalid" : ""
                            }`}
                          >
                            <option value="">Select an organization</option>
                            {allOrganizations?.map((org) => (
                              <option key={org.id} value={org.id}>
                                {org.org_name}
                              </option>
                            ))}
                          </FormControl>
                          {meta.touched && meta.error ? (
                            <div className="invalid-feedback">{meta.error}</div>
                          ) : null}
                        </FloatingLabel>
                      </FormGroup>
                    )}
                  </Field>

                  <Field name="user_role">
                    {({ field, form: { touched, errors }, meta }) => (
                      <FormGroup controlId="user_role" className="my-3">
                        <FloatingLabel label="Select user role">
                          <FormControl
                            as="select"
                            size="lg"
                            {...field}
                            value={field.value}
                            className={`form-control-height ${meta.touched && meta.error ? "is-invalid" : ""}`}
                          >
                            <option value="">Select a user role</option>
                            {userRoles?.map((role) => (
                              <option key={role.id} value={role.id}>
                                {role.name}
                              </option>
                            ))}
                          </FormControl>
                          {meta.touched && meta.error ? (
                            <div className="invalid-feedback">{meta.error}</div>
                          ) : null}
                        </FloatingLabel>
                      </FormGroup>
                    )}
                  </Field>

                  {/* Referral Code */}
                  <Button
                    type="submit"
                    className="btn bg-primary btn-full my-3"
                    id="singup-btn"
                    disabled={submitBtn.disabled}
                  >
                    {submitBtn.label}
                  </Button>
                </Form>
              )}
            />
          </Col>
        </Row>
      </Container>
    </React.Fragment>
  );
};

export default InvitationForm;
