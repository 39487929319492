const GenericDataDisplay = ({ login, password }) => {
    return (
      <div>
        Username:{" "}
        <b style={{ color: "#bcf4de" }}>{login}</b>{" "}
        Password:{" "}
        <b style={{ color: "#bcf4de" }}>{password}</b>
      </div>
    );
  };
  
  export default GenericDataDisplay;
  