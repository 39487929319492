import React, { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import { Navbar, Nav } from "react-bootstrap";
import BreadCrumb from "../../components/breadcrumbs/BreadCrumb";
import OpenIssues from "./OpenIssues";

const Cves = () => {
  const location = useLocation();
  const isHistory = location.search.includes("history");
  const [isActiveLink, setIsActiveLink] = useState(
    isHistory ? "History" : "Open Issues"
  );

  const [scanningDetails, setScanningDetails] = useState({
    status: null,
    date: "",
  });

  const updateScanningDetails = (details) => {
    setScanningDetails(details);
  };

  const updateActiveTab = (tab) => {
    setIsActiveLink(tab);
  };
  useEffect(() => {
    if (isHistory) {
      setIsActiveLink("History");
    }
  }, [location.search]);

  return (
    <React.Fragment>
      <div>
        <BreadCrumb
          breadcrumbItems={["Security Issues"]}
          showScanning={true}
          endTime={scanningDetails?.date || new Date()}
          status={scanningDetails?.status}
          isLastUpdate={true}
        />
        <Navbar className="border-bottom mt-4">
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="me-auto ms-3">
              <Link
                className={`disable-link-color discovery-nav-text text-white ${
                  isActiveLink === "Open Issues" ? "discovery-active-nav-text" : ""
                }`}
                onClick={() => setIsActiveLink("Open Issues")}
              >
                Open Issues
              </Link>
              <Link
                className={`disable-link-color discovery-nav-text text-white mx-4 ${
                  isActiveLink === "History" ? "discovery-active-nav-text" : ""
                }`}
                onClick={() => setIsActiveLink("History")}
              >
                History
              </Link>
            </Nav>
          </Navbar.Collapse>
        </Navbar>
        <OpenIssues
          type={isActiveLink === "History" ? "history" : "open"}
          updateScanningDetails={updateScanningDetails}
          activeTab={updateActiveTab}
        />
      </div>
    </React.Fragment>
  );
};

export default Cves;
