import React from "react";
import ProgressBar from 'react-bootstrap/ProgressBar';

const CustomProgressBar = ({ licenseUtilization }) => {
  const getBarColor = () => {
    if (licenseUtilization < 90) {
      return "#398FFE"; 
    } else if (licenseUtilization >= 90 && licenseUtilization <= 100) {
      return "#FF9F45"; 
    } else {
      return "#FF4A4A"; 
    }
  };

  return (
      <div>
        <ProgressBar 
          className="lincense-bar mt-3 mb-1"
          now={licenseUtilization}
          style={{ backgroundColor: '#575353',borderRadius:'8px' }}  
        >
          <div
            className="progress-bar"
            style={{ backgroundColor: getBarColor(), width: `${licenseUtilization}%` }}
          />
        </ProgressBar>
      </div>
  );
};

export default CustomProgressBar;
