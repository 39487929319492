import React, { useRef, useEffect } from "react";
import { Bar } from "react-chartjs-2";
import "chart.js/auto";
import ChartDataLabels from "chartjs-plugin-datalabels";

const HorizontalBarChart = ({
  HorizontalBarChartdata,
  HorizontalBarChartoptions,
  dynamicLabels,
  onBarClick,
}) => {
  const chartRef = useRef(null);

  const onCanvasClick = (event) => {
    const chart = chartRef.current;
    if (!chart) return;

    const canvasPosition = chart.canvas.getBoundingClientRect();
    const x = event.clientX - canvasPosition.left;
    const y = event.clientY - canvasPosition.top;

    const elements = chart.getElementsAtEventForMode(
      event,
      "nearest",
      { intersect: true },
      false
    );

    if (elements.length > 0) {
      const datasetIndex = elements[0].datasetIndex;
      const elementIndex = elements[0].index;
      if (dynamicLabels && dynamicLabels.length > 1) {
        const selectedLabel = dynamicLabels[elementIndex];
        onBarClick && onBarClick(selectedLabel);
      }
    } else {
      // Handle click on labels
      chart.scales.y.ticks.forEach((tick, index) => {
        const labelYPos = chart.scales.y.getPixelForTick(index);
        const labelHeight = 10; // Adjust this based on your label font size

        if (
          y >= labelYPos - labelHeight / 2 &&
          y <= labelYPos + labelHeight / 2
        ) {
          const selectedLabel = dynamicLabels[index];
          onBarClick && onBarClick(selectedLabel);
        }
      });
    }
  };

  useEffect(() => {
    const chart = chartRef.current;

    if (chart && chart.canvas) {
      chart.canvas.addEventListener("click", onCanvasClick);
    }

    // Cleanup function
    return () => {
      if (chartRef.current && chartRef.current.canvas) {
        chartRef.current.canvas.removeEventListener("click", onCanvasClick);
      }
    };
  }, [dynamicLabels]);

  return (
    <div className="bar-chart-container d-flex align-items-center justify-content-center w-100 h-100">
      <div className="col-12 h-100">
        <Bar
          ref={chartRef}
          data={HorizontalBarChartdata}
          options={HorizontalBarChartoptions}
          plugins={[ChartDataLabels]}
        />
      </div>
    </div>
  );
};

export default HorizontalBarChart;
