import React from "react";
import {useNavigate  } from "react-router-dom";
import { ReactComponent as SuccessIcon } from "../../assets/images/rounded-success.svg";
import { ReactComponent as WarningIcon } from "../../assets/images/rounded-warning.svg";
import { ReactComponent as HighWarningIcon } from "../../assets/images/rounded-error.svg";
import NotificationEmptyState from "./NotificationEmptyState";
import Moment from "react-moment";

const Notifications = ({ alerts, onRead ,target}) => {
  const navigate = useNavigate();
  const handleViewClick = async (alert) => {
    await onRead(alert.id, alert.notification_id); 
    if (alert.redirectionPage) {      
      navigate(alert.redirectionPage);
    }
  };
  return (
    <div
      className={`notifications-container ${
        alerts.length !== 0 ? "d-flex" : ""
      }`}
      style={{
        height: alerts.length == 0 || alerts.length > 6 ? '482px' : 'auto'
      }}
    >
      {alerts.length === 0 ? (
        <NotificationEmptyState />
      ) : (
        <ul className="notification-list ps-0 mb-0 scrollable-checkbox-list">
          {alerts.map((alert, index) => (
            <li
              key={alert.notification_id}
              className={`notification-item p-0 cursor-pointer ${alert.read ? "" : "unread"}`}
              style={{ borderTop: index > 0 ? "1px solid #ccc" : "none" }}
              onClick={() => handleViewClick(alert)}
            >
              <div className=" notification-action w-100">
                <div className="d-flex align-items-center justify-content-between">
                  <div className=" d-flex align-items-center ">
                    <div className="notification-icon ">
                      <div>
                        {alert.icon === "success" && <SuccessIcon />}
                        {alert.icon === "medium-warning" && <WarningIcon />}
                        {alert.icon === "high-warning" && <HighWarningIcon />}
                      </div>
                    </div>
                    <p className="notification-description text-white">
                      {alert.description}
                    </p>
                  </div>
                  <div className="d-flex align-items-center">
                    <span
                      className="notification-link px-1 cursor-pointer"
                    >
                      View
                    </span>
                    {!alert.read && <span className="unread-dot" />}
                  </div>
                </div>
                <div className="notification-details">
                  <p className="notification-date-time pt-2">
                    <Moment format="MMM DD, hh:mm A" className="pe-2">
                      {alert.dateTime}
                    </Moment>
                  </p>
                </div>
              </div>
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default Notifications;
