export const httpsFilterOptions = [
  {
    id: 0,
    name: "Yes",
    type: "Login Over HTTPS: Yes",
    key: "3",
    active: false,
  },
  {
    id: 1,
    name: "No",
    type: "Login Over HTTPS: No",
    key: "3",
    active: false,
  },
];

export const httpFilterOptions = [
  {
    id: 0,
    name: "Yes",
    type: "Login Over HTTP: Yes",
    key: "4",
    active: false,
  },
  {
    id: 1,
    name: "No",
    type: "Login Over HTTP: No",
    key: "4",
    active: false,
  },
];
export const statusMap = { new: 1, changed: 2, unchanged: 0 }; 
export  const exportHeaders = [
  "Hostname",
  "Login Page",
  "Login Over HTTPS",
  "Login Over HTTP",
];
   
