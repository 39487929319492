import React, { useState, useEffect } from "react";
import { Modal, Button, Form } from "react-bootstrap";
import logopng from "../../assets/images/logo.png";
import { Link } from "react-router-dom";

const TermsConditionsModal = ({
  show,
  onHide,
  onContinue,
  isAfterRegister = false,
  closeOption = true,
  onDecline,
  isContactLink = true,
}) => {
  const [bottom, setBottom] = useState(false);
  const handleScroll = (e) => {
    const target = e.target;
    const isBottom =
      target.scrollHeight - target.scrollTop <= target.clientHeight + 1;
    setBottom(isBottom);
  };
  useEffect(() => {
    if (show) {
      setBottom(false);
    }
  }, [show]);

  return (
    <Modal
      show={show}
      onHide={onHide}
      centered
      className="base-modal w-85 h-80 terms-modal"
      backdrop={false}
    >
      <div className="d-flex flex-column justify-content-center align-items-center">
      <img src={logopng} alt="Logo" className="mt-3 d-block mx-auto img-fluid logo-img" /> 
        <h2 className="mt-4 text-center ">Terms and Conditions </h2>
      </div>
      <Modal.Body className="scrollable-checkbox-list" onScroll={handleScroll}>
        <div>
          <p>Welcome to the ‘Armory’ platform!</p>
          <p>
            ‘Armory’ is a continuous threat exposure management platform (the
            “Platform”), which includes, inter alia, the following:
          </p>
          <ul>
            <li>
              ‘Armory’ offers a unique perspective by continuously mimicking the
              actions of an organization’s attacker.
            </li>
            <li>
              ‘Armory’ systematically and continuously maps and scans the
              external Assets (as defined below) of an organization and may
              identify data leaks and sensitive data exposures, including by
              conducting controlled active attacks to validate security
              measures.
            </li>
            <li>
              ‘Armory’ prioritizes findings based on potential risks, provides
              targeted insights and actionable intelligence, all based on
              integrated AI technology.
            </li>
            <li>
              ‘Armory’ provides certain cyber awareness tools designated to
              assist a company CISO (chief information security officer) to
              enable, among other things, risk demonstration and enhancement of
              cyber awareness within its corporation.
            </li>
          </ul>
          <p>
            Nevertheless, the Platform is only an interface designed to assist
            organizations and their CISOs, and any actions to be taken based on
            the Platform Content (as defined below) are to be done by solely
            such organizations or their CISOs and on their own expense.
          </p>
          <ol>
            <li>
              <h4 className="underline">GENERAL</h4>
              <p>
                By executing an Order Form (as defined below), logging into the
                Account (as defined below) or clicking the ‘I Accept’ button or
                a similar button as part of accessing or using the Platform
                (whichever is earlier, the “Effective Date”) you (the
                “Customer”) hereby agree to these general terms and conditions,
                as may be amended from time to time (“Agreement”) with
                Cisotronix Ltd., on behalf of itself and its affiliates (the
                “Company”). Each of the Company and the Customer shall be
                referred to as “Party” and together the “Parties”.
              </p>
              <p>
                Please note that this Agreement constitutes a legally binding
                agreement between the Company and the Customer, and if the
                Customer does not agree to all of the terms of this Agreement,
                the Customer must exit the Platform and discontinue the use
                thereof and of the Services (as defined below) or terminate any
                outstanding Order Form in accordance with its terms. If the
                terms of the Agreement are considered an offer, acceptance is
                expressly limited to these terms.
              </p>
            </li>
            <li>
              <h4 className="underline">THE PLATFORM</h4>
              <ol>
                <li>
                  As part of using the Platform, the Customer may receive from
                  time to time, certain information and updates (including via
                  designated reports issued by the Company), including regarding
                  sensitive data exposures, security vulnerabilities and
                  misconfigurations or other security measures insights (the
                  “Platform Content”), with respect to assets and data of the
                  Customer or the Customer’s personnel (the “Personnel”), as
                  requested by the Customer from time to time, including via any
                  Order Form or by way of a query submitted through the
                  Platform, by providing the Company certain data, such as IP
                  addresses, phone numbers or domains (the “Assets”).
                </li>
                <li>
                  The Platform’s subscription services under this section, and
                  all features provided thereunder, together with any additional
                  services provided by the Company to the Customer under any
                  Order Form, shall be defined herein as the “Services”.
                </li>
              </ol>
            </li>
            <li>
              <h4 className="underline">SCOPE OF SERVES AND THE LICENSE</h4>
              <ol>
                <li>
                  Following the Effective Date, the Company shall grant the
                  Customer a limited, revocable, non-exclusive, non-transferable
                  and non-sub-licensable license to use the Platform, for
                  internal and personal use only, for the sole purpose of
                  self-education and awareness, including any revisions,
                  releases, corrections, copies, modifications, derivatives,
                  enhancements, updates or upgrades thereto, in accordance with
                  terms of this Agreement and subject thereto (the “License”).
                </li>
                <li>
                  It is hereby clarified that the Customer does not acquire any
                  rights whatsoever in and to the Platform or the Services
                  beyond the rights granted herein, and any such use of the
                  Platform shall be in accordance with the terms of this
                  Agreement.
                </li>
                <li>
                  The Company may update the functionality, user interface,
                  usability and other user documentation of the Services or the
                  Platform, and information relating to the Platform and all of
                  its features, from time to time, in its sole discretion and in
                  accordance with this Agreement as part of its ongoing mission
                  to improve the Platform. Customer is solely responsible for
                  maintaining at all times the most up-to-date version of the
                  Platform available, to cooperate with the Company and provide
                  it with all necessary information required for the proper
                  performance of the Services as requested by the Company from
                  time to time. It is hereby clarified that the Customer’s
                  failure or delay to obtain such most up-to-date version or any
                  other updates or upgrades thereof, or otherwise comply with
                  Company’s instructions, within the timeframe provided to do so
                  by the Company, or to provide the most accurate and complete
                  information, may cause or result in delay or malfunctions in
                  the performance of the Platform and the provision of the
                  Services.
                </li>
                <li>
                  In no event will the Company be liable to Customer or to any
                  third-party for Customer’s failure or delay to comply with
                  Section ‎3.3 above, and Customer shall bear full
                  responsibility in connection therewith.
                </li>
              </ol>
            </li>
            <li>
              <h4 className="underline">THE ACCOUNT</h4>
              <ol>
                <li>
                  Under the License, the Company shall provide the Customer with
                  credentials to open an account for the purpose of accessing
                  and using the Platform (the “Account”).
                </li>
                <li>
                  As part of the opening of the Account the Customer shall be
                  required to provide certain information to the Company,
                  including as further detailed in the Privacy Policy (as
                  defined below).
                </li>
                <li>
                  The Customer is responsible to maintain the confidentiality of
                  all of the Account information provided for the purpose of
                  logging into the Account (including, its username, password,
                  or other identification information), using at least the same
                  degree of care as the Customer uses to protect its most
                  confidential information. The Customer shall be solely
                  responsible for any activity on the Account and any activity
                  that occurs as part of its access to the Platform and use
                  thereof. Except to the extent caused by the Company’s breach
                  of its confidentiality obligations hereunder, the Company
                  shall not be responsible for unauthorized access to the
                  Account or any loss that may occur as a result thereof.
                </li>
                <li>
                  The Customer will contact the Company promptly if (i) the
                  Customer discover that any Account information is lost,
                  stolen, or disclosed to an unauthorized person; (ii) the
                  Customer reasonably believes that the Account has been
                  compromised, including any unauthorized access, use, or
                  disclosure of Account information; or (iii) any other breach
                  of security in relation to its passwords, usernames, access
                  information, or the Platform, that may have occurred or is
                  reasonably likely to occur.
                </li>
              </ol>
            </li>
            <li>
              <h4 className="underline">USER OBLIGATIONS AND RESTRICTIONS</h4>
              <ol>
                <li>
                  The Customer is solely responsible to obtain and maintain, at
                  its own expense, all necessary computer hardware, modems,
                  connections to the internet and other items required to access
                  and use the Platform and the Services, and to make sure that
                  such equipment is compatible with the Platform.
                </li>
                <li>
                  Without limiting the foregoing, the Customer may not, and may
                  not permit or aid others, or allow any third party, to: (i)
                  use the Company’s IPR (as defined below), either solely or in
                  conjunction with any other products, for any purpose other
                  than for the purpose hereunder, or contrary to the terms of
                  this Agreement; (ii) copy, reproduce, sell, license (or
                  sub-license), lease, loan, assign, transfer, distribute,
                  commercially exploit or pledge the Company’s IPR or any part
                  thereof or the Customer’s rights under the License; (iii)
                  modify, display, disassemble, decompile, reverse engineer,
                  revise, enhance, republish, create any derivative works, or
                  otherwise merge or utilize all or any part of the Company’s
                  IPR, with or into any third party materials or components or
                  attempt to access or discover the Platform’s source code; (iv)
                  make any changes or interfere in any way in the source code of
                  the Platform, and upload any software or application that may
                  harm or cause damage to the Company, the Platform or any other
                  third party; (v) allow any other third party to use or access
                  the Services, the Platform or the Account and agree to use
                  reasonable efforts to prevent unauthorized access to or use
                  thereof; (vi) use the Platform, the Services or the Platform
                  Content in any illegal manner or for unlawful purposes, or in
                  any other abusive, fraudulent, deceitful or misrepresented
                  manner; (vii) create an account with the Platform through
                  unauthorized means, including but not limited to, by using an
                  automated device, script, bot, spider, crawler or scraper;
                  (viii) develop, support or use any means, including any
                  automated device, script or bot, to scrape (such as access or
                  copy in bulk, retrieve, harvest, or index any portion) the
                  Platform or any data of any kind available on the Platform or
                  related thereto, for any purpose; (ix) represent that the
                  Customer have any proprietary interest in the Company’s IPR or
                  delete or modify any attributions, legal notices or other
                  proprietary designations on the Company’s IPR; (x) contest the
                  Company’s Intellectual Property Rights (as defined below) to
                  the Company’s IPR; (xi) interfere with or disrupt the
                  integrity or performance of the Platform or the Company’s
                  network or the data contained therein; (xii) engage in
                  “framing”, “mirroring”, or otherwise simulating the appearance
                  or function of the Platform; or (xiii) Abuse the Platform in
                  any way. “Abuse” shall mean and include any of the prohibited
                  activity outlined in this Section ‎5.2, including without
                  limitation, direct or indirect violation or bad activity
                  through the Platform or the Services, including copyright
                  infringement, email spamming and network scanning. The
                  decision whether an Abuse occurred or not, shall reside with
                  the Company at its sole discretion. Upon a decision by the
                  Company that an Abuse has occurred, without derogating from
                  any of the Company’s rights hereunder, the Company shall issue
                  the Customer a notice to cease the Abuse.
                </li>
                <li>
                  The Company will have the right (but not the obligation) to
                  review and monitor all use of the Platform to ensure the
                  Customer’s compliance with the terms of the License and this
                  Agreement. Without prejudice to any other right of the
                  Company, of upon any forbidden uses as described in this
                  Section, the Company shall have the right to (i) prevent the
                  Customer from using the Platform and suspend the License, (ii)
                  report the Customer’s behaviour patterns while using the
                  Platform to third parties, and (iii) take any other action
                  that the Company may deem appropriate to protect its property
                  and rights, as well as the rights of third parties.
                </li>
              </ol>
            </li>
            <li>
              <h4 className="underline">CONSIDERATION</h4>
              <ol>
                <li>
                  In consideration for the License granted herein, for the use
                  of and access to the Platform and the Services (as
                  applicable), the Customer may pay the Company the fees set
                  forth in the order form executed between the Parties in
                  accordance with the terms therein (if any) (“Order Form”).
                </li>
                <li>
                  Unless otherwise agreed in any specific Order Form, the
                  calculation of any fee payable to the Company hereunder shall
                  be done on a monthly basis, commencing on the date of
                  execution of the Order Form, and will automatically renew on a
                  monthly basis (each, a “Billing Period”), unless this
                  Agreement is terminated in accordance with Section ‎11 below.
                  All payments of the fee shall be made at least 30 days prior
                  to the then relevant Billing Period. All amounts paid shall be
                  non-refundable, non-cancellable and non-creditable regardless
                  of any termination of this Agreement, for any reason, during
                  the relevant Billing Period. All payments shall be invoiced.
                </li>
                <li>
                  In the event of Convenience Termination (as defined below),
                  the Customer shall immediately pay the Company the balance of
                  any discounts provided on account of the fees under the Order
                  Form.
                </li>
                <li>
                  If the Customer believes that the Company has billed the
                  Customer incorrectly, the Customer must contact the Company no
                  later than 60 days after the closing date on the first billing
                  statement in which the error or problem appeared, in order to
                  receive an adjustment or credit.
                </li>
                <li>
                  Interest in the amount of 1.5% per month will be charged on
                  all late payments. The Company reserves the right to
                  immediately suspend or terminate the Customer’s use of the
                  Platform in the event of any delay in payment. The Customer
                  shall reimburse the Company for all reasonable costs and
                  expenses incurred (including reasonable attorneys’ fees) in
                  collecting late payments pursuant to this Agreement.
                </li>
                <li>
                  The Customer is solely responsible for payment of any taxes
                  resulting from this Agreement, including VAT, if applicable.
                  All fees under this Agreement are presented as net prices, and
                  are exclusive of levies, duties, taxes, including withholding
                  taxes, which shall be payable by the Customer in addition to
                  the fees owed to the Company.
                </li>
              </ol>
            </li>
            <li>
              <h4 className="underline">INTELLECTUAL PROPERTY</h4>
              <ol>
                <li>
                  <p>
                    Notwithstanding anything to the contrary herein, the
                    Platform, the Services, and any content embedded therein,
                    including without limitation, materials, text, photos,
                    logos, any graphical display of data, designs, sounds,
                    figures, analysis, statistics and any other content, and all
                    Intellectual Property Rights therein, as well as any
                    Feedback (as defined below) (collectively, “Company’s IPR”),
                    are exclusively owned by the Company or its licensors.
                    Except as expressly provided herein, no other rights
                    whatsoever, expressed or implied, are acquired by or granted
                    to the Customer with respect to any of the Company’s IPR.
                  </p>
                  <p>
                    “Intellectual Property Rights” means any and all worldwide
                    intellectual property rights, whether registered or not,
                    including, but not limited to: (a) patents, patent
                    applications and patent rights, know how, inventions,
                    research, data, and development activities and discoveries;
                    (b) rights associated with works of authorship, including
                    copyrights, copyrights applications, copyrights
                    restrictions, mask work rights, mask work applications and
                    mask work registrations; (c) rights relating to the
                    protection of trade secrets and confidential information,
                    including but not limited to confidential and proprietary
                    information concerning the business and financial activities
                    of the Company, and any information concerning its service
                    providers, employees, customers, suppliers, and partners;
                    (d) trademarks, trade names, service marks, logos, trade
                    dress, goodwill and domains; (e) rights analogous to those
                    set forth herein and any other proprietary rights relating
                    to intangible property; and (f) divisions, continuations,
                    renewals, reissues and extensions of the foregoing (as
                    applicable) now existing or hereafter filed, issued, or
                    acquired.
                  </p>
                </li>
                <li>
                  The Customer hereby acknowledges that the Company may use
                  third party platforms or software to provide certain Services.
                </li>
                <li>
                  The Customer hereby grants the Company the right to freely use
                  the Customer’s logo and trademarks on the Company’s websites
                  and in any of its promotional and marketing materials.
                </li>
                <li>
                  Any feedback provided to the Company by the Customer regarding
                  the Platform, the Services or the use thereof, or any
                  suggested improvements, enhancements or derivatives thereto,
                  including to the Platform Content (the “Feedback”) is welcome
                  by the Company. The Customer is not required to provide
                  Feedback, however, to the extent that it shall do so, such
                  Feedback shall be solely owned by the Company, and shall not,
                  under any circumstance constitute as the Customer’s
                  confidential or proprietary information. The Customer hereby
                  acknowledge that the Company may use such Feedback in any
                  manner the Company sees fit, without restrictions or
                  limitations, and without payment of any royalty or any other
                  consideration.
                </li>
              </ol>
            </li>
            <li>
              <h4 className="underline">REPRESENTATIONS AND WARRANTIES</h4>
              <ol>
                <li>
                  Each Party hereby represents and warrants that: (i) it has the
                  full corporate right, power and authority to enter into this
                  Agreement and to perform the acts required hereunder; (ii) the
                  execution of this Agreement and the performance of its
                  obligations and duties hereunder does not violate any
                  agreement to which it is a party or by which it is otherwise
                  bound; (iii) when executed and delivered, the Agreement will
                  constitute the legal, valid and binding obligation of each
                  Party, enforceable against each Party in accordance with its
                  terms.
                </li>
                <li>
                  The Customer hereby further represents and warrants that it
                  has all rights, authorizations, consents and permissions to
                  act, perform and use the Services under the Platform,
                  including from any person or entity (including its employees,
                  contractors, service providers, suppliers or any other third
                  party) that the Customer will send or provide information to
                  as part of using the Platform, or retrieve information from or
                  regarding to such person or entity.
                </li>
              </ol>
            </li>
            <li>
              <h4 className="underline">USER CONTENT</h4>
              <ol>
                <li>
                  In connection with the Parties’ engagement hereunder, the
                  Customer hereby acknowledges and accepts that the Company
                  shall receive content, including data, text, links, Assets,
                  GPS location points and coordinates, Personnel’s information
                  and other types of information (the “User Content”), either
                  through the features embedded in the Platform or during the
                  performance of the Services, including from the Customer or
                  the Personnel or publicly available information.
                </li>
                <li>
                  The Customer hereby undertakes to provide the Company only
                  with such User Content that the Customer is authorized to
                  provide and use for the purpose of the Services under this
                  Agreement.
                </li>
                <li>
                  The Customer grants the Company with a non-exclusive,
                  irrevocable, limited, royalty free and worldwide license,
                  during the term of this Agreement, to use, process and store
                  the respective data and the User Content in order to perform
                  the Services and any features of the Platform, and to exercise
                  the Company’s rights and obligations under this Agreement, or
                  otherwise in connection thereto.
                </li>
                <li>
                  The Customer (or any of its licensors) is and shall remain the
                  owner of the User Content, as applicable, and shall be solely
                  responsible for all User Content. The Customer undertakes to
                  ensure that it shall have all rights to the User Content,
                  including with respect to the Assets, either by way of
                  ownership or valid license, and that it has received all
                  consent from the owners or licensors of such Assets as
                  required for the Customer’s receipt of the use of the
                  Platform. The Company is under no obligation to edit, monitor
                  or control the User Content, and will not be in any way
                  responsible or liable for the User Content
                </li>
                <li>
                  To the fullest extent permitted by law, the Company shall not
                  be responsible for any access to the User Content or to the
                  results of processing of the User Content, by any third party.
                </li>
              </ol>
            </li>
            <li>
              <h4 className="underline">PLATFORM CONTENT</h4>
              <ol>
                <li>
                  The Customer hereby acknowledges and agrees that certain
                  Platform Content may be obtained or created by the Company,
                  inter alia, via access and use by the Company of third party
                  software and tools.
                </li>
                <li>
                  Certain features of the Platform may include the display of
                  GPS coordinates on a map, based on certain User Content
                  provided by the Customer (either directly or indirectly). In
                  the event the Customer wishes to receive such Services, the
                  Customer hereby gives the Company its irrevocable
                  authorization and consent to transfer such GPS coordinates to
                  Google LLC, in accordance with and subject to Google LLC’s
                  terms of use, and to obtain all required consents and
                  permissions from the relevant individuals.
                </li>
                <li>
                  The Customer hereby agrees that, subject to the terms of the
                  License, it may use the Platform Content for internal and
                  personal use only, for the self-education and awareness of the
                  Customer and its CISO, and in no event shall the Customer use
                  the Platform Content for any other purpose, including for
                  cyber offense or any other misuse.
                </li>
                <li>
                  The Customer hereby acknowledges that it is aware to the fact
                  that the Platform, the Services and the Platform Content may
                  include information concerning to concepts that are subject to
                  continuing update, modification, external dependencies,
                  evolving regulatory and legal frameworks, or factors beyond
                  the Company’s control, and the Customer is hereby cautioned
                  not to place reliance on any Platform Content, including for
                  the purpose of making any decisions with respect to the
                  Customer’s data security privacy protection measures (such as
                  a decision to take or to avoid from taking any security
                  actions). Any reliance on any portion of the Platform Content
                  is at the Customer’s sole risk and the Customer bear sole
                  responsibility for its decisions made relying on the Platform
                  Content.
                </li>
                <li>
                  The Platform Content does not guarantee, nor does it provide
                  any warranty or representation, to the security,
                  vulnerability, or success of any specific data security and
                  privacy protection tool, measure or standard, nor with respect
                  to any cyber protection or cyber offense.
                </li>
                <li>
                  The Platform Content is for informational purposes and
                  internal use only for the operation and awareness of the
                  Customer, and in no event will the Company be responsible to
                  remedy any security breach, to prevent any cyber-attacks, or
                  to take any actions regarding the security measures detected
                  as part of the Platform Content, or otherwise during the use
                  of the Services and the Platform. Further, the Platform
                  Content does not purport in any way to be accurate, current or
                  complete, and the Platform Content is not soliciting any
                  action based on the Platform Content. The Customer is advised
                  to consider making further inspections and review of
                  additional materials, not related to the Platform, with
                  respect to its data security and privacy protection measures,
                  including any data protection breaches.
                </li>
                <li>
                  The Company does not undertake to advise the Customer of any
                  changes in any of the Platform Content.
                </li>
              </ol>
            </li>
            <li>
              <h4 className="underline">TERM</h4>
              <ol>
                <li>
                  This Agreement is effective upon the Effective Date and shall
                  continue until such time as the Customer continues to have the
                  right to access and use the Platform, in accordance with the
                  terms herein or of the Order Form.
                </li>
                <li>
                  The Customer may terminate this Agreement or any Order Form,
                  for convenience, by providing the Company with written advance
                  notice of at least 30 days prior to the end of the then
                  current Billing Period, unless specified otherwise in the
                  Order Form (“Convenience Termination”). Failure to provide
                  such termination notice will render the Customer liable for
                  payment of fees incurred during the subsequent Billing Period.
                </li>
                <li>
                  The Company terminate this Agreement or any Order Form, for
                  convenience, by providing the Customer with written advance
                  notice of at least 30 days. In the event of termination in
                  accordance with this Section ‎11.2, the Customer shall be
                  entitled to a pro-rata repayment of the applicable fees
                  actually paid by the Customer for the applicable Billing
                  Period.
                </li>
                <li>
                  Notwithstanding, the Company may terminate this Agreement
                  either (i) by 7 days’ notice to the Customer upon Customer’s
                  failure to comply with any of its obligations hereunder, and
                  (ii) immediately and without prior notice, in the following
                  events: (a) if the Customer’s attempts to transfer or assign
                  any of its rights, liabilities or obligations under this
                  Agreement contrary to the provisions thereof; (b) the
                  Customer’s violation of any of the restrictions set forth in
                  Section ‎5 of this Agreement; (c) the Company believes it is
                  necessary to do so to comply with applicable law; or (d) a
                  Force Majeure Event (as defined below) occurs which materially
                  affects the Company’s ability to provide the Platform or the
                  Services.
                </li>
                <li>
                  Upon termination of the Agreement: (i) the Customer shall
                  immediately pay all due fees and payments, regardless of the
                  due date of payment under this Agreement, (ii) the Customer
                  shall cease use of the Platform and the Services and
                  immediately return to the Company all Confidential Information
                  (as defined below) and Company’s IPR, in any media and form,
                  and shall erase all copies thereof the Platform, and (iii) the
                  Company shall be entitled to cancel the License, disable the
                  Account and terminate the Customer’s access to Platform.
                  Notwithstanding the termination or expiration of this
                  Agreement, Sections ‎7 (Intellectual Property), ‎9 (User
                  Content), ‎10 (Platform Content), ‎11.5(Termination
                  Consequences), ‎12 (Privacy), ‎13 (Confidentiality), ‎14‎15
                  (Disclaimer of Warranties), ‎16 (Limitation of Liability), ‎17
                  (Indemnification) and ‎19 (General) shall survive and remain
                  in effect in perpetuity.
                </li>
              </ol>
            </li>
            <li>
              <h4 className="underline">PRIVACY AND DATA</h4>
              <ol>
                <li>
                  By virtue of this Agreement, the Company may have access to
                  certain personal data regarding individuals. The Customer
                  acknowledges and agrees that except as described in this
                  Agreement, any content, data, and information the Customer
                  enters into or uploads to the Platform (including the User
                  Content and personal data) or that the Company collects in
                  connection with the Customer’s use of the Platform
                  (collectively, “Data”) will be processed as described in the
                  Privacy Policy available on the Platform (the “Privacy
                  Policy”) and the Data Processing Addendum attached to this
                  Agreement, as Exhibit A (the “DPA”).
                </li>
                <li>
                  The Customer is solely responsible (a) for Data as entered,
                  supplied, accessed, or used by the Customer, and (b) for
                  complying with any privacy and data protection laws and
                  regulations applicable to the Customer with respect to the
                  Data or the Customer’s use of the Platform. The Customer
                  represents and warrants that the Customer has obtained and
                  will maintain all rights, consents, and authorizations
                  required to grant the Company the rights and licenses set
                  forth herein and to enable the Company to exercise its rights
                  under the same and to provide the Services without violation
                  or infringement of the rights of any third party, including
                  with respect to privacy rights under any privacy and data
                  protection laws and regulations applicable to the Customer.
                </li>
                <li>
                  It is acknowledged by the Parties, that with respect to the
                  personal data that is provided or uploaded by the Customer to
                  the Platform as part of the Services, the Customer is
                  considered as the “Owner”, “Controller”, "Service Provider",
                  and the Company is considered as the “Holder”, “Processor”, or
                  "Business" in relation to such data, as further described in
                  the DPA.
                </li>
                <li>
                  The Customer hereby grants the Company, and the Company hereby
                  accepts, a non-exclusive, non-transferable license, to use the
                  Data in an anonymized manner for the Company’s internal
                  purposes.
                </li>
              </ol>
            </li>
            <li>
              <h4 className="underline">CONFIDENTIALITY</h4>
              <ol>
                <li>
                  <p>
                    Each Party (“Recipient”) shall keep confidential and shall
                    not disclose to any third party (other than to its
                    employees, affiliates and advisors having a need to know to
                    perform Recipient’s obligations hereunder, which are bound
                    by a confidentiality undertaking with Recipient on terms no
                    less restrictive than as contained herein), any Confidential
                    Information which it has acquired from the other Party
                    (“Discloser”) and shall only use such Confidential
                    Information in connection with exercising its rights and
                    performing its obligations under this Agreement. All right,
                    title and interest in and to the Confidential Information
                    disclosed or transferred by Discloser shall remain the
                    property of Discloser. Such confidentiality obligation shall
                    continue perpetually after termination of this Agreement.
                  </p>
                  <p>
                    “Confidential Information” means any know-how, any trade or
                    business secrets, any rights associated with works of
                    authorship, including copyrights, any commercial, financial,
                    business, technical or other confidential information of
                    whatever nature relating to the Discloser’s business
                    (whether written, oral or in electronic or other form, and
                    whether marked or unmarked as confidential) or of clear
                    confidential nature, including, with respect to the Company,
                    the Platform, the Services, the Platform Content and all of
                    Company’s IPR incorporated therein. Confidential Information
                    shall not include information that: (i) is or becomes
                    publicly known other than through any act or omission of the
                    Recipient; (ii) was in the Recipient’s lawful possession
                    before the disclosure, as evidenced by the Recipient; (iii)
                    is lawfully disclosed to the Recipient by a third party
                    without restriction on disclosure, as evidenced by the
                    Recipient; or (iv) is independently developed by the
                    Recipient without use of Confidential Information of the
                    Discloser, which independent development can be shown by
                    written evidence.
                  </p>
                </li>
                <li>
                  The Recipient may disclose Confidential Information in the
                  event it is required to do so by law, by any court of
                  competent jurisdiction or by any regulatory or administrative
                  body, provided, that the Recipient (unless prohibited from
                  doing so) shall promptly give Discloser prior notice thereof
                  so that Discloser may contest the disclosure or obtain a
                  protective order with respect thereto. Recipient shall only
                  disclose that portion of the Confidential Information that
                  Recipient is legally obligated to disclose.
                </li>
              </ol>
            </li>
            <li>
              <h4 className="underline">SERVICE LEVEL</h4>
              <ol>
                <li>
                  The Company’s service commitments hereunder do not include
                  downtime to the extent resulting from previously scheduled
                  maintenance and events beyond the Company’s reasonable
                  control, including, but not limited to, any downtime caused by
                  (a) outages to any public internet backbones, networks or
                  servers; (b) any failures of the Customer’s system, equipment,
                  etc.; or (c) acts of God. Subject to the terms above, the
                  Company shall provide the Customer with reasonable technical
                  support services with regards to the operation of the
                  Platform, in accordance with its standard practice. The
                  Customer's sole remedy in the event of any noncompliance with
                  this Section ‎14, shall be to terminate this Agreement. Such
                  termination does not affect payments of any fees hereunder due
                  prior to such termination.
                </li>
              </ol>
            </li>
            <li>
              <h4 className="underline">DISCLAIMER OF WARRANTIES</h4>
              <ol>
                <li>
                  <p>
                    EXCEPT FOR THE EXPRESS REPRESENTATIONS AND WARRANTIES STATED
                    HEREIN, THE PLATFORM AND THE PLATFORM CONTENT ARE PROVIDED
                    “AS-IS”, “AS-AVAILABLE” AND “WITH ALL FAULTS” BASIS, AND THE
                    COMPANY MAKES NO OTHER WARRANTIES AND EXPLICITLY DISCLAIMS
                    ANY OTHER WARRANTIES OF ANY KIND, EITHER EXPRESS OR IMPLIED,
                    INCLUDING BUT NOT LIMITED TO WARRANTIES OF MERCHANTABILITY,
                    FITNESS FOR A PARTICULAR PURPOSE OR NON-INFRINGEMENT. THE
                    COMPANY DOES NOT HAVE ANY OBLIGATION TO MONITOR THE USE OF
                    THE PLATFORM AND IT IS NOT RESPONSIBLE FOR THE AVAILABILITY,
                    ACCURACY, APPLICABILITY, COMPLETENESS, OR LEGALITY OF ANY
                    INFORMATION, DATA, DOMAIN, INCLUDING THE PLATFORM CONTENT,
                    AND IN NO EVENT WILL THE COMPANY BE LIABLE FOR ANY
                    CYBER-ATTACK OR ANY OTHER SECURITY OR DATA BREACH OF ANY
                    KIND WITH RESPECT TO THE CUSTOMER, ITS PERSONNEL OR ANY
                    THIRD PARTY. FURTHER, AND IN ADDITION TO THE PROVISIONS OF
                    SECTION ‎10 ABOVE, THE COMPANY DOES NOT REPRESENT OR WARRANT
                    THAT: (I) THE PLATFORM, THE SERVICES OR THE PLATFORM CONTENT
                    SHALL BE ERROR FREE OR THAT ANY ERRORS WILL BE CORRECTED;
                    (II) THE PLATFORM SHALL NOT CONTAIN ANY BUGS, VIRUSES,
                    TROJAN HORSES, OR THE LIKE WHICH MAY BE TRANSMITTED TO OR
                    THROUGH THE PLATFORM BY ANY THIRD PARTY; (III) THE OPERATION
                    OF THE PLATFORM WILL BE UNINTERRUPTED OR THAT IT WILL BE
                    ABLE TO BE USED AT ANY TIME; (IV) THE PLATFORM, THE SERVICES
                    OR THE PLATFORM CONTENT WILL MEET THE CUSTOMER’S
                    REQUIREMENTS. NOTHING IN THIS AGREEMENT SHALL BE CONSTRUED
                    AS PROVIDING A GUARANTEE OR ASSURANCE AGAINST THE FAILURE OF
                    CYBER-ATTACKS OR BREACH OF INFORMATION SECURITY OF ANY KIND,
                    AND THE COMPANY HEREBY DECLARES THAT IT DOES NOT PROVIDE ANY
                    SUCH GUARANTEE OR ASSURANCE.
                  </p>
                  <p>
                    THE COMPANY SHALL NOT BE RESPONSIBLE FOR UNAUTHORIZED ACCESS
                    TO OR ALTERATION TO THE PLATFORM AND WILL NOT BE LIABLE FOR
                    ANY DAMAGES OR LOSS INCURRED TO THE CUSTOMER, OR ANY OTHER
                    THIRD PARTY AS A RESULT OR IN CONNECTION WITH THE USE OF THE
                    PLATFORM OR THE SERVICES OR IN CONNECTION WITH USE OF OR
                    RELIANCE ON THE PLATFORM OR ANY INFORMATION DERIVED THROUGH
                    THE PLATFORM, INCLUDING WITHOUT LIMITATION, THE PLATFORM
                    CONTENT. IN ADDITION, THE COMPANY SHALL NOT BE RESPONSIBLE
                    OR LIABLE FOR UNAUTHORIZED ACCESS TO CUSTOMER’S SYSTEMS OR
                    FOR THE USE OF THE PLATFORM BY THE CUSTOMER.
                  </p>
                </li>
              </ol>
            </li>
            <li>
              <h4 className="underline">LIMITATION OF LIABILITY</h4>
              <ol>
                <li>
                  TO THE FULLEST EXTENT PERMITTED BY APPLICABLE LAW, IN NO EVENT
                  WILL THE COMPANY BE LIABLE TO CUSTOMER OR ANY THIRD PARTY FOR
                  LOST PROFITS, LOSS OF USE, LOSS OF DATA OR BUSINESS
                  INFORMATION, LOSS OF USE, COST OF PROCUREMENT OF SUBSTITUTE
                  GOODS OR SERVICES, OR ANY OTHER SPECIAL, INCIDENTAL, INDIRECT,
                  PUNITIVE, OR CONSEQUENTIAL DAMAGES, INCLUDING, WITHOUT
                  LIMITATION, IN CONNECTION WITH THE COMPANY CONTENT, HOWEVER
                  CAUSED, AND ON ANY THEORY OF LIABILITY, WHETHER FOR BREACH OF
                  CONTRACT, TORT (INCLUDING NEGLIGENCE AND STRICT LIABILITY), OR
                  OTHERWISE, WHETHER OR NOT THE COMPANY HAS BEEN ADVISED OF THE
                  POSSIBILITY OF SUCH DAMAGES. TO THE FULLEST EXTENT PERMITTED
                  BY APPLICABLE LAW, IN ANY EVENT, THE COMPANY’S MAXIMUM
                  AGGREGATE LIABILITY UNDER OR ARISING OUT OF OR RELATING TO
                  THIS AGREEMENT SHALL NOT EXCEED THE HIGHER OF: (I) USD 1,000;
                  AND (II) TOTAL AMOUNT OF FEES ACTUALLY PAID BY THE CUSTOMER
                  DURING THE SIX (6) MONTHS PRECEDING ANY CLAIM UNDER WHICH SUCH
                  LIABILITY SHALL ARISE.
                </li>
                <li>
                  The limitations contained in this Section ‎16 are considered
                  reasonable by the Parties having regard to the circumstances
                  which are known to or in the contemplation of the Parties at
                  the date of this Agreement, and the availability of insurance
                  to the Parties.
                </li>
              </ol>
            </li>
            <li>
              <h4 className="underline">INDEMNIFICATION</h4>
              <ol>
                <li>
                  The Customer hereby agrees to indemnify and hold the Company
                  harmless from any and all damages, liabilities, costs, losses
                  or expenses (including reasonable attorney fees) arising out
                  of any claim, demand, or action (“Claim”) by a third party
                  arising from or in connection to the Customer’s access or use
                  of the Platform or any breach of the Customer’s
                  responsibilities or obligations, representations or warranties
                  under this Agreement. The Company shall notify the Customer in
                  writing of the Claim and shall make commercially reasonable
                  efforts to provide the Customer with reasonable assistance and
                  information.
                </li>
              </ol>
            </li>
            <li>
              <h4 className="underline">LINKS TO THIRD PARTY SITES</h4>
              <ol>
                <li>
                  The Platform may contain links, content, advertisements,
                  promotions, logos and other materials (the “Third Party
                  Materials”) to platforms, websites or software that are
                  controlled or offered by third parties (the “Third-Party
                  Sites”). The Company does not verify, make any
                  representations, or take responsibility for these Third-Party
                  Sites, including the truthfulness, accuracy, quality, or
                  completeness of the content, services, links displayed, or
                  other activities conducted on or through the Third-Party
                  Sites. The Company does not endorse, sponsor, is affiliated
                  with, or make any representations about the Third-Party Sites
                  or any information, material, or results that may be obtained
                  through the use of Third-Party Sites, and hereby caution the
                  Customer to ensure that it understands the risks involved in
                  using such Third-Party Sites or purchasing anything via these
                  Third-Party Sites, and to verify all information within the
                  Third-Party Sites before relying on it. If the Customer
                  decides to access any of the Third-Party Sites, the Customer
                  does this entirely at its own risk, and the Customer must
                  follow the privacy policies and the terms and conditions for
                  such Third-Party Sites. The display of these Third Party
                  Materials in the Platform is as a convenience only, and under
                  no circumstances will the Customer hold the Company liable,
                  directly or indirectly, for any loss or damage caused by the
                  use of or reliance on any content, goods or services available
                  on any Third-Party Site.
                </li>
              </ol>
            </li>
            <li>
              <h4 className="underline">GENERAL</h4>
              <ol>
                <li>
                  The Company may, at its sole discretion, amend, modify, or
                  discontinue, from time to time, any of the services provided
                  under the Platform or introduce new services. The Company
                  shall not be liable for any loss suffered by the Customer
                  resulting from any such changes made and the Customer shall
                  have no claims against the Company in such regard.
                </li>
                <li>
                  <p>
                    This Agreement, together with any Order Form, contains the
                    entire understanding of the Parties with respect to the
                    subject matter hereof and supersedes all prior agreements
                    between the Parties, including without limitation, any kind
                    of non-disclosure agreement. For clarity, the Parties
                    specifically agree that this Agreement supersedes and
                    renders void any contrary terms and conditions contained in
                    any Order Form, sales order or other instrument, agreement
                    or document unless such instrument is entered into after the
                    Effective Date, signed by both Parties hereto, and expressly
                    referencing this Agreement.
                  </p>
                </li>
                <li>
                  For the purposes of this Agreement, the Parties will at all
                  times be independent contractors with no right to bind or
                  obligate the other in any manner whatsoever. The transmission
                  of information to or from the Platform does not create between
                  the Parties any relationship that deviates from those
                  specified in this Agreement.
                </li>
                <li>
                  The Customer may not transfer or assign its rights or
                  obligations under this Agreement to any third party without
                  the Company’s prior written approval. The Company may assign
                  its rights or obligations under this Agreement at any time.
                </li>
                <li>
                  Capitalized terms used in this Agreement and not otherwise
                  defined herein, shall have the meaning ascribed to such terms
                  in the applicable Order Form.
                </li>
                <li>
                  All notices will be made in writing and given by personal
                  delivery, overnight courier, facsimile, email or other means
                  of transmission or by certified or registered mail to contact
                  information mentioned above or the last contact information
                  provided by a party following the Effective Date.
                </li>
                <li>
                  The failure of either Party at any time to require performance
                  by the other of any provision herein will not affect the right
                  of such Party to require performance at any time thereafter,
                  nor will the failure of either Party to take action regarding
                  a breach of any provision hereof be taken or held to be a
                  waiver of the provision itself.
                </li>
                <li>
                  Any provision of this Agreement which is determined to be
                  prohibited or unenforceable by a court of competent
                  jurisdiction will be ineffective only to the extent of such
                  prohibition or unenforceability and will be severed without
                  invalidating the remaining provisions hereof or otherwise
                  affecting the validity or enforceability of such provision.
                  The headings used herein are for the convenience of the
                  Parties only and will not affect the interpretation of this
                  Agreement.
                </li>
                <li>
                  This Agreement shall be governed by the laws of the State of
                  Israel, without reference to its principles of conflict of
                  laws to the extent they would require the application of the
                  law of another jurisdiction. The parties each consent to the
                  exclusive jurisdiction of the courts of Tel-Aviv, Israel, and
                  waive any objection to venue in such courts. Notwithstanding
                  the foregoing, the Company shall be entitled to seek
                  injunctive and other equitable relief, without the necessity
                  of showing actual money damages in any jurisdiction in the
                  event of an actual or threatened breach.
                </li>
              </ol>
            </li>
          </ol>
          <br />
          <br />
          <h4 className="text-center">EXHIBIT A</h4>
          <br />
          <h4 className="text-center underline">Data Processing Addendum</h4>
          <p>
            This data processing addendum (the “Addendum”), forms a part of the
            Agreement entered into by and between Cisotronix Ltd. (the
            “Company”), and the “Customer”) (with The Company on the one hand
            and the Customer on the other hand who may also be referred to
            herein as a “Party”, and collectively as the “Parties”).
          </p>
          <p>
            Capitalized terms not defined herein will have the meaning set forth
            in the Agreement.
          </p>
          <p>
            This addendum applies to Customer's use of the Platform and not to
            other services provided by the Company.
          </p>
          <ol>
            <li>
              <h4 className="underline">DEFINITION</h4>
              <ol>
                <li>
                  "Agreement Data" shall mean any Data provided by the Customer
                  on the Platform received, or any Data accessed by the Company
                  or any Subcontractor pursuant to or in connection with the
                  Agreement.
                </li>
                <li>
                  "Agreement Personal Data" shall mean any Personal Data
                  provided by the Customer on the Platform Processed by the
                  Company or any Subcontractor pursuant to or in connection with
                  the Agreement.
                </li>
                <li>
                  "Applicable Laws" shall mean laws applicable directly to the
                  Company by virtue of the Services provided under the Agreement
                  with respect to any Personal Data.
                </li>
                <li>
                  “Applicable Privacy Laws” means any laws applicable to the
                  Company by virtue of the Services provided under the Agreement
                  relating to Personal Data and privacy protection, including
                  (if applicable) the GDPR, CCPA, and Protection of Privacy Law,
                  1981, and the regulations promulgated thereunder.
                </li>
                <li>
                  “Data Subject” means any person to whom the Personal Data
                  relates.
                </li>
                <li>
                  "Subcontractor" means any person appointed by or on behalf of
                  the Company to Process Agreement Personal Data on behalf of
                  the Customer in connection with the Agreement.
                </li>
                <li>
                  "GDPR" means Regulation (EU) 2016/679 of the European
                  Parliament and of the Council.
                </li>
                <li>
                  "CCPA" means the California Consumer Privacy Act (2018), as
                  amended by the California Privacy Rights Act (2020).
                </li>
                <li>
                  "Controller", "Data Subject", "Personal Data", "Personal Data
                  Breach", "Processing", "Sell", "Sale" or "Selling", "Share",
                  "Shared" or "Sharing", "Business" and "Service Provider" shall
                  have the meanings ascribed to them in the Applicable Privacy
                  Laws.
                </li>
                <li>
                  <p>
                    “Standard Contractual Clauses” or “EU SCC” means European
                    Commission's Implementing Decision 2021/914 of 4 June 2021
                    on standard contractual clauses for the transfer of Personal
                    Data to third countries pursuant to the GDPR or any
                    subsequent final version thereof which shall automatically
                    apply. The version of the EU SCCs governing transfers of
                    Personal Data from Controller to Processor is available
                    online in Module 2 at:
                  </p>
                  <p>
                    <a
                      className="text-link"
                      href="https://eur-lex.europa.eu/legal-content/EN/TXT/HTML/?uri=CELEX:32021D0914&from=EN#d1e32-57-1"
                    >
                      https://eur-lex.europa.eu/legal-content/EN/TXT/HTML/?uri=CELEX:32021D0914&from=EN#d1e32-57-1
                    </a>
                  </p>
                  <p>
                    If the European Commission replaces the EU SCCs with amended
                    or new standard contractual clauses, then, to the extent the
                    relevant supervisory authority approves of the use of such
                    amended or new standard contractual clauses, the references
                    herein to "EU SCCs" will be read to refer to such amended or
                    new standard contractual clauses.
                  </p>
                </li>
              </ol>
            </li>
            <li>
              <h4 className="underline">GENERAL REQUIREMENTS</h4>
              <ol>
                <li>
                  Both Parties undertake to act in accordance with the
                  provisions of this Addendum and in accordance with the
                  provisions of the Applicable Privacy Laws.
                </li>
                <li>
                  <p>
                    It is acknowledged by the Parties that by virtue of the
                    Agreement, the Company may Process Agreement Personal Data
                    on behalf of the Customer. The Customer is the party who
                    determines the purposes and means of the Processing of
                    Agreement Personal Data and the Company Processes Agreement
                    Personal Data on behalf of the Customer for the provision of
                    the Services thereto. The Company shall only Process
                    Agreement Personal Data on behalf of and in accordance with
                    Customer's documented instructions. To the extent that the
                    Company believes that an instruction given by the Customer
                    does not comply with any Applicable Law, it shall refuse to
                    comply with such instruction even if the Customer insists on
                    it despite the notification of the Company.
                  </p>
                </li>
                <li>
                  Accordingly, the Customer is considered as the "Controller" or
                  the "Business" and the Company is considered as the
                  "Processor" or the "Service Provider" (or different terms with
                  similar meanings in any Applicable Privacy Laws) with regards
                  to the Agreement Personal Data, by virtue of Applicable
                  Privacy Laws.
                </li>
                <li>
                  Schedule I to this Addendum sets out certain details regarding
                  the Company's Processing of Agreement Personal Data.
                </li>
                <li>
                  The Company will not Sell or Share Agreement Personal Data nor
                  take any action that would cause any transfer of Agreement
                  Personal Data to or from the Company under the Agreement or
                  this Addendum to qualify as Selling or Sharing of such
                  Agreement Personal Data.
                </li>
                <li>
                  The Company shall notify the Customer if it determines that it
                  can no longer meet its obligations under Applicable Privacy
                  Laws. The Customer may, upon written notice, take reasonable
                  steps to stop and remediate an unauthorized use of Agreement
                  Personal Data in accordance with Applicable Privacy Laws.
                </li>
              </ol>
            </li>
            <li>
              <h4 className="underline">COMPANY'S PERSONNEL</h4>
              <ol>
                <li>
                  Company shall ensure that access to Personal Data is limited
                  to those individuals who need to know or access the relevant
                  Personal Data and as strictly necessary for the purpose of the
                  Agreement.
                </li>
                <li>
                  Company shall take steps to ensure that the individuals who
                  may have access to Personal Data on its behalf (i) are
                  informed of the confidential nature of Personal Data; and/or
                  (ii) are subject to confidentiality undertakings or
                  appropriate statutory obligations of confidentiality.
                </li>
              </ol>
            </li>
            <li>
              <h4 className="underline">SUBCONTRACTORS</h4>
              <ol>
                <li>
                  The Customer grants the Company a general authorization to
                  engage third-party Subcontractors in connection with the
                  provision of the Services. The subcontractors employed by the
                  Company are listed in the Subcontractor list in Schedule II.
                </li>
                <li>
                  The Company will take measures to ensure that all its
                  subcontractors are subject to privacy and security
                  undertakings which are similar to the undertakings which are
                  applicable to the Company as part of this Addendum and are in
                  compliance with Applicable Privacy Laws.
                </li>
              </ol>
            </li>
            <li>
              <h4 className="underline">RIGHTS OF DATA SUBJECT</h4>
              <ol>
                <li>
                  Without derogating from the generality of the above, the
                  Company shall (i) notify the Customer without undue delay of
                  any request raised by a Data Subject in relation to Personal
                  Data concerning him or her to Company; and (ii) refrain from
                  responding to any such request, except on a written
                  instruction of Customer or as required by Applicable Privacy
                  Laws to which Company is subject.
                </li>
                <li>
                  Taking into account the nature of the processing of Personal
                  Data by Company, Company shall assist the Customer by
                  reasonably appropriate technical and organizational measures,
                  insofar as this is possible and reasonable, for the fulfilment
                  of the Customer's obligations to respond to a request raised
                  by a Data Subject in relation to Personal Data concerning him
                  or her. Company may refer requests of Data Subjects received
                  in relation to Personal Data concerning them and the Data
                  Subjects making them, directly to the Customer for its
                  treatment of such requests. To the extent permitted under
                  Applicable Privacy Laws, the Customer shall be responsible for
                  any costs arising from Company's provision of such assistance.
                </li>
              </ol>
            </li>
            <li>
              <h4 className="underline">SECURITY & PERSONAL DATA BREACHES</h4>
              <ol>
                <li>
                  Company undertakes to implement security measures that comply
                  with the reasonable practices of the industry and in
                  accordance with the requirements under the Applicable Privacy
                  Laws.
                </li>
                <li>
                  Company will notify the Customer of any material personal data
                  breach affecting Personal Data ("Personal Data Breach")
                  without undue delay after becoming aware of the Personal Data
                  Breach, and reasonably assist the Customer in relation to any
                  Personal Data Breach notifications the Customer is required to
                  make under the Applicable Privacy Laws.
                </li>
                <li>
                  Company will take reasonable steps to mitigate the effects and
                  to minimize any damage resulting from the Personal Data Breach
                  to the extent mitigation is within Company's reasonable
                  control.
                </li>
              </ol>
            </li>
            <li>
              <h4 className="underline">TRANSFERS TO THIRD PARTY</h4>
              <ol>
                <li>
                  The Company undertakes to transfer the Agreement Personal Data
                  or grant access to it only to those employees, subcontractors,
                  representatives, and/or other third parties on its behalf that
                  will need the Agreement Personal Data for the purpose of
                  providing the services to the Customer.
                </li>
                <li>
                  The Company undertakes that Personal Data will be only
                  transferred to countries which are allowed under the
                  Applicable Privacy Laws.
                </li>
                <li>
                  <p>
                    Without limitation to the generality of section 7.2, the
                    Company shall ensure that transfers of Personal Data
                    collected from the Data Subjects in EU territory to
                    jurisdictions outside of the EU or the European Economic
                    Area ("EEA") are made only according to the following legal
                    methods:
                  </p>
                  <ol>
                    <li>
                      The transfer is to a jurisdiction deemed by the European
                      Commission to have an adequate level of protection; or
                    </li>
                    <li>
                      The transfer is to a country outside of the EEA which is
                      not subject to an adequacy decision under GDPR and the
                      Parties signed the EU SCCs.
                    </li>
                  </ol>
                </li>
              </ol>
            </li>
            <li>
              <h4 className="underline">DELETION OR RETURN OF PERSONAL DATA</h4>
              <ol>
                <li>
                  The Company will delete or return to the Customer all Personal
                  Data in its possession following the cessation of the
                  Services, except to the extent Company is required by
                  applicable law to retain some or all of the Personal Data (in
                  which case Company will implement reasonable measures to
                  prevent the Personal Data from any further processing). The
                  terms of this Addendum will continue to apply to such Personal
                  Data.
                </li>
              </ol>
            </li>

            <li>
              <h4 className="underline">AUDITS</h4>
              <ol>
                <li>
                  Company will allow for and contribute to audits to demonstrate
                  compliance with this Addendum in accordance with the following
                  provisions:
                </li>
                <li>
                  The Customer shall provide at least six (6) weeks prior
                  written notice to Company of a request to perform an audit,
                  provided that any such request shall occur no more than once
                  in any twelve (12) calendar month period.
                </li>
                <li>
                  Company will inform the Customer if it has conducted an audit
                  of its data protection and data security procedures in the
                  preceding twelve (12) calendar month period, in which case the
                  Customer agrees to exercise any right it may have to conduct
                  an audit under this Addendum by instructing Company to provide
                  Customer with a summary of such most recent relevant audit
                  report, which shall be considered Company’s confidential
                  information.
                </li>
                <li>
                  Any audit shall be conducted by a mutually agreed upon
                  independent third-party auditor who is engaged and paid by the
                  Customer and is under a non-disclosure agreement requiring the
                  auditor to maintain the confidentiality of all Company’s
                  confidential information and all audit findings. All audits
                  shall be conducted during normal business hours, at Company’s
                  principal place of business or other location(s) where
                  Personal Data is processed. Any such audit will result in the
                  generation of an audit report, which shall be considered
                  Company’s confidential information. At the Customer’s written
                  request, Company will make available to the Customer a summary
                  of the relevant audit report.
                </li>
                <li>
                  The scope of any audit will be limited to Company’s policies,
                  procedures, and controls relevant to the processing of
                  Personal Data.
                </li>
                <li>
                  If legally required, the Company shall permit the Supervisory
                  Authorities to conduct a data protection audit with regard to
                  the Processing carried out by the Company.
                </li>
                <li>
                  If, pursuant to Applicable Privacy Laws, the Customer is
                  required to perform a data protection impact assessment or
                  prior consultation with Authorities, at the Customer's
                  request, the Company shall provide such documents as are
                  generally available for the Services. Any additional
                  assistance shall be mutually agreed between the Parties.
                </li>
              </ol>
            </li>
            <li>
              <h4 className="underline">MISCELLANEOUS</h4>
              <ol>
                <li>
                  For the avoidance of doubts, the limitation of liability and
                  indemnification provisions of the Agreement apply to this
                  Addendum as well.
                </li>
                <li>
                  This Addendum shall continue to be in force until the
                  termination of the Agreement.
                </li>
                <li>
                  Regarding the subject matter of this Addendum, in the event of
                  inconsistencies between the provisions of this Addendum and
                  any other agreements between the Parties, including the
                  Agreement, the provisions of this Addendum shall prevail.
                </li>
                <li>
                  The Parties hereby submit to the choice of jurisdiction
                  stipulated in the Agreement with respect to any disputes or
                  claims howsoever arising under this Addendum, and all
                  non-contractual or other obligations arising out of or in
                  connection with it are governed by the laws of the country or
                  territory stipulated for this purpose in the Agreement.
                </li>
                <li>
                  If any provision of this Addendum is held by a court of
                  competent jurisdiction to be unenforceable under Applicable
                  Law, then such provision shall be excluded from this Addendum
                  and the remainder of this Addendum shall be interpreted as if
                  such provision was so excluded and shall be enforceable in
                  accordance with its terms; provided, however, that in such
                  event this Addendum shall be interpreted so as to give effect,
                  to the greatest extent consistent with and permitted by
                  applicable law, to the meaning and intention of the excluded
                  provision as determined by such court of competent
                  jurisdiction.
                </li>
              </ol>
            </li>
          </ol>
          <br />
          <h4 className="text-center">Schedule I</h4>
          <h4 className="text-center">
            Description of Agreement Personal Data Processing
          </h4>
          <br />
          <ol>
            <li>
              <h4 className="underline">TYPES OF PERSONAL DATA:</h4> The
              following data of the Customer:
              <ul>
                <li>Customer's users information</li>
                <li>
                  Any other information entered by the Customer including: names
                  and surnames, IP addresses, email addresses, phone numbers,
                  etc.
                </li>
              </ul>
            </li>
            <li>
              <h4 className="underline">CATEGORIES OF PERSONAL DATA:</h4>No
              special categories of Personal Data are processed as part of the
              Agreement.
            </li>
            <li>
              <h4 className="underline">DATA SUBJECTS:</h4>The Personal Data
              transferred concerns the following categories of data subjects:
              Customer's representatives and end-users.
            </li>
            <li>
              <h4 className="underline">DATA SUBJECTS:</h4>The Personal Data
              transferred concerns the following categories of data subjects:
              Customer's representatives and end-users.
            </li>
            <li>
              <h4 className="underline">DURATION OF PROCESSING:</h4>The
              processing will begin on the effective date of the Agreement and
              will end upon expiration or termination of the Agreement.
            </li>
            <li>
              <h4 className="underline">NATURE AND PURPOSE OF PROCESSING:</h4>
              The nature and purposes of processing carried out by the Company
              in order to provide the services.
            </li>
          </ol>
          <br />
          <h4 className="text-center">Schedule II</h4>
          <h4 className="text-center">List of the Company's subcontractors</h4>
          <p className="underline">
            Please request the list by contacting Office@cisotronix.com.
          </p>
          <br />
          <br />
          <h4 className="text-center">Schedule III</h4>
          <h4 className="text-center">
            Technical and Organizational Measures to be Implemented by the
            Company
          </h4>
          <br />
          <ol>
            <li>
              <strong>Information security program</strong> A written security
              program is implemented, maintained, and complied with. As part of
              the program, the Company will: (i) implement an audit program to
              test and, if necessary, remediate identified gaps of all security
              controls at least annually or whenever there is a material change
              in business practices that may reasonably implicate the security
              or integrity of records containing Personal Data; (ii) conduct, in
              line with an annual risk assessment that assesses the threats and
              vulnerabilities associated with systems; and (iii) produce
              (pursuant to the results of (i) and (ii)) a documented risk
              assessment and, where appropriate, risk remediation plan
            </li>
            <li>
              <strong>Security official.</strong> A designated management level
              or above security official is responsible for the development,
              implementation, and ongoing maintenance of the information
              security program. The appointed official has appropriate
              recognized information security credentials and qualifications.
            </li>
            <li>
              <strong>Access control.</strong> Access rights are assigned
              according to the principle that employees and third parties are
              only granted the level of access they need to perform their
              activities (need-to-know principle). Access rights are granted
              according to defined (role-based) permissions. The access rights
              granted are reviewed regularly. Rights that are no longer required
              are withdrawn immediately.
            </li>
            <li>
              <strong>Physical access control.</strong> Secure areas are defined
              based on information security and data protection requirements and
              protected against unauthorized access by appropriate physical
              safeguards, defined based on the protection needs of the
              information located or accessed within them. Including but not
              limited to: locking of server rooms; alert mechanisms for
              unauthorized access; rooms and building entry controls, and;
              implementation of well-known security standards by data storage
              providers.
            </li>
            <li>
              <strong>Logical access control.</strong> Password procedures are
              implemented, including length, complexity, and locking due to
              failed logins. Limitations placed on the number of authorized
              users. Remote connection to Company's systems is made using a
              trusted VPN service. Endpoint devices are updated, encrypted, and
              protected by spyware and virus protection programs.
            </li>
            <li>
              <strong>Incident response plan.</strong> Policies and procedures
              are implemented, designed to detect, respond to, and otherwise
              address incidents, including specific points of contact in the
              event of an incident, and procedures to: (i) monitor and detect
              actual and attempted attacks on, or intrusions into, the
              processing systems, (ii) identify and respond to suspected or
              known incidents, (iii) immediately mitigate the harmful effects of
              any incidents without detriment to measures or actions necessary
              to determine the seriousness of the breach.
            </li>
            <li>
              <strong>System Testing and Maintenance.</strong> The Company tests
              and maintains systems to protect data including, without
              limitation: (i) installing security patches and updates for
              operating systems and applications within a reasonable time of
              publication, and (ii) installing the latest recommended versions
              of operating systems, software and firmware for all system
              components, where possible.
            </li>
            <li>
              <strong>Availability and resilience.</strong> The Company
              implements measures to ensure the Data is protected from
              accidental destruction or loss, including without limitation: (i)
              performing routine database backup and mirroring of servers; (ii)
              using updated antivirus and antimalware programs and firewalls
              updated to the latest requirements.
            </li>
            <li>
              <strong>Audit logging.</strong> Technical mechanisms are
              implemented and maintained to record and examine activity in
              processing systems that contain or use electronic information,
              including appropriate logs and reports concerning the security
              requirements set forth in this Schedule.
            </li>
            <li>
              <strong>Security awareness and privacy training.</strong> An
              ongoing security and privacy awareness and training program is
              maintained for all employees (including management, employees,
              contractors, and other agents), which includes training on how to
              implement and comply with the information security program and
              setting forth disciplinary measures for violation of the security
              program. Security and privacy awareness training is conducted at
              least annually.
            </li>
          </ol>
        </div>
      </Modal.Body>
      <hr />
      <Modal.Footer>
        {!isAfterRegister ? (
          <>
            <div
              className="d-flex justify-content-center align-items-center w-25 cancel-agree"
              onClick={onDecline}
            >
              Decline
            </div>
            <Button
              className="w-50"
              variant="primary"
              onClick={onContinue}
              disabled={!bottom}
            >
              Agree and Continue
            </Button>
          </>
        ) : (
          <div className="w-100 text-center">
            <Button variant="primary" onClick={onDecline} className="w-fit-content px-3">
              Close
            </Button>
          </div>
        )}
        {/* <div className="w-100 text-center">
        <span className="text-white">Have questions? </span>
          {isContactLink ? (
            <Link to="/contact" onClick={onHide}>
            Contact us.
            </Link>
          ) : (
            <>
            <a href="mailto:office@cisotronix.co.il">office@cisotronix.co.il</a>

            </>
          )}
        </div> */}
      </Modal.Footer>
    </Modal>
  );
};

export default TermsConditionsModal;
