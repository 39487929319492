export const discoveredByFilterOptions = [
    {
      id: 0,
      name: "Armory",
      type: "Discovered By: Armory",
      key: "8",
      active: false,
    },
    {
      id: 1,
      name: "User’s input",
      type: "Discovered By: User’s input",
      key: "8",
      active: false,
    },
  ];

  export const networkZoneFilterOptions = [
    {
      id: 0,
      name: "On-Premise",
      type: "Network Zone: On-Premise",
      key: "11",
      active: false,
    },
    {
      id: 1,
      name: "Cloud-Hosted",
      type: "Network Zone: Cloud-Hosted",
      key: "11",
      active: false,
    },
  ];

  export const criticalityFilterOptions = [
    {
      id: 0,
      name: "Low",
      type: "Asset Criticality: Low",
      key: "13",
      active: false,
    },
    {
      id: 1,
      name: "Medium",
      type: "Asset Criticality: Medium",
      key: "13",
      active: false,
    },
    {
      id: 2,
      name: "High",
      type: "Asset Criticality: High",
      key: "13",
      active: false,
    },
  ];

  export const firstDetectedFilterOptions = [
    {
      id: 0,
      name: "Last 24 Hours",
      type: "First Detected: Last 24 Hours",
      key: "14",
      active: false,
    },
    {
      id: 1,
      name: "Last 7 Days",
      type: "First Detected: Last 7 Days",
      key: "14",
      active: false,
    },
    {
      id: 2,
      name: "Last 30 Days",
      type: "First Detected: Last 30 Days",
      key: "14",
      active: false,
    },
  ];