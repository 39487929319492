import React from "react";
import { Modal, Button } from "react-bootstrap";

const StopScannerConfirmation = ({ show, onConfirm, onCancel }) => {
  return (
    <Modal
      centered
      aria-labelledby="contained-modal-title-vcenter"
      backdrop="static"
      keyboard={false}
      className="base-modal"
      show={show}
      onHide={onCancel}
    >
      <Modal.Header closeButton={false} className="border-0">
        <Modal.Title>Confirm Stop</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <span className="text-white">
          {" "}
          Are you sure, want to stop the current scan?
        </span>
      </Modal.Body>
      <Modal.Footer className="border-0">
        <div className="d-flex justify-content-end align-items-center">
          <Button
            className="w-auto bg-transparent me-2 text-primary"
            onClick={onCancel}
          >
            Cancel
          </Button>
          <Button
            className="w-auto bg-transparent text-primary"
            onClick={onConfirm}
          >
            Yes
          </Button>
        </div>
      </Modal.Footer>
    </Modal>
  );
};

export default StopScannerConfirmation;
