import { ReactComponent as APIKey } from "../assets/images/sensitive-api-key.svg";
import { ReactComponent as BackUp } from "../assets/images/sensitive-backup.svg";
import { ReactComponent as CloudKey } from "../assets/images/sensitive-cloud-key.svg";
import { ReactComponent as Configuration } from "../assets/images/sensitive-configuration.svg";
import { ReactComponent as Database } from "../assets/images/sensitive-database.svg";
import { ReactComponent as Debugging } from "../assets/images/sensitive-debugging.svg";
import { ReactComponent as Exposed } from "../assets/images/sensitive-exposed.svg";
import { ReactComponent as Infrastructure } from "../assets/images/sensitive-infrastructure.svg";
import { ReactComponent as PrivateKey } from "../assets/images/sensitive-private-key.svg";
import { ReactComponent as SSHKey } from "../assets/images/sensitive-ssh-key.svg";
import { ReactComponent as UserAuth } from "../assets/images/sensitive-user-auth.svg";
import { ReactComponent as PastesExposure } from "../assets/images/sensitive-pastes.svg";

export const allCategories = [
  "API Keys",
  "CMS Configuration File",
  "Cloud Keys",
  "Directory & Backup Exposures",
  "Exposed Devices",
  "PasteBin Search and File Leaks",
  "SSH Keys",
  "Secret & Private Keys",
  "Server Configuration & Database",
  "Server Software & Infrastructure",
  "System Logs & Debugging",
  "User Information & Authentication",
];

export const getSensitiveCategories = (convertedCategories) => [
  {
    label: "API Keys",
    value: convertedCategories["api_keys"],
    icon: <APIKey className="mb-2" />,
  },
  {
    label: "Secret & Private Keys",
    value: convertedCategories["secret_private_keys"],
    icon: <PrivateKey className="mb-2" />,
  },
  {
    label: "Cloud Keys",
    value: convertedCategories["cloud_keys"],
    icon: <CloudKey className="mb-2" />,
  },
  {
    label: "SSH Keys",
    value: convertedCategories["ssh_keys"],
    icon: <SSHKey className="mb-2" />,
  },
  {
    label: "Server Configuration & Database",
    value: convertedCategories["server_configuration_database"],
    icon: <Database className="mb-2" />,
  },
  {
    label: "System Logs & Debugging",
    value: convertedCategories["system_logs_debugging"],
    icon: <Debugging className="mb-2" />,
  },
  {
    label: "Directory & Backup Exposures",
    value: convertedCategories["directory_backup_exposures"],
    icon: <BackUp className="mb-2" />,
  },
  {
    label: "CMS Configuration Files",
    value: convertedCategories["cms_configuration_file"],
    icon: <Configuration className="mb-2" />,
  },
  {
    label: "Server Software & Infrastructure",
    value: convertedCategories["server_software_infrastructure"],
    icon: <Infrastructure className="mb-2" />,
  },
  {
    label: "Exposed Devices",
    value: convertedCategories["exposed_devices"],
    icon: <Exposed className="mb-2" />,
  },
  {
    label: "User Information & Authentication",
    value: convertedCategories["user_information_authentication"],
    icon: <UserAuth className="mb-2" />,
  },
  {
    label: "Pastes Exposure",
    value: convertedCategories["pastebin_search_and_file_leaks"],
    icon: <PastesExposure className="mb-2" />,
  },
];
