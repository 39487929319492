import React from "react";
import { Modal, Button } from "react-bootstrap";
import axios from "../../util/axios";
import { useParams } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";

const GenericActionModal = ({
  showModal,
  handleClose,
  actionType,
  onConfirm,
  groupData,
  type,
}) => {
  const routeParams = useParams();

  const statusMap = {
    Open: { value: 1 },
    Solve: { value: 3 },
    "False-Positive": { value: 6 },
    "Acknowledge Risk": { value: 7 },
    Restore: { value: 2 },
  };
  const onYes = async () => {
    const getStatusValue = (actionType) => {
      const statusEntry = Object.keys(statusMap).find(
        (key) => key === actionType
      );
      return statusEntry ? statusMap[statusEntry].value : null;
    };

    const statusValue = getStatusValue(actionType);
    let issues;

    if (type === 0) {
      issues = {
        status: statusValue,
        issues: [groupData.target_isssue_id || groupData.id],
      };
    } else if (type === 1) {
      if (groupData.assets && Array.isArray(groupData.assets)) {
        issues = {
          status: statusValue,
          issues: groupData.assets.map((asset) => asset.id),
        };
      }
    } else if (type === 2) {
      const groupIssues = [];
      groupData.forEach((group) => {
        if (group.assets && Array.isArray(group.assets)) {
          groupIssues.push(...group.assets.map((asset) => asset.id));
        } else {
          groupIssues.push(group.target_isssue_id);
        }
      });
      issues = {
        status: statusValue,
        issues: groupIssues,
      };
    }

    const apiUrl = `issues/${routeParams.id}/status`;
    try {
      const response = await axios.post(apiUrl, issues);

      if (statusValue === 6 || statusValue === 7) {
        showSuccessToast();
      }
      onConfirm(statusValue, actionType);
      handleClose();
    } catch (e) {
      console.error("API call failed:", e);
    }
  };
  const data = (
    <div>
      <p className="toast-text m-0 pb-1 fw-600 fs-6">
        The issues were successfully moved to the History page.
      </p>
      <p className=" toast-text m-0 fw-600 fs-12">
        The issues can be reviewed or restored from the History tab as needed.
      </p>
    </div>
  );
  const showSuccessToast = () => {
    toast.success(data, {
      position: "top-center",
      autoClose: 3000,
      style: { width: "500px" },
    });
  };

  const renderModalContent = () => {
    let issuesCount = 0;

    // Determine the number of issues based on the type and groupData structure
    if (type === 0) {
      issuesCount = 1; // Only 1 issue for type 0
    } else if (type === 1 && groupData?.assets?.length) {
      issuesCount = groupData.assets.length; // Count of assets in groupData for type 1
    } else if (type === 2 && Array.isArray(groupData)) {
      issuesCount = groupData.reduce((acc, group) => {
        return acc + (group.assets ? group.assets.length : 1); // Sum up asset counts or individual issues in each group
      }, 0);
    }

    const isMultipleIssues = issuesCount > 1; // Check if there are multiple issues

    switch (actionType) {
      case "Solve":
        return (
          <>
            <h3 className="securityissueCard_modal_header fw-bold">
              Are you sure you want to Solve{" "}
              {isMultipleIssues ? `These ${issuesCount} Issues` : "the Issue"}?
            </h3>
            <div className="securityissueCard-modal_content">
              These issues will be marked as 'Pending Verification' until the
              next scan.
              <label className="my-3">
                If the issues are solved, they will be marked as 'Solved' and
                moved to history.
              </label>
              <label>
                If the issues persist, the status will change to 'Open' with
                'Verification Failed'.
              </label>
            </div>
          </>
        );
      case "False-Positive":
        return (
          <>
            <h3 className="securityissueCard_modal_header fw-bold">
              Are you sure you want to Mark{" "}
              {isMultipleIssues ? `These ${issuesCount} Issues` : "This Issue"}{" "}
              as False Positive?
            </h3>
            <div className="securityissueCard-modal_content">
              The issue will be archived in the history page, with the option to
              restore it if necessary.
            </div>
          </>
        );
      case "Acknowledge Risk":
        return (
          <>
            <h3 className="securityissueCard_modal_header fw-bold">
              Are you sure you want to Acknowledge the Risk of{" "}
              {isMultipleIssues ? `These ${issuesCount} Issues` : "This Issue"}?
            </h3>
            <div className="securityissueCard-modal_content">
              The issue will be archived in the history page, with the option to
              restore it if necessary.
            </div>
          </>
        );
      case "Restore":
        return (
          <>
            <h3 className="securityissueCard_modal_header fw-bold">
              Are you sure you want to Restore{" "}
              {isMultipleIssues ? `These ${issuesCount} Issues` : "This Issue"}?
            </h3>
            <div className="securityissueCard-modal_content">
              Restoring will move these issues back to the open issues page with
              the status of 'Re-opened', and they will be monitored in the next
              scan.
            </div>
          </>
        );
      default:
        return null;
    }
  };

  return (
    <Modal show={showModal} onHide={handleClose} centered>
      <Modal.Body className="securityissueCard_modal p-4">
        {renderModalContent()}
        <div className="securityissueCard_modal_button">
          <Button
            className="securityissueCard_button fs-12"
            onClick={handleClose}
          >
            Cancel
          </Button>
          <Button className="securityissueCard_button fs-12" onClick={onYes}>
            {actionType === "Solve" || actionType === "Restore"
              ? actionType
              : "Yes"}
          </Button>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default GenericActionModal;
