import React, {
  useState,
  useEffect,
  useMemo,
  useContext,
  useRef,
  useCallback,
} from "react";
import BaseTable from "../../components/table/BaseTable";
import { ReactComponent as FaCheckCircle } from "../../assets/images/FaCheckCircle.svg";
import { ReactComponent as FaExclamationCircle } from "../../assets/images/inactive-status-red.svg";
import BaseFilter from "../../components/filter/BaseFilter";
import ChartDataLabels from "chartjs-plugin-datalabels";
import CurrentStatus from "../../components/status/CurrentStatus.js";
import axios from "../../util/axios";
import moment from "moment";
import { OverlayTrigger, Popover } from "react-bootstrap";
import { ThreeCircles } from "react-loader-spinner";
import GenericCard from "../../components/card/GenericCard.js";
import DoughnutChart from "../../components/charts/DoughnutChart";
import NoData from "../../components/empty/NoData.js";
import Data from "../../components/summary/AssetSummary.jsx";
import { NoDataAfterScanning } from "../../components/empty/NoDataAfterScanning.js";
import { ReactComponent as GlobeIcon } from "../../assets/images/globe-blue.svg";
import { ReactComponent as IPAddressIcon } from "../../assets/images/ip.svg";
import { ReactComponent as HostnameIcon } from "../../assets/images/server.svg";
import { ReactComponent as LinkIcon } from "../../assets/images/external-link-icon.svg";
import { ServiceCallContext } from "../../components/ServiceCallContext.js";
import {
  useNavigate,
  useParams,
  useLocation,
  useOutletContext,
} from "react-router-dom";
import { formatScannerName } from "../../helpers/formatScannerName.js";
import { ScannerContext } from "../../components/ScannerContext";
import { locationToPageName } from "../../helpers/index.js";
import { sendEvent } from "../../util/analytics.js";
import { webUpdatesFilter } from "../../util/genericFunctions.js";
import AssetsModal from "./AssetsModal.js";
import { showToast } from "../../util/toasts.js";
import ClickableBarChart from "./CriticalityBarChart.js";
import { parseFilterString, applyCondition } from "../../util/conditions";
import { assetsDiscoveryTabsConfig } from "../../util/tabsConfig.js";
import BulkSelection from "../../components/bulk-selection/BulkSelection.js";

const colorList = ["#FFFFFF", "#3CE2C1", "#87C2F3", "#A7A7B1"];

const doughnutChartOptions = {
  responsive: true,
  maintainAspectRatio: false,
  cutout: "70%",
  plugins: {
    legend: {
      display: false,
    },
    tooltip: {
      backgroundColor: "white",
      titleColor: "black",
      bodyColor: "black",
      callbacks: {
        label: function (context) {
          const label = context.dataset.label || "";
          const value = context.raw;
          return `${label}: ${value}`;
        },
      },
      yAlign: "bottom",
      xAlign: "center",
    },
    title: {
      display: false,
    },
    centerText: {
      display: true,
      text: "70",
      color: "#FF0000",
      fontStyle: "bold",
      fontSize: 24,
    },
  },
  elements: {
    arc: {
      borderWidth: 0,
      borderColor: "transparent",
    },
  },
  onHover: (event, elements) => {
    event.native.target.style.cursor = elements.length ? "pointer" : "default";
  },
};

const AssetsDiscovery = ({
  data,
  servicesStatus,
  assetsStatus,
  fetchScans,
}) => {
  const { dropdownItems } = useContext(ScannerContext);
  const { triggerServiceCall } = useContext(ServiceCallContext);
  const navigate = useNavigate();
  const location = useLocation();
  const { scanningStatus } = useOutletContext();
  const routeParams = useParams();
  const [targetScan, setTargetScan] = useState({});
  const [noDataDoughnutChart, setNoDataDoughnutChart] = useState(false);
  const [isDatainProcess, setIsDataInProcess] = useState(false);
  const [activeFilters, setActiveFilters] = useState([]);
  const [searchValue, setSearchValue] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [selectedAssets, setSelectedAssets] = useState(null);
  const [rejectIP, setRejectIP] = useState([]);
  const [rejectAction, setRejectAction] = useState(2);
  const [hoveredPort, setHoveredPort] = useState(null);
  const [showPopover, setShowPopover] = useState(false);
  const [statsData, setStatsData] = useState({
    totalHostnames: 0,
    totalActiveHostnames: 0,
    totalIps: 0,
    totalActiveIps: 0,
    totalDomain: 0,
  });
  const initialColumns = [
    { header: "Domain", key: "domain", visibility: true, default: true },
    { header: "Hostname", key: "host", visibility: true, default: true },
    { header: "IP Address", key: "ip", visibility: true, default: true },
    { header: "Status", key: "statusValue", visibility: true },
    { header: "Hosting Provider", key: "hosting_provider", visibility: true },
    { header: "CDN", key: "cdn_name", visibility: true },
    { header: "Network Zone", key: "network_zone", visibility: true },
    { header: "Service Category", key: "service_category", visibility: true },
    { header: "Web Interface", key: "web_interface", visibility: true },
    { header: "Asset Criticality", key: "asset_criticality", visibility: true },
    { header: "Security Issues", key: "number_issues", visibility: true },
    { header: "Discovered By", key: "discovered_by", visibility: true },
    { header: "First Detected", key: "first_detected", visibility: true },
    { header: "Current State", key: "current_state", visibility: true },
  ];
  const [allColumnsObj, setAllColumnsObj] = useState([]);
  const [doughnutChartType, setDoughnutChartType] = useState({
    labels: ["Unchanged", "New", "Changed", "Removed"],
    datasets: [
      {
        data: [100, 20, 30, 30],
        backgroundColor: colorList,
        hoverBackgroundColor: colorList,
        borderColor: "transparent",
        borderWidth: 0,
      },
    ],
  });
  const [tabs, setTabs] = useState([]);

  const discoveredByFilterOptions = [
    {
      id: 0,
      name: "Armory",
      type: "Discovered By: Armory",
      key: "8",
      active: false,
    },
    {
      id: 1,
      name: "User’s input",
      type: "Discovered By: User’s input",
      key: "8",
      active: false,
    },
  ];

  const networkZoneFilterOptions = [
    {
      id: 0,
      name: "On-Premise",
      type: "Network Zone: On-Premise",
      key: "11",
      active: false,
    },
    {
      id: 1,
      name: "Cloud-Hosted",
      type: "Network Zone: Cloud-Hosted",
      key: "11",
      active: false,
    },
  ];

  const criticalityFilterOptions = [
    {
      id: 0,
      name: "Low",
      type: "Asset Criticality: Low",
      key: "13",
      active: false,
    },
    {
      id: 1,
      name: "Medium",
      type: "Asset Criticality: Medium",
      key: "13",
      active: false,
    },
    {
      id: 2,
      name: "High",
      type: "Asset Criticality: High",
      key: "13",
      active: false,
    },
  ];

  const firstDetectedFilterOptions = [
    {
      id: 0,
      name: "Last 24 Hours",
      type: "First Detected: Last 24 Hours",
      key: "14",
      active: false,
    },
    {
      id: 1,
      name: "Last 7 Days",
      type: "First Detected: Last 7 Days",
      key: "14",
      active: false,
    },
    {
      id: 2,
      name: "Last 30 Days",
      type: "First Detected: Last 30 Days",
      key: "14",
      active: false,
    },
  ];

  const updateTabsWithData = (data) => {
    const domainName = Array.from(new Set(data.map((el) => el.domain)))
      ?.filter((name) => name)
      .map((item) => ({
        id: item,
        name: item,
        type: "Domain: " + item,
        key: "1",
        active: false,
      }));
    const categoryList = [];
    data?.map((el) => {
      el?.scan_ip_categories?.map((service) => {
        categoryList.push(service.service_category.title);
      });
    });
    const serviceCategorName = Array.from(new Set(categoryList.map((el) => el)))
      ?.filter((name) => name)
      .map((item) => ({
        id: item,
        name: item,
        type: "Service Category: " + item,
        key: "12",
        active: false,
      }));
    const hostName = Array.from(new Set(data.map((el) => el.host)))
      ?.filter((name) => name)
      .map((item) => ({
        id: item,
        name: item,
        type: "Hostname: " + item,
        key: "2",
        active: false,
      }));

    const ipName = Array.from(new Set(data.map((el) => el.ip)))
      ?.filter((name) => name)
      .map((item) => ({
        id: item,
        name: item,
        type: "IP Address: " + item,
        key: "3",
        active: false,
      }));

    const statusName = Array.from(new Set(data.map((el) => el.statusValue)))
      ?.filter((name) => name)
      .map((item) => ({
        id: item,
        name: item,
        type: "Status: " + item,
        key: "4",
        active: false,
      }));

    const hostingName = Array.from(
      new Set(data.map((el) => el.hosting_provider))
    )
      ?.filter((name) => name)
      .map((item) => ({
        id: item,
        name: item,
        type: "Hosting Provider: " + item,
        key: "5",
        active: false,
      }));
    const secutiryIssues = Array.from(
      new Set(data.map((el) => el.number_issues))
    )
      ?.filter((name) => name)
      .map((el, index) => ({
        id: index,
        name: `${el}`,
        type: "Security Issues: " + `${el}`,
        key: "9",
        active: false,
      }));
    const cdnName = Array.from(new Set(data.map((el) => el.cdn_name)))
      ?.filter((name) => name)
      .map((item) => ({
        id: item,
        name: item,
        type: "CDN: " + item,
        key: "6",
        active: false,
      }));
    const ports = Array.from(
      new Set(
        data.flatMap((host) =>
          host.scan_ports.map((scanHost) => `${scanHost.ports?.port}`)
        )
      )
    ).map((server) => ({
      id: server,
      name: server,
      type: "Port: " + server,
      key: "7",
      active: false,
    }));

    const webInterfaceName = Array.from(
      new Set(
        data.map((el) => {
          if (
            el.host_status_code === null ||
            el.host_status_code === "" ||
            el.host_status_code === undefined
          ) {
            return "No";
          } else {
            return `Yes (Status Code ${el.host_status_code})`;
          }
        })
      )
    )
      ?.filter((name) => name)
      .map((item) => ({
        id: item,
        name: item,
        type: "Web Interface: " + item,
        key: "15",
        active: false,
      }));

    setTabs(
      assetsDiscoveryTabsConfig({
        domainName,
        hostName,
        ipName,
        statusName,
        hostingName,
        cdnName,
        networkZoneFilterOptions,
        serviceCategorName,
        webInterfaceName,
        criticalityFilterOptions,
        secutiryIssues,
        discoveredByFilterOptions,
        firstDetectedFilterOptions,
      })
    );
  };

  const handleRowSelect = (selectedRow) => {
    const selectedIpsToAdd = [];
    const selectedIpsToRemove = [];
    Object.entries(selectedRow).forEach(([rowId, isSelected]) => {
      const selectedData = filteredData.find((row) => row.id === Number(rowId));
      if (selectedData) {
        const relatedHosts = data
          .filter((el) => el.ip_id == selectedData?.ip_id && el.host)
          .map((el) => el.host);
        const ipData = {
          domain_id: selectedData?.domain_id,
          ip_id: selectedData?.ip_id,
          ip: selectedData?.ip,
          host_id: selectedData?.host_id,
          host: selectedData?.host,
          id: selectedData?.id,
          relatedHosts,
        };
        if (isSelected) {
          selectedIpsToAdd.push(ipData);
        } else {
          selectedIpsToRemove.push(ipData);
        }
      }
    });

    setRejectIP((prevIps) => {
      const updatedIps = prevIps.filter(
        (ip) =>
          !selectedIpsToRemove.some(
            (selectedIp) => selectedIp.ip_id === ip.ip_id
          )
      );
      return [...updatedIps, ...selectedIpsToAdd];
    });
    setRejectIP(selectedIpsToAdd);
    setSelectedAssets(selectedRow);
  };
  const handleRejectSubmit = async () => {
    let endpoint = "";
    let payload = [];

    if (rejectAction === 1) {
      endpoint = `/scans/${routeParams?.id}/rejectHostname`;
      let emptyHostname = false;
      rejectIP.forEach((el) => {
        if (el?.host_id) {
          payload.push({
            host_id: el?.host_id,
          });
        } else {
          emptyHostname = true;
        }
      });
      if (emptyHostname) {
        showToast(
          `${
            rejectIP?.length === 1
              ? "The hostname is already rejected"
              : "These hostnames are already rejected"
          }`,
          "error",
          "top-center",
          "light"
        );
        return;
      }
    } else {
      endpoint = `/scans/${routeParams?.id}/rejects`;
      rejectIP.forEach((el) => {
        payload.push({
          domain_id: el?.domain_id,
          ip: el?.ip,
          ip_id: el?.ip_id,
        });
      });
    }
    const response = await axios.post(endpoint, payload);
    if (response?.status === 200) {
      showToast(response.data.message, "success", "top-center", "light");
      const pageName = locationToPageName(location);
      sendEvent("buttonClick", {
        pageName,
        result: "rejectIP",
        ip: rejectIP,
      });
      fetchScans();
      triggerServiceCall();
      setShowModal(false);
      setSelectedAssets(null);
      setRejectIP([]);
    }
  };

  const handleModifyCriticalitySubmit = async (rowItems, selectedValue) => {
    try {
      const criticalityMap = {
        High: 3,
        Medium: 2,
        Low: 1,
      };
      const criticality = criticalityMap[selectedValue];
      const selectedItems = Array.isArray(rowItems) ? rowItems : [rowItems];
      const isSameCriticality = selectedItems.every(
        (el) => el.criticality === criticality
      );
      if (isSameCriticality) {
        return;
      }

      const ips = selectedItems
        .filter((el) => el.ip && !el.host_id)
        .map((el) => el.ip_id);

      const hosts = selectedItems
        .filter((el) => el.host && el.host_id)
        .map((el) => el.host_id);

      const payload = {
        criticality,
        ips,
        hosts,
      };

      const response = await axios.post(
        `/assets/${routeParams?.id}/adjust-criticality`,
        payload
      );

      if (response.status === 200) {
        showToast(
          "Criticality modified successfully",
          "success",
          "top-center",
          "light"
        );
        fetchScans();
        setShowModal(false);
      }
    } catch (error) {
      showToast("Failed to modify criticality", "error", "top-center", "light");
    }
  };

  const handleFilterChange = (updatedActiveFilters, updatedTabs) => {
    setActiveFilters(updatedActiveFilters);
    setTabs(updatedTabs);
  };
  const removeFilter = (updatedFilters, updatedTabs) => {
    setActiveFilters(updatedFilters);
    setTabs(updatedTabs);
  };
  const handleSearchChange = (e) => {
    setSearchValue(e.target.value);
  };

  const handleMultipleAction = (item) => {
    setRejectAction(item.id);
    setShowModal(true);
  };

  const handleOnHide = (event) => {
    if (event) {
      event.stopPropagation();
    }
    setShowModal(false);
  };

  const handleAction = (item) => {
    const relatedHosts = data
      .filter((el) => el.ip_id === item.data?.ip_id && el.host)
      .map((el) => el.host);
    const ips = [
      {
        domain_id: item.data?.domain_id,
        ip_id: item.data?.ip_id,
        ip: item?.data?.ip,
        host_id: item?.data?.host_id,
        host: item?.data?.host,
        id: item?.data?.id,
        relatedHosts,
        criticality: item?.data?.criticality,
      },
    ];
    setRejectAction(item.selectedAction.id);
    setRejectIP(ips);
    setShowModal(true);
  };

  const SecurityIssueCard = useCallback(
    (data) => {
      if (data.number_issues > 0) {
        navigate(`/security-issues/${routeParams?.id}?ip=${data?.ip}`);
      }
    },
    [navigate, routeParams?.id]
  );

  const filteredColumns = useMemo(() => {
    const columns = [
      { Header: "Domain", accessor: "domain", key: "domain", isSortable: true },
      {
        Header: "Hostname",
        key: "host",
        accessor: (row) =>
          row?.host.length > 26 ? (
            <OverlayTrigger
              placement="top"
              overlay={
                <Popover
                  id={`tooltip-${row?.host}`}
                  className="custom-popover-arrow"
                >
                  <Popover.Body className="comming-tool-body">
                    <label className="comming-text-tool">{row?.host}</label>
                  </Popover.Body>
                </Popover>
              }
            >
              <div
                style={{
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  width: "190px",
                }}
              >
                {row?.host.slice(0, 26) + "..."}
              </div>
            </OverlayTrigger>
          ) : (
            row?.host
          ),
        isSortable: true,
      },
      { Header: "IP Address", accessor: "ip", key: "ip", isSortable: true },
      {
        Header: "Status",
        isSortable: true,
        key: "statusValue",
        width: 20,
        accessor: (row) => {
          let statusLabel;
          let statusIcon;
          let statusColor;
          let statusValue = "";
          if (!row?.is_processed && !row.host_status_code) {
            statusValue = "Validating";
          } else {
            if (row?.status || row.host_status_code) {
              statusValue = "Active";
            } else {
              statusValue = "Inactive";
            }
          }
          switch (statusValue) {
            case "Validating":
              statusLabel = "Validating";
              statusIcon = (
                <ThreeCircles height={18} width={18} color="white" />
              );
              statusColor = "#fff";
              break;
            case "Active":
              statusLabel = "Active";
              statusIcon = <FaCheckCircle />;
              statusColor = "#3DDC97";
              break;
            case "Inactive":
              statusLabel = "Inactive";
              statusIcon = <FaExclamationCircle />;
              statusColor = "#FF6155";
              break;
            default:
              break;
          }

          return (
            <div
              style={{
                color: statusColor,
                display: "flex",
                alignItems: "center",
              }}
            >
              {statusIcon}{" "}
              <span style={{ marginLeft: "5px" }}>{statusLabel}</span>
            </div>
          );
        },
      },
      {
        Header: "Hosting Provider",
        key: "hosting_provider",
        isSortable: true,
        width: 20,
        accessor: (row) => {
          let statusLabel = "Scanning";
          let statusIcon = (
            <ThreeCircles height={18} width={18} color="white" />
          );
          let statusColor = "#fff";

          return !row.hosting_provider &&
            servicesStatus?.find((s) => s.id == 7)?.status < 3 ? (
            <div
              style={{
                color: statusColor,
                display: "flex",
                alignItems: "center",
              }}
            >
              {statusIcon}{" "}
              <span style={{ marginLeft: "5px" }}>{statusLabel}</span>
            </div>
          ) : !row.hosting_provider ? (
            "-"
          ) : (
            row.hosting_provider
          );
        },
      },
      {
        Header: "CDN",
        key: "cdn_name",
        width: 25,
        isSortable: true,
        accessor: (row) => {
          let statusLabel = "Scanning";
          let statusIcon = (
            <ThreeCircles height={18} width={18} color="white" />
          );
          let statusColor = "#fff";
          let hostsCdn = "-";
          return !row.cdn_name &&
            servicesStatus?.find((s) => s.id === 9)?.status < 3 ? (
            <div
              style={{
                color: statusColor,
                display: "flex",
                alignItems: "center",
              }}
            >
              {statusIcon}{" "}
              <span style={{ marginLeft: "5px" }}>{statusLabel}</span>
            </div>
          ) : !row.cdn_name ? (
            hostsCdn
          ) : (
            row.cdn_name
          );
        },
      },
      {
        Header: "Network Zone",
        key: "network_zone",
        width: 25,
        isSortable: true,
        accessor: (row) => {
          if (row.network_zone === null) {
            return "-";
          }
          const text =
            row.network_zone === 1
              ? "On-Premise"
              : row.network_zone === 2
              ? "Cloud-Hosted"
              : "Unknown";
          return (
            <div className="cursor-pointer web-interface-container">{text}</div>
          );
        },
      },
      {
        Header: "Service Category",
        key: "service_category",
        width: 50,
        isSortable: false,
        accessor: (row) => {
          if (!row.scan_ip_categories || row.scan_ip_categories.length === 0) {
            return "-";
          }
          const serviceCategories = row.scan_ip_categories;
          const categoryArray = serviceCategories.map(
            (service) => service.service_category.title
          );
          const displayCategories = categoryArray.slice(0, 2);
          const remainingCategories = categoryArray.slice(2);
          const popover = (
            <Popover
              id={`popover-categories-${row.id}`}
              className="custom-popover-arrow"
            >
              <Popover.Body className="comming-tool-body d-grid scrollable-checkbox-list">
                {remainingCategories.map((category, i) => (
                  <span key={i} className="text-black port-box">
                    <span>·</span> {category}
                  </span>
                ))}
              </Popover.Body>
            </Popover>
          );

          return (
            <div className="">
              <div className="flex gap-2">
                {displayCategories.map((category, i) => (
                  <div
                    className="cursor-pointer service-categiry-chip w-mx-content"
                    key={i}
                  >
                    {category}
                  </div>
                ))}

                {remainingCategories.length > 0 && (
                  <OverlayTrigger
                    trigger={["hover", "focus"]}
                    placement="top"
                    overlay={popover}
                  >
                    <div className="cursor-pointer service-categiry-chip">
                      +{remainingCategories.length}
                    </div>
                  </OverlayTrigger>
                )}
              </div>
            </div>
          );
        },
      },
      {
        Header: "Web Interface",
        key: "web_interface",
        width: 25,
        isSortable: true,
        accessor: (row) => {
          const protocol = row.host_protocol || "https";
          const host = row.host;
          if (
            row.host_status_code === null ||
            row.host_status_code === "" ||
            row.host_status_code === undefined
          ) {
            return (
              <div className="cursor-pointer web-interface-container">No</div>
            );
          }
          return (
            <a
              href={`${protocol}://${host}`}
              target="_blank"
              rel="noopener noreferrer"
              className="web-interface-link"
            >
              <div className="cursor-pointer web-interface-container">
                <p className="underline m-0">
                  Yes (Status Code {row.host_status_code})
                </p>
                <LinkIcon />
              </div>
            </a>
          );
        },
      },
      {
        Header: "Asset Criticality",
        key: "asset_criticality",
        isSortable: true,
        accessor: (row) => {
          return (
            <div className="d-flex align-items-center">
              <div className="cursor-pointer">
                <ClickableBarChart
                  criticality={row?.criticality}
                  onCriticalityChange={(criticality) =>
                    handleModifyCriticalitySubmit(row, criticality)
                  }
                />{" "}
              </div>
            </div>
          );
        },
      },
      {
        Header: "Security Issues",
        key: "number_issues",
        isSortable: true,
        accessor: (row) => {
          return (
            <div
              onClick={() => {
                SecurityIssueCard(row);
              }}
              className="cursor-pointer"
              style={{
                textDecoration:
                  row?.number_issues > 0 ? "underline" : "initial",
              }}
            >
              {row?.number_issues || "-"}
            </div>
          );
        },
      },
      {
        Header: "Discovered By",
        key: "discovered_by",
        isSortable: true,
        accessor: (row) => (
          <div>
            {row?.discovered_by === 2
              ? "User’s input"
              : row?.discovered_by === 1
              ? "Armory"
              : "-"}
          </div>
        ),
      },
      {
        Header: "First Detected",
        key: "first_detected",
        isSortable: true,
        accessor: (row) => {
          return (
            <div>
              {row?.first_detected
                ? moment(row?.first_detected).format("DD/MM/YY")
                : ""}
            </div>
          );
        },
      },
      {
        Header: "Current State",
        key: "current_state",
        isSortable: true,
        accessor: (row) => {
          return (
            <div>
              {row?.change_status != null ? (
                <CurrentStatus
                  status={row?.change_status}
                  tooltipInfo={row?.changes}
                  headerKeys={{
                    hosting_provider: "Hosting Provider Name",
                    ip: "IP Address",
                    cdn_name: "CDN Name",
                    has_cdn: "CDN",
                    host: "Hostname",
                    cms: "CMS Name",
                    has_cms: "CMS",
                    port: "Port",
                    service: "Service",
                    product: "Name",
                    version: "Version",
                    is_encrypted: "Encrypted Protocol",
                    first_detected: "First Detected",
                    change_status: "Current State",
                    network_zone: "Network Zone",
                  }}
                />
              ) : (
                ""
              )}
            </div>
          );
        },
      },
    ];
    return columns.filter((column) => {
      const found = allColumnsObj.find((sc) => sc.key === column.key);
      return found ? found.visibility : false;
    });
  }, [
    SecurityIssueCard,
    allColumnsObj,
    hoveredPort,
    navigate,
    routeParams?.id,
    servicesStatus,
    showPopover,
  ]);

  const fetchManageColumns = async () => {
    try {
      const { data } = await axios.get(`userSettings`);
      const services = await axios.get(`/scans/${routeParams?.id}/services`);
      if (data?.columns) {
        let updatedColumns = [];
        initialColumns?.map((el) => {
          if (el?.default || data?.columns?.includes(el?.key)) {
            updatedColumns.push({
              ...el,
              visibility: true,
            });
          } else {
            updatedColumns.push({
              ...el,
              visibility: false,
            });
          }
        });
        setAllColumnsObj(updatedColumns);
      } else {
        setAllColumnsObj(initialColumns);
      }
      if (services?.data) {
        if (services?.data?.is_asset_scan_finished) {
          if (
            assetsStatus?.constant === 0 &&
            assetsStatus?.new === 0 &&
            assetsStatus?.changed === 0 &&
            assetsStatus?.removed === 0
          ) {
            setNoDataDoughnutChart(true);
          } else {
            setNoDataDoughnutChart(false);
          }
        } else {
          if (
            assetsStatus?.constant === 0 &&
            assetsStatus?.new === 0 &&
            assetsStatus?.changed === 0 &&
            assetsStatus?.removed === 0
          ) {
            setIsDataInProcess(true);
          } else {
            setIsDataInProcess(false);
          }
        }
      }
    } catch (err) {
      console.error(err);
      setAllColumnsObj(initialColumns);
    }
  };
  const updateColumnSettings = async () => {
    let columns = "";
    allColumnsObj?.forEach((el) => {
      if (el?.visibility) {
        columns += `${el?.key},`;
      }
    });
    try {
      await axios.post(`updateUserSettings`, {
        table_name: "ASSETS_INVENTORY",
        columns: columns,
      });
    } catch (err) {
      console.error(err);
    }
  };

  const getExportedRows = (dataForExport) => {
    const visibleKeys = allColumnsObj
      .filter((key) => key.visibility)
      .map((key) => key.key);

    const body = dataForExport.flatMap((ipData) => {
      const row = visibleKeys.map((key) => {
        switch (key) {
          case "domain":
          case "host":
          case "ip":
          case "hosting_provider":
          case "cdn_name":
            return `${ipData[key] || "-"}`;
          case "status":
            return !ipData.is_processed
              ? "Validating"
              : ipData.status
              ? "Active"
              : "Inactive";
          case "scan_ports":
            return `${
              ipData[key].length > 0
                ? ipData[key]?.map((hostData) => hostData.ports.port).join(", ")
                : "-"
            }`;
          case "issues":
            return `${ipData?.number_issues || "-"}`;
          case "discovered_by":
            return ipData[key] === 2
              ? "User’s input"
              : ipData[key] === 1
              ? "Armory"
              : "-";
          case "first_detected":
            return `${
              moment(ipData?.first_detected).format("DD/MM/YY") || "-"
            }`;
          case "current_state":
            return `${
              ipData?.change_status === 1
                ? "New"
                : ipData?.change_status === 2
                ? "Changed"
                : ipData?.change_status === 0
                ? "Unchanged"
                : ipData?.change_status === 3
                ? "Removed"
                : "-"
            }`;
          case "service_category":
            return `${
              ipData.scan_ip_categories?.length > 0
                ? ipData.scan_ip_categories
                    .map((category) => category.service_category.title)
                    .join(", ")
                : "-"
            }`;
          case "network_zone":
            return `${
              ipData.network_zone === 1
                ? "On-Premise"
                : ipData.network_zone === 2
                ? "Cloud-Hosted"
                : "-"
            }`;
          default:
            return ipData[key] || "-";
        }
      });
      return [row];
    });

    return body;
  };

  const columnsChanged = (prevColumns, currentColumns) => {
    return prevColumns.some(
      (col, index) => col.visibility !== currentColumns[index].visibility
    );
  };

  const groupedFilters = activeFilters.reduce((acc, filter) => {
    if (!acc[filter.eventKey]) {
      acc[filter.eventKey] = [];
    }
    acc[filter.eventKey].push(filter);
    return acc;
  }, {});

  const handleChartClick = (name) => {
    setActiveFilters(webUpdatesFilter(name, activeFilters, "8"));
  };

  const sortData = (data) => {
    return data.sort((a, b) => {
      const rankA = getRank(a);
      const rankB = getRank(b);

      if (rankA === rankB) {
        const hostA = a.host || "";
        const hostB = b.host || "";
        return hostA.localeCompare(hostB);
      }
      return rankA - rankB;
    });
  };

  const getRank = (el) => {
    if (
      el?.host &&
      (el.status === 1 || el.status === -1) &&
      el?.ip &&
      el?.is_processed
    ) {
      return 1;
    }
    if (
      el?.host &&
      (el.status === 1 || el.status === -1) &&
      el?.ip &&
      !el?.is_processed
    ) {
      return 2;
    }
    if (!el?.host && (el.status === 1 || el.status === -1) && el?.ip) {
      return 3;
    }
    if (el?.host && el.status === 0 && el?.ip) {
      return 3;
    }
    return 4;
  };

  const statusMap = { new: 1, changed: 2, unchanged: 0, removed: 3 };

  const filteredData = data.filter((target) => {
    const matchesFilters = Object.keys(groupedFilters).every((eventKey) => {
      return groupedFilters[eventKey].some((filter) => {
        const lowerCaseFilter = filter.name.toLowerCase();
        if (eventKey === "1") {
          return target.domain.toLowerCase() === lowerCaseFilter;
        } else if (eventKey === "3") {
          return target.ip && target.ip === lowerCaseFilter;
        } else if (eventKey === "2") {
          return target.host.toLowerCase() === lowerCaseFilter;
        } else if (eventKey === "5") {
          return (
            target.hosting_provider &&
            target.hosting_provider.toLowerCase() === lowerCaseFilter
          );
        } else if (eventKey === "4") {
          return (
            (lowerCaseFilter === "validating" && !target.is_processed) ||
            (lowerCaseFilter === "active" &&
              target.is_processed &&
              target.status === 1) ||
            (lowerCaseFilter === "inactive" &&
              target.is_processed &&
              !target.status)
          );
        } else if (eventKey === "6") {
          return (
            target.cdn_name && target.cdn_name.toLowerCase() === lowerCaseFilter
          );
        } else if (eventKey === "7") {
          return (
            target.scan_ports &&
            target.scan_ports.some(
              (port) =>
                port?.ports.port &&
                `${port.ports.port}`.toLowerCase() === lowerCaseFilter
            )
          );
        } else if (eventKey === "8") {
          return target?.change_status === statusMap[filter.name.toLowerCase()];
        } else if (eventKey === "9") {
          return Number(target?.number_issues) == Number(filter.name);
        } else if (eventKey === "10") {
          return (
            (filter.name === "User’s input" && target.discovered_by === 2) ||
            (filter.name === "Armory" && target.discovered_by === 1)
          );
        } else if (eventKey === "11") {
          return (
            (filter.name === "On-Premise" && target.network_zone === 1) ||
            (filter.name === "Cloud-Hosted" && target.network_zone === 2)
          );
        } else if (eventKey === "13") {
          return (
            (filter.name === "Low" && target.criticality === 1) ||
            (filter.name === "Medium" && target.criticality === 2) ||
            (filter.name === "High" && target.criticality === 3)
          );
        } else if (eventKey === "12") {
          return (
            target.scan_ip_categories &&
            target.scan_ip_categories.some(
              (port) =>
                port?.service_category.title &&
                `${port.service_category.title}`.toLowerCase() ===
                  lowerCaseFilter
            )
          );
        } else if (eventKey === "15") {
          if (filter.name?.toLowerCase() == "no") {
            return (
              target.host_status_code === null ||
              target.host_status_code === "" ||
              target.host_status_code === undefined
            );
          } else {
            return filter.name?.includes(target.host_status_code);
          }
        } else if (eventKey === "14") {
          return applyCondition(
            target.first_detected,
            filter.name.toLowerCase().replaceAll(" ", "_"),
            filter.name
          );
        } else if (eventKey === "advanced-filter") {
          const parsedFilters = parseFilterString(filter.name);
          return parsedFilters.every((ol) => {
            const { column, condition, value } = ol;
            switch (column) {
              case "domain":
                return applyCondition(
                  target.domain?.toLowerCase(),
                  condition,
                  value?.toLowerCase()
                );
              case "host":
                return applyCondition(
                  target.host?.toLowerCase(),
                  condition,
                  value?.toLowerCase()
                );
              case "ip":
                return applyCondition(
                  target.ip?.toLowerCase().toLowerCase(),
                  condition,
                  value?.toLowerCase()
                );
              case "status":
                return (
                  (value?.toLowerCase() === "validating" &&
                    !target.is_processed) ||
                  (value?.toLowerCase() === "active" &&
                    target.is_processed &&
                    target.status === 1) ||
                  (value?.toLowerCase() === "inactive" &&
                    target.is_processed &&
                    !target.status)
                );
              case "hosting_provider_discovery":
                return applyCondition(
                  target.hosting_provider?.toLowerCase(),
                  condition,
                  value?.toLowerCase()
                );
              case "cdn_name_discovery":
                return applyCondition(
                  target.cdn_name?.toLowerCase(),
                  condition,
                  value?.toLowerCase()
                );
              case "number_issues":
                return applyCondition(
                  `${target?.number_issues}`,
                  condition,
                  value
                );
              case "host_status_code":
                if (value?.toLowerCase() == "no") {
                  const isStatusCode =
                    target.host_status_code === null ||
                    target.host_status_code === "" ||
                    target.host_status_code === undefined;
                  return condition == "is_not" ? !isStatusCode : isStatusCode;
                } else {
                  return condition == "is_not"
                    ? !value?.includes(target.host_status_code)
                    : value?.includes(target.host_status_code);
                }
              case "first_detected":
                return applyCondition(target.first_detected, condition, value);
              case "port":
                return (
                  target.scan_ports &&
                  target.scan_ports.some((port) =>
                    applyCondition(
                      `${port.ports.port}`.toLowerCase(),
                      condition,
                      value?.toLowerCase()
                    )
                  )
                );
              case "discovered_by":
                return (
                  (value === "User’s input" && target.discovered_by === 2) ||
                  (value === "Armory" && target.discovered_by === 1)
                );
              case "current_state":
                return applyCondition(
                  target?.change_status,
                  condition,
                  statusMap[value.toLowerCase()]
                );
              case "network_zone":
                return (
                  (value === "On-Premise" && target.network_zone === 1) ||
                  (value === "Cloud-Hosted" && target.network_zone === 2)
                );
              case "service_category":
                return (
                  target.scan_ip_categories &&
                  target.scan_ip_categories.some(
                    (port) =>
                      port?.service_category.title &&
                      `${port.service_category.title}`.toLowerCase() ===
                        value?.toLowerCase()
                  )
                );
              case "criticality":
                return (
                  (value === "Low" && target.criticality === 1) ||
                  (value === "Medium" && target.criticality === 2) ||
                  (value === "High" && target.criticality === 3)
                );
              default:
                return false;
            }
          });
        }
        return false;
      });
    });

    const matchesSearch =
      searchValue === "" ||
      target.domain?.toLowerCase().includes(searchValue?.toLowerCase()) ||
      target.host?.toLowerCase().includes(searchValue?.toLowerCase()) ||
      target.hosting_provider
        ?.toLowerCase()
        .includes(searchValue?.toLowerCase()) ||
      target.cdn?.toLowerCase().includes(searchValue?.toLowerCase()) ||
      (target.ip &&
        typeof target.ip === "string" &&
        target.ip?.toLowerCase().includes(searchValue?.toLowerCase()));

    return matchesFilters && matchesSearch;
  });

  useEffect(() => {
    if (data?.length > 0) {
      updateTabsWithData(data);
      let totalHostnames = 0;
      let totalActiveHostnames = 0;
      let totalIps = [];
      let totalActiveIps = 0;
      let totalDomain = [];
      data?.map((el) => {
        if (!totalDomain?.includes(el?.domain)) {
          totalDomain?.push(el?.domain);
        }
        if (el.ip && totalIps?.filter((ol) => ol.ip == el.ip) == 0) {
          totalIps?.push({
            ip: el.ip,
            status: el.status,
          });
        }
        if (el?.host) {
          totalHostnames += 1;
          if ((el.status === 1 || el.status === -1) && el?.ip) {
            totalActiveHostnames += 1;
          }
        }
      });
      totalIps?.map((el) => {
        if (el.status === 1 || el.status === -1) {
          totalActiveIps += 1;
        }
      });
      setStatsData({
        totalHostnames,
        totalActiveHostnames,
        totalIps: totalIps?.length,
        totalActiveIps,
        totalDomain: totalDomain?.length,
      });
    }
  }, [data]);

  useEffect(() => {
    const targetScan = dropdownItems.filter((item) => {
      return item.target_uuid === routeParams?.id;
    })[0];
    setTargetScan(targetScan);
  }, [dropdownItems]);

  useEffect(() => {
    if (assetsStatus) {
      setDoughnutChartType({
        labels: ["Unchanged", "New", "Changed", "Removed"],
        datasets: [
          {
            data: [
              assetsStatus?.constant || 0,
              assetsStatus?.new || 0,
              assetsStatus?.changed || 0,
              assetsStatus?.removed || 0,
            ],
            backgroundColor: colorList,
            hoverBackgroundColor: colorList,
            borderColor: "transparent",
            borderWidth: 0,
          },
        ],
      });
    }
  }, [assetsStatus]);

  useEffect(() => {
    fetchManageColumns();
    if (location?.search?.includes("tab=1")) {
      setActiveFilters([
        {
          type: "Current State: Removed",
          eventKey: "8",
          name: "Removed",
        },
      ]);
      if (scanningStatus === 0) {
        setIsDataInProcess(true);
      } else {
        setIsDataInProcess(false);
        if (scanningStatus === 3 || scanningStatus === -1) {
          if (
            assetsStatus?.constant === 0 &&
            assetsStatus?.new === 0 &&
            assetsStatus?.changed === 0 &&
            assetsStatus?.removed === 0
          ) {
            setNoDataDoughnutChart(true);
          } else {
            setNoDataDoughnutChart(false);
          }
        } else if (scanningStatus === 2 || scanningStatus === 1) {
          if (
            assetsStatus?.constant === 0 &&
            assetsStatus?.new === 0 &&
            assetsStatus?.changed === 0 &&
            assetsStatus?.removed === 0
          ) {
            setNoDataDoughnutChart(true);
          } else {
            setNoDataDoughnutChart(false);
          }
        }
      }
    }
  }, []);

  useEffect(() => {
    if (allColumnsObj?.length > 0) {
      updateColumnSettings();
    }
  }, [allColumnsObj]);
  const handleSelectionAll = () => {
    const allSelected = {};
    const allSelectedIps = [];
  
    filteredData.forEach((item) => {
      allSelected[item.id] = true;
      allSelectedIps.push({
        domain_id: item?.domain_id,
        ip_id: item?.ip_id,
        ip: item?.ip,
        host_id: item?.host_id,
        host: item?.host,
        id: item?.id,
      });
    });
  
    setSelectedAssets(allSelected);
    setRejectIP(allSelectedIps);
  };

  return (
    <>
      <div className="row mb-4 ">
        <div className="col-6 h-50">
          <GenericCard
            children={
              <Data
                items={[
                  {
                    icon: GlobeIcon,
                    label: "Domains",
                    value: statsData?.totalDomain || 0,
                    active: null,
                  },
                  {
                    icon: IPAddressIcon,
                    label: "IP Addresses",
                    value: statsData?.totalIps || 0,
                    active: statsData?.totalActiveIps || 0,
                  },
                  {
                    icon: HostnameIcon,
                    label: "Hostnames",
                    value: statsData?.totalHostnames || 0,
                    active: statsData?.totalActiveHostnames || 0,
                  },
                ]}
              />
            }
            title={"Summary"}
          />
        </div>

        <div className="col-6 chart-with-position">
          <GenericCard
            children={
              noDataDoughnutChart ? (
                <NoDataAfterScanning />
              ) : isDatainProcess ? (
                <NoData />
              ) : (
                <DoughnutChart
                  data={doughnutChartType}
                  options={doughnutChartOptions}
                  plugins={[ChartDataLabels]}
                  height={104}
                  width={209}
                  onHandleClick={handleChartClick}
                />
              )
            }
            title={"Asset State"}
          />
        </div>
      </div>
      <div>
        <BaseFilter
          tabs={tabs}
          className="mt-4"
          onFilterChange={handleFilterChange}
          activeFilters={activeFilters}
          removeFilter={removeFilter}
          searchValue={searchValue}
          onSearchChange={handleSearchChange}
          isSearch={true}
          isManageColumn={true}
          allColumns={allColumnsObj}
          setAllColumns={setAllColumnsObj}
          totalRecords={filteredData?.length}
          exportTitle={`AssetsDiscovery_${formatScannerName(
            targetScan?.title
          )}-${moment().format("DDMMMYYYY").toUpperCase()}`}
          exportRows={getExportedRows(sortData(filteredData))}
          exportHeader={allColumnsObj
            .filter((col) => col.visibility)
            .map((col) => col.header)}
          actions={[
            { id: 1, name: "Reject Hostname" },
            { id: 2, name: "Reject IP" },
            { id: 3, name: "Modifiy Asset Criticality" },
          ]}
          onActionSelect={handleMultipleAction}
          onClearSelectRow={() => {
            setRejectIP([]);
            setSelectedAssets(null);
          }}
          multiSelect={
            selectedAssets &&
            Object.entries(selectedAssets)?.filter((el) => el[1] == true)
              ?.length > 0
              ? Object.entries(selectedAssets)?.filter((el) => el[1] == true)
                  ?.length
              : 0
          }
          tableData={filteredData}
        />
      </div>
      <div>
        {selectedAssets &&
          Object.entries(selectedAssets)?.filter((el) => el[1] === true)
            ?.length > 0 && (
            <div>
              <BulkSelection
                actions={[
                  { id: 1, name: "Reject Hostname" },
                  { id: 2, name: "Reject IP" },
                  { id: 3, name: "Modify Asset Criticality" },
                ]}
                onClearSelectRow={() => {
                  setRejectIP([]);
                  setSelectedAssets(null);
                }}
                onActionSelect={handleMultipleAction}
                multiselectLabel={"Assets"}
                multiSelect={
                  Object.entries(selectedAssets)?.filter((el) => el[1] === true)
                    ?.length
                }
                totalRecords={filteredData?.length}
                handleSelectionAll={handleSelectionAll}
              />
            </div>
          )}
      </div>
      {filteredColumns.length > 0 ? (
        <div>
          <div className="assets-discovery-table  custom-x-scroll-table">
            <BaseTable
              className="mt-3 mb-3"
              columns={filteredColumns}
              data={sortData(filteredData)}
              selectable={true}
              showCheckboxes={true}
              action={true}
              loading={false}
              isDatainProcess={false}
              stickyAction={true}
              actions={[
                { id: 1, name: "Reject Hostname" },
                { id: 2, name: "Reject IP" },
                { id: 3, name: "Modifiy Asset Criticality" },
              ]}
              onApplyActions={handleAction}
              onRowSelect={handleRowSelect}
              allSelectedRows={selectedAssets}
            />
          </div>
        </div>
      ) : null}
      <AssetsModal
        showModal={showModal}
        handleOnHide={handleOnHide}
        actionType={rejectAction}
        items={rejectIP}
        handleSubmit={handleRejectSubmit}
        handleModifyCriticalitySubmit={handleModifyCriticalitySubmit}
      />
    </>
  );
};

export default AssetsDiscovery;
