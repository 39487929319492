
export const getChartOptions = (chartConfig) => ({
    responsive: true,
    maintainAspectRatio: false,
    layout: {
      padding: {
        top: 0,
        bottom: 0,
      },
    },
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        enabled: false,
      },
    },
    scales: {
      x: {
        display: false,
        barPercentage: 0.9,
        categoryPercentage: 0.2,
      },
      y: {
        display: false,
      },
    },
    onClick: (event, elements) =>
      chartConfig.handleBarClick(event, elements, chartConfig),
  });
export const getChartData = (clickedBars) => ({
    labels: ['First', 'Second', 'Third'],
    datasets: [
      {
        label: 'Sample Data',
        data: [70, 100, 130],
        backgroundColor: clickedBars.map((clicked, index) => {
          return clicked ? '#3b82f6' : index === 0 ? '#60a5fa' : '#cbd5e1';
        }),
        hoverBackgroundColor: ['#2563eb', '#2563eb', '#2563eb'],
        borderWidth: 1,
        borderRadius: 10,
        borderSkipped: false,
        maxBarThickness: 12,
      },
    ],
  });
    