import React, { useState, useEffect } from "react";
import { Bar } from "react-chartjs-2";
import { getChartOptions, getChartData } from "../../util/chartUtils";
import {
  Chart as ChartJS,
  BarElement,
  CategoryScale,
  LinearScale,
  Tooltip,
  Legend,
} from "chart.js";

ChartJS.register(BarElement, CategoryScale, LinearScale, Tooltip, Legend);

const ClickableBarChart = ({ criticality, onCriticalityChange ,barClickable = true}) => {
  const [clickedBars, setClickedBars] = useState([
    criticality > 0,
    criticality > 1,
    criticality > 2,
  ]);
  useEffect(() => {
    setClickedBars([criticality > 0, criticality > 1, criticality > 2]);
  }, [criticality]);
  const getCriticality = (clickedState) => {
    if (clickedState[2]) return "High";
    if (clickedState[1]) return "Medium";
    if (clickedState[0]) return "Low";
    return "Low";
  };
  const handleBarClick = (event, elements) => {
    if (!barClickable || elements.length === 0) return;
      const index = elements[0].index;
      setClickedBars((prevState) => {
        const newState = [false, false, false];
        if (index >= 0 && index < newState.length) {
          newState[index] = !prevState[index];
          for (let i = 0; i <= index; i++) {
            newState[i] = true;
          }
        }
        const criticality = getCriticality(newState);
        onCriticalityChange(criticality);
        return newState;
      });
    
  };
  const data = getChartData(clickedBars);
  const chartConfig = {
    setClickedBars,
    getCriticality,
    onCriticalityChange,
    clickedBars,
    handleBarClick,
  };

  const options = getChartOptions(chartConfig);

  return (
    <div className="d-flex align-items-center">
      <div
        style={{
          width: "33px",
          height: "29px",
          boxSizing: "border-box",
        }}
      >
        <Bar data={data} options={options} height={29} />
      </div>
      <span className="criticality-level">{getCriticality(clickedBars)}</span>
    </div>
  );
};

export default ClickableBarChart;
