import React from "react";
import { Button, Modal } from "react-bootstrap";
import BaseTable from "../../components/table/BaseTable";

export const PreviewModal = ({ showModal, hideModal, headings, data }) => {
  return (
    <Modal show={showModal} centered onHide={hideModal}>
      <Modal.Body className="preview_modal">
        <div className="file-heading">File Preview</div>
        <div className="table-section">
          <BaseTable
            className="h-fit-content"
            columns={headings}
            data={data}
            selectable={false}
            showCheckboxes={false}
            isPagination={data?.length > 10}
          />
        </div>
        <div className="preview_modal_button">
          <Button className="" onClick={hideModal}>
            Close
          </Button>
        </div>
      </Modal.Body>
    </Modal>
  );
};
