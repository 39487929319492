const initialColumns = [
    { header: "Domain", key: "domain", visibility: true, default: true },
    { header: "Hostname", key: "host", visibility: true, default: true },
    { header: "IP Address", key: "ip", visibility: true, default: true },
    { header: "Hosting Provider", key: "hosting_provider", visibility: true },
    { header: "CDN", key: "cdn_name", visibility: true },
    { header: "Archive Date", key: "archive_date", visibility: true },
    { header: "Current State", key: "current_state", visibility: true },
    { header: "Network Zone", key: "network_zone", visibility: true },
    { header: "Service Category", key: "service_category", visibility: true },
    { header: "Web Interface", key: "web_interface", visibility: true },
    { header: "Asset Criticality", key: "asset_criticality", visibility: true },
    { header: "Security Issues", key: "issues", visibility: true },
    { header: "Discovered By", key: "discovered_by", visibility: true },
    { header: "First Detected", key: "first_detected", visibility: true },
  ];
  
  export default initialColumns;
  