import React, { useState, useEffect } from "react";
import ScanImg from "../../assets/images/scanImg.png";
import scangif from "../../assets/images/scangif.gif";
const WebScanner = () => {
  const [currentImg, setCurrentImg] = useState(ScanImg);
  const handleImageClick = () => {
    setCurrentImg(scangif);
    setTimeout(() => {
      setCurrentImg(ScanImg);
    }, 2000); 
  };
  return (
    <>
        <div className="scanimg">
          <img
          src={currentImg}
          alt="marketing-area"
          className="w-100 h-100"
          onClick={handleImageClick}
        />
        </div>
    </>
  );
};

export default WebScanner;
