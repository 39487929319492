import { parseFilterString, applyCondition } from "./conditions";

export const statusMap = { new: 1, changed: 2, unchanged: 0 };

export function groupFilters(activeFilters) {
  return activeFilters.reduce((acc, filter) => {
    if (!acc[filter.eventKey]) {
      acc[filter.eventKey] = [];
    }
    acc[filter.eventKey].push(filter);
    return acc;
  }, {});
}

export function getSensitiveFilterData(
  sensitiveData,
  activeFilters,
  searchValue
) {
  const groupedFilters = groupFilters(activeFilters);
  return sensitiveData
    .filter((item) => {
      const matchesFilters = Object.keys(groupedFilters).every((eventKey) => {
        return groupedFilters[eventKey].some((filter) => {
          const lowerCaseFilter = filter.name?.toLowerCase();
          switch (eventKey) {
            case "1":
              return item.name?.toLowerCase() === lowerCaseFilter;
            case "2":
              return item?.data?.some(
                (el) => el?.domain?.toLowerCase() === lowerCaseFilter
              );
            case "3":
              return item?.data?.some(
                (el) => el?.url?.toLowerCase() === lowerCaseFilter
              );
            case "4":
              return item?.data?.some(
                (el) => el?.title?.toLowerCase() === lowerCaseFilter
              );
            case "5":
              return item?.data?.some(
                (el) => el?.description?.toLowerCase() === lowerCaseFilter
              );
            case "6":
              return item?.data?.some(
                (el) =>
                  el.change_status === statusMap[filter.name?.toLowerCase()]
              );
            case "advanced-filter":
              const parsedFilters = parseFilterString(filter.name);
              return parsedFilters.every((ol) => {
                const { column, condition, value } = ol;
                switch (column) {
                  case "name":
                    return applyCondition(item.name, condition, value);
                  case "host":
                    return item?.data?.some((el) =>
                      applyCondition(el.domain, condition, value)
                    );
                  case "url":
                    return item?.data?.some((el) =>
                      applyCondition(el.url, condition, value)
                    );
                  case "title":
                    return item?.data?.some((el) =>
                      applyCondition(el.title, condition, value)
                    );
                  case "description":
                    return item?.data?.some((el) =>
                      applyCondition(el.description, condition, value)
                    );
                  case "current_state":
                    return item.data?.some((el) =>
                      applyCondition(
                        el.change_status,
                        condition,
                        statusMap[value.toLowerCase()]
                      )
                    );
                  default:
                    return false;
                }
              });
            default:
              return false;
          }
        });
      });

      const matchesSearch =
        searchValue === "" ||
        item?.name?.toLowerCase().includes(searchValue?.toLowerCase()) ||
        item?.data.some(
          (el) =>
            el?.title?.toString()?.includes(searchValue?.toLowerCase()) ||
            el?.description?.toString()?.includes(searchValue?.toLowerCase()) ||
            el?.domain?.toString()?.includes(searchValue?.toLowerCase()) ||
            el?.url?.toString()?.includes(searchValue?.toLowerCase())
        );

      return matchesFilters && matchesSearch;
    })
    .map((el) => {
      const allCategories = activeFilters.every(
        (filter) => filter.eventKey == "1"
      );

      return {
        ...el,
        data: el.data.filter(
          (ol) =>
            activeFilters.length === 0 ||
            allCategories ||
            (groupedFilters["2"] &&
              groupedFilters["2"].some(
                (filter) =>
                  ol.domain?.toLowerCase() === filter.name?.toLowerCase()
              )) ||
            (groupedFilters["3"] &&
              groupedFilters["3"].some(
                (filter) =>
                  ol.url && ol.url?.toLowerCase() === filter.name?.toLowerCase()
              )) ||
            (groupedFilters["4"] &&
              groupedFilters["4"].some(
                (filter) =>
                  ol.title &&
                  ol.title?.toLowerCase() === filter.name?.toLowerCase()
              )) ||
            (groupedFilters["5"] &&
              groupedFilters["5"].some(
                (filter) =>
                  ol.description &&
                  ol.description?.toLowerCase() === filter.name?.toLowerCase()
              )) ||
            (groupedFilters["6"] &&
              groupedFilters["6"].some(
                (filter) =>
                  ol.change_status === statusMap[filter.name?.toLowerCase()]
              )) ||
            (groupedFilters["advanced-filter"] &&
              groupedFilters["advanced-filter"].some((filter) => {
                const parsedFilters = parseFilterString(filter.name);
                const withoutCategoryFilter = parsedFilters?.filter(
                  (el) => el.column !== "name"
                );
                return withoutCategoryFilter.every((item) => {
                  const { column, condition, value } = item;
                  switch (column) {
                    case "host":
                      return applyCondition(ol.domain, condition, value);
                    case "url":
                      return applyCondition(ol.url, condition, value);
                    case "title":
                      return applyCondition(ol.title, condition, value);
                    case "description":
                      return applyCondition(ol.description, condition, value);
                    case "current_state":
                      return applyCondition(
                        ol.change_status,
                        condition,
                        statusMap[value.toLowerCase()]
                      );
                    default:
                      return false;
                  }
                });
              }))
        ),
      };
    });
}
