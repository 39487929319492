import React, { useState } from "react";
import { Navbar, Nav } from "react-bootstrap";
import { Link } from "react-router-dom";
import BreadCrumb from "../../components/breadcrumbs/BreadCrumb";
import MyLicense from "./MyLicense";

const Settings = () => {
  const [activeLink, setActiveLink] = useState("License");

  const handleLinkClick = (link) => {
    setActiveLink(link);
  };

  return (
    <>
      <BreadCrumb
        breadcrumbItems={["Settings"]}
        isLastUpdate={false}
        showScanning={false}
      />
      <Navbar className="border-bottom mt-4">
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="me-auto ms-3">
            <Link
              className={`disable-link-color discovery-nav-text text-white ${
                activeLink === "License" ? "discovery-active-nav-text" : ""
              }`}
              onClick={() => handleLinkClick("License")}
            >
              My License
            </Link>
          </Nav>
        </Navbar.Collapse>
      </Navbar>
      <div className="p-5">
        {activeLink === "License" && <MyLicense />}
      </div>
    </>
  );
};

export default Settings;
