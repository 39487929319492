import { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import logopng from "../../assets/images/logo.png";

import { ReactComponent as MenuIcon } from '../../assets/images/icon-menu.svg';
import { ReactComponent as XIcon } from '../../assets/images/icon-x.svg';
import Navbar from 'react-bootstrap/Navbar';
import { Link } from 'react-router-dom';




function Header({ open, onToggle }) {
  const [expand, setExpand] = useState(false);


  return (
    <>

      <Navbar sticky="top" fixed="top"  key={expand} expand={expand} className="d-md-block d-lg-none bg-body ">
        <Container fluid>
        <Link to="/">
        <img src={logopng} alt="Logo" className="mt-3 d-block mx-auto img-fluid logo-img" /> 
        {process.env.REACT_APP_NAME || ""}
        </Link>

          {!open ? <MenuIcon onClick={onToggle}
            aria-controls="sidebar"
            aria-expanded={open} /> : <XIcon onClick={onToggle} aria-controls="sidebar"
              aria-expanded={open} />}
          

        </Container>
      </Navbar>

    </>
  );
}

export default Header;